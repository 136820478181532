import { useCallback, useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../Mqtt/constants';
import config from '../utils/config';
import { getCookies, isEmptyStringObject } from '../utils/helper';
import LocalDb from '../utils/localStorage';
import TokenHandler from '../utils/token';
import { transformResponse } from '../utils/userSessionDTO';

const useSessionFromCookie = () => {
	const [sessionSet, setSessionSet] = useState(false);
	const [fetchingSession, setFetchingSession] = useState(true);

	const cookieObject = getCookies();
	const keysCookieObject = Object.keys(cookieObject);
	let selectedTokenKey = keysCookieObject.find((i) => i.includes('_selected'));
	if (selectedTokenKey === undefined) {
		selectedTokenKey = keysCookieObject[0];
	}

	const whiteboardId =
		window.location.pathname.split('/') && window.location.pathname.split('/')[2];

	const fetchSession = useCallback(
		async (token) => {
			// console.debug('fetch session with token', token);
			var raw = JSON.stringify({
				stringValues: [token],
			});
			try {
				const data = await fetch(`${API_ENDPOINTS.GET_SESSION}`, {
					method: 'POST',
					headers: TokenHandler.headersTypeJSON(),
					body: raw,
				});
				const json = await data.json();
				if (json.success === false) {
					console.log('Session expired. Please login again.');
					window.open(
						`${config.clientURL}?redirectUrl=${config.whiteboardURL}/board/${whiteboardId}`,
						'_self'
					);
				} else if (json.success === true) {
					if (!json.userSessionDetails) {
						setTimeout(() => {
							window.open(
								`${config.clientURL}?redirectUrl=${config.whiteboardURL}/board/${whiteboardId}`,
								'_self'
							);
						}, 500);
					}
					const sessionCurrent = transformResponse(
						json.userSessionDetails[0],
						token
					);
					LocalDb.setSession(sessionCurrent, () => {});
					setSessionSet(true);
					TokenHandler.setToken();
				} else {
					setSessionSet(false);
				}
			} catch (error) {
				console.error(error);
				setSessionSet(false);
			}
			setFetchingSession(false);
		},
		[whiteboardId]
	);

	useEffect(() => {
		if (cookieObject) {
			if (
				isEmptyStringObject(cookieObject) ||
				Object.keys(cookieObject).length === 0
			) {
				window.open(
					`${config.clientURL}?redirectUrl=${config.whiteboardURL}/board/${whiteboardId}`,
					'_self'
				);
			} else {
				try {
					fetchSession(cookieObject[selectedTokenKey]);
				} catch (error) {
					console.error(error);
					setFetchingSession(false);
					setSessionSet(false);
				}
			}
		}
	}, [cookieObject, fetchSession, selectedTokenKey, whiteboardId]);
	return { sessionSet, fetchingSession };
};

export default useSessionFromCookie;
