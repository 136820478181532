import config from "./config";

const BASE_API = config.baseApi

export const TOOL_TYPE = {
	TEXTBOX: 'TEXT_BOX',
	STROKE_PROP_SELECTOR: 'STROKE_PROP_SELECTOR',
	OPACITY_SELECTOR: 'OPACITY_SELECTOR',
	ERASER: 'ERASER',
	SHAPES: 'SHAPES',
	UNDO: 'UNDO',
	POINTER: 'POINTER',
	ADD_IMAGE: 'ADD_IMAGE',
	DOWNLOAD_SCREENSHOT: 'DOWNLOAD_SCREENSHOT',
	LASER: 'LASER',
	STICKY_NOTE: 'STICKY_NOTE',
};

export const SHAPES = {
	CIRCLE: 'CIRCLE',
	SQUARE: 'SQUARE',
	RECTANGLE: 'RECTANGLE',
	TRIANGLE: 'TRIANGLE',
	DIAMOND: 'DIAMOND',
	LINE: 'LINE',
	ARROW: 'ARROW',
	STAR: 'STAR',
	TERMINAL: 'TERMINAL',
	DECISION: 'DECISION',
	PROCESS: 'PROCESS',
	DATA: 'DATA',
};

export const MQTT_TOPICS = {
	WHITEBOARD: 'anydone/whiteboard/',
};

export const BACKGROUNDS = {
	WHITE: 'WHITE',
	BLACK: 'BLACK',
	DOTTED: 'DOTTED',
	GRID: 'GRID',
};

export const SNACKBAR_ALERT_TYPES = {
	SUCCESS: 'success',
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info',
};

export const URLS = {
	BASE_URL: BASE_API,
	ENDPOINTS: {
		IMAGE_UPLOAD: 'upload/image',
	},
};
