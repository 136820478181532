import styled from "styled-components";

const StyledBoardName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 20px 0 40px;
`;

export default StyledBoardName;
