import React from "react";
import { InboxIconWrapper } from "./Style";
import { getInboxColor, getInitialLettersOfEachWord } from "./helper";

const returnIcon = ({ subject, width, uniquekey }) => (
	<InboxIconWrapper
		key={uniquekey}
		color={getInboxColor(subject) && getInboxColor(subject).primaryColor}
		background={getInboxColor(subject) && getInboxColor(subject).secondaryColor}
		width={width}
		height={width}
	>
		{getInitialLettersOfEachWord(subject)}
	</InboxIconWrapper>
);

/**
 * It returns an icon based on the type of call, the subject of the call, the width of the icon, and
 * the profile url of the user
 */
export const CallAvatarIcon = ({
	uniquekey,
	subject = "User",
	width = "110px",
}) => returnIcon({ subject, width, uniquekey });
