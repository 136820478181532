import React from 'react';
import { CirclePicker } from 'react-color';
import { SHAPES } from '../../../utils/constants';
import rectangle_icon from '../../../assets/shapes_icons/rect_shape.svg';
import circle_icon from '../../../assets/shapes_icons/circle_shape.svg';
import triangle_icon from '../../../assets/shapes_icons/triangle_shape.svg';
import line_icon from '../../../assets/shapes_icons/line_shape.svg';
import arrow_icon from '../../../assets/shapes_icons/arrow_shape.svg';
import star_icon from '../../../assets/shapes_icons/star_shape.svg';
import decision_icon from '../../../assets/shapes_icons/decision.svg';
import data_icon from '../../../assets/shapes_icons/data.svg';
import process_icon from '../../../assets/shapes_icons/process.svg';
import terminal_icon from '../../../assets/shapes_icons/terminal.svg';

import StyledShapesSelector from './style';
import { Tooltip } from '@mui/material';

const ShapesSelector = ({
	shapeColor,
	handleSelectShape,
	handleUpdateShapeColor,
}) => {
	const shapes = [
		{
			type: SHAPES.RECTANGLE,
			display: 'Rectangle',
			icon: rectangle_icon,
		},
		{
			type: SHAPES.CIRCLE,
			display: 'Circle',
			icon: circle_icon,
		},
		{
			type: SHAPES.TRIANGLE,
			display: 'Triangle',
			icon: triangle_icon,
		},
		{
			type: SHAPES.LINE,
			display: 'Line',
			icon: line_icon,
		},
		{
			type: SHAPES.ARROW,
			display: 'Arrow',
			icon: arrow_icon,
		},
		{
			type: SHAPES.STAR,
			display: 'Star',
			icon: star_icon,
		},
		{
			type: SHAPES.DECISION,
			display: 'Decision',
			icon: decision_icon,
		},
		{
			type: SHAPES.PROCESS,
			display: 'Process',
			icon: process_icon,
		},
		{
			type: SHAPES.DATA,
			display: 'Data',
			icon: data_icon,
		},
		{
			type: SHAPES.TERMINAL,
			display: 'Terminal',
			icon: terminal_icon,
		},
	];
	return (
		<StyledShapesSelector>
			<div className="top-shapes-container">
				{shapes.map((shape) => (
					<Tooltip title={shape.display} placement="top" key={shape.display}>
						<button
							key={shape.display}
							onClick={(e) => handleSelectShape(e, shape)}
							className="no-border-button"
							type="button"
						>
							<img width="20" height="20" src={shape.icon} alt={shape.display} />
						</button>
					</Tooltip>
				))}
			</div>
			<div className="bottom-color-picker-container">
				<CirclePicker
					width="100%"
					color={shapeColor}
					colors={[
						'#000000',
						'#0F9D58',
						'#1EA4E9',
						'#DE4132',
						'#FFBB24',
						'#3A5090',
						'#00FC81',
						' #6BA8C8',
						'#FFE600',
					]}
					onChange={(color, event) => handleUpdateShapeColor(color, event)}
				/>
			</div>
		</StyledShapesSelector>
	);
};

export default ShapesSelector;
