/* eslint-disable no-undef */
// automatically generated by the FlatBuffers compiler, do not modify
var __createBinding =
	(this && this.__createBinding) ||
	(Object.create
		? function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				var desc = Object.getOwnPropertyDescriptor(m, k);
				if (
					!desc ||
					("get" in desc ? !m.__esModule : desc.writable || desc.configurable)
				) {
					desc = {
						enumerable: true,
						get: function () {
							return m[k];
						},
					};
				}
				Object.defineProperty(o, k2, desc);
		  }
		: function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				o[k2] = m[k];
		  });
var __setModuleDefault =
	(this && this.__setModuleDefault) ||
	(Object.create
		? function (o, v) {
				Object.defineProperty(o, "default", { enumerable: true, value: v });
		  }
		: function (o, v) {
				o["default"] = v;
		  });
var __importStar =
	(this && this.__importStar) ||
	function (mod) {
		if (mod && mod.__esModule) return mod;
		var result = {};
		if (mod != null)
			for (var k in mod)
				if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k))
					__createBinding(result, mod, k);
		__setModuleDefault(result, mod);
		return result;
	};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Account = void 0;
var flatbuffers = __importStar(require("flatbuffers"));
var Account = /** @class */ (function () {
	function Account() {
		this.bb = null;
		this.bb_pos = 0;
	}
	Account.prototype.__init = function (i, bb) {
		this.bb_pos = i;
		this.bb = bb;
		return this;
	};
	Account.getRootAsAccount = function (bb, obj) {
		return (obj || new Account()).__init(
			bb.readInt32(bb.position()) + bb.position(),
			bb
		);
	};
	Account.getSizePrefixedRootAsAccount = function (bb, obj) {
		bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
		return (obj || new Account()).__init(
			bb.readInt32(bb.position()) + bb.position(),
			bb
		);
	};
	Account.prototype.accountid = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 4);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.email = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 6);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.phone = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 8);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.accounttype = function () {
		var offset = this.bb.__offset(this.bb_pos, 10);
		return offset ? this.bb.readUint8(this.bb_pos + offset) : 0;
	};
	Account.prototype.mutate_accounttype = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 10);
		if (offset === 0) {
			return false;
		}
		this.bb.writeUint8(this.bb_pos + offset, value);
		return true;
	};
	Account.prototype.countrycode = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 12);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.profilepic = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 14);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.status = function () {
		var offset = this.bb.__offset(this.bb_pos, 16);
		return offset ? this.bb.readUint8(this.bb_pos + offset) : 0;
	};
	Account.prototype.mutate_status = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 16);
		if (offset === 0) {
			return false;
		}
		this.bb.writeUint8(this.bb_pos + offset, value);
		return true;
	};
	Account.prototype.isemailverified = function () {
		var offset = this.bb.__offset(this.bb_pos, 18);
		return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
	};
	Account.prototype.mutate_isemailverified = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 18);
		if (offset === 0) {
			return false;
		}
		this.bb.writeInt8(this.bb_pos + offset, +value);
		return true;
	};
	Account.prototype.isphoneverified = function () {
		var offset = this.bb.__offset(this.bb_pos, 20);
		return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
	};
	Account.prototype.mutate_isphoneverified = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 20);
		if (offset === 0) {
			return false;
		}
		this.bb.writeInt8(this.bb_pos + offset, +value);
		return true;
	};
	Account.prototype.iskycverified = function () {
		var offset = this.bb.__offset(this.bb_pos, 22);
		return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
	};
	Account.prototype.mutate_iskycverified = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 22);
		if (offset === 0) {
			return false;
		}
		this.bb.writeInt8(this.bb_pos + offset, +value);
		return true;
	};
	Account.prototype.fullname = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 24);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.address = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 26);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.timezone = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 28);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.currencycode = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 30);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.locationslist = function (index, optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 32);
		return offset
			? this.bb.__string(
					this.bb.__vector(this.bb_pos + offset) + index * 4,
					optionalEncoding
			  )
			: null;
	};
	Account.prototype.locationslistLength = function () {
		var offset = this.bb.__offset(this.bb_pos, 32);
		return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
	};
	Account.prototype.language = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 34);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.termsandconditionsversion = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 36);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.acceptcalls = function () {
		var offset = this.bb.__offset(this.bb_pos, 38);
		return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
	};
	Account.prototype.mutate_acceptcalls = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 38);
		if (offset === 0) {
			return false;
		}
		this.bb.writeInt8(this.bb_pos + offset, +value);
		return true;
	};
	Account.prototype.refid = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 40);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.passwordchanged = function () {
		var offset = this.bb.__offset(this.bb_pos, 42);
		return offset ? !!this.bb.readInt8(this.bb_pos + offset) : false;
	};
	Account.prototype.mutate_passwordchanged = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 42);
		if (offset === 0) {
			return false;
		}
		this.bb.writeInt8(this.bb_pos + offset, +value);
		return true;
	};
	Account.prototype.addresseslist = function (index, optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 44);
		return offset
			? this.bb.__string(
					this.bb.__vector(this.bb_pos + offset) + index * 4,
					optionalEncoding
			  )
			: null;
	};
	Account.prototype.addresseslistLength = function () {
		var offset = this.bb.__offset(this.bb_pos, 44);
		return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
	};
	Account.prototype.onlinestatus = function () {
		var offset = this.bb.__offset(this.bb_pos, 46);
		return offset ? this.bb.readUint8(this.bb_pos + offset) : 0;
	};
	Account.prototype.mutate_onlinestatus = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 46);
		if (offset === 0) {
			return false;
		}
		this.bb.writeUint8(this.bb_pos + offset, value);
		return true;
	};
	Account.prototype.lastactivetimestamp = function () {
		var offset = this.bb.__offset(this.bb_pos, 48);
		return offset ? this.bb.readUint64(this.bb_pos + offset) : BigInt("0");
	};
	Account.prototype.mutate_lastactivetimestamp = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 48);
		if (offset === 0) {
			return false;
		}
		this.bb.writeUint64(this.bb_pos + offset, value);
		return true;
	};
	Account.prototype.inboxid = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 50);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.sectionid = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 52);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.title = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 54);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.workinghourslist = function (index, optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 56);
		return offset
			? this.bb.__string(
					this.bb.__vector(this.bb_pos + offset) + index * 4,
					optionalEncoding
			  )
			: null;
	};
	Account.prototype.workinghourslistLength = function () {
		var offset = this.bb.__offset(this.bb_pos, 56);
		return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
	};
	Account.prototype.description = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 58);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.code = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 60);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.baid = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 62);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.createdat = function () {
		var offset = this.bb.__offset(this.bb_pos, 64);
		return offset ? this.bb.readUint64(this.bb_pos + offset) : BigInt("0");
	};
	Account.prototype.mutate_createdat = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 64);
		if (offset === 0) {
			return false;
		}
		this.bb.writeUint64(this.bb_pos + offset, value);
		return true;
	};
	Account.prototype.role = function () {
		var offset = this.bb.__offset(this.bb_pos, 66);
		return offset ? this.bb.readUint8(this.bb_pos + offset) : 0;
	};
	Account.prototype.mutate_role = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 66);
		if (offset === 0) {
			return false;
		}
		this.bb.writeUint8(this.bb_pos + offset, value);
		return true;
	};
	Account.prototype.companyname = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 68);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.workspacename = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 70);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.workspaceid = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 72);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Account.prototype.employeetype = function () {
		var offset = this.bb.__offset(this.bb_pos, 74);
		return offset ? this.bb.readUint8(this.bb_pos + offset) : 0;
	};
	Account.prototype.mutate_employeetype = function (value) {
		var offset = this.bb.__offset(this.bb_pos, 74);
		if (offset === 0) {
			return false;
		}
		this.bb.writeUint8(this.bb_pos + offset, value);
		return true;
	};
	Account.startAccount = function (builder) {
		builder.startObject(36);
	};
	Account.addAccountid = function (builder, accountidOffset) {
		builder.addFieldOffset(0, accountidOffset, 0);
	};
	Account.addEmail = function (builder, emailOffset) {
		builder.addFieldOffset(1, emailOffset, 0);
	};
	Account.addPhone = function (builder, phoneOffset) {
		builder.addFieldOffset(2, phoneOffset, 0);
	};
	Account.addAccounttype = function (builder, accounttype) {
		builder.addFieldInt8(3, accounttype, 0);
	};
	Account.addCountrycode = function (builder, countrycodeOffset) {
		builder.addFieldOffset(4, countrycodeOffset, 0);
	};
	Account.addProfilepic = function (builder, profilepicOffset) {
		builder.addFieldOffset(5, profilepicOffset, 0);
	};
	Account.addStatus = function (builder, status) {
		builder.addFieldInt8(6, status, 0);
	};
	Account.addIsemailverified = function (builder, isemailverified) {
		builder.addFieldInt8(7, +isemailverified, +false);
	};
	Account.addIsphoneverified = function (builder, isphoneverified) {
		builder.addFieldInt8(8, +isphoneverified, +false);
	};
	Account.addIskycverified = function (builder, iskycverified) {
		builder.addFieldInt8(9, +iskycverified, +false);
	};
	Account.addFullname = function (builder, fullnameOffset) {
		builder.addFieldOffset(10, fullnameOffset, 0);
	};
	Account.addAddress = function (builder, addressOffset) {
		builder.addFieldOffset(11, addressOffset, 0);
	};
	Account.addTimezone = function (builder, timezoneOffset) {
		builder.addFieldOffset(12, timezoneOffset, 0);
	};
	Account.addCurrencycode = function (builder, currencycodeOffset) {
		builder.addFieldOffset(13, currencycodeOffset, 0);
	};
	Account.addLocationslist = function (builder, locationslistOffset) {
		builder.addFieldOffset(14, locationslistOffset, 0);
	};
	Account.createLocationslistVector = function (builder, data) {
		builder.startVector(4, data.length, 4);
		for (var i = data.length - 1; i >= 0; i--) {
			builder.addOffset(data[i]);
		}
		return builder.endVector();
	};
	Account.startLocationslistVector = function (builder, numElems) {
		builder.startVector(4, numElems, 4);
	};
	Account.addLanguage = function (builder, languageOffset) {
		builder.addFieldOffset(15, languageOffset, 0);
	};
	Account.addTermsandconditionsversion = function (
		builder,
		termsandconditionsversionOffset
	) {
		builder.addFieldOffset(16, termsandconditionsversionOffset, 0);
	};
	Account.addAcceptcalls = function (builder, acceptcalls) {
		builder.addFieldInt8(17, +acceptcalls, +false);
	};
	Account.addRefid = function (builder, refidOffset) {
		builder.addFieldOffset(18, refidOffset, 0);
	};
	Account.addPasswordchanged = function (builder, passwordchanged) {
		builder.addFieldInt8(19, +passwordchanged, +false);
	};
	Account.addAddresseslist = function (builder, addresseslistOffset) {
		builder.addFieldOffset(20, addresseslistOffset, 0);
	};
	Account.createAddresseslistVector = function (builder, data) {
		builder.startVector(4, data.length, 4);
		for (var i = data.length - 1; i >= 0; i--) {
			builder.addOffset(data[i]);
		}
		return builder.endVector();
	};
	Account.startAddresseslistVector = function (builder, numElems) {
		builder.startVector(4, numElems, 4);
	};
	Account.addOnlinestatus = function (builder, onlinestatus) {
		builder.addFieldInt8(21, onlinestatus, 0);
	};
	Account.addLastactivetimestamp = function (builder, lastactivetimestamp) {
		builder.addFieldInt64(22, lastactivetimestamp, BigInt("0"));
	};
	Account.addInboxid = function (builder, inboxidOffset) {
		builder.addFieldOffset(23, inboxidOffset, 0);
	};
	Account.addSectionid = function (builder, sectionidOffset) {
		builder.addFieldOffset(24, sectionidOffset, 0);
	};
	Account.addTitle = function (builder, titleOffset) {
		builder.addFieldOffset(25, titleOffset, 0);
	};
	Account.addWorkinghourslist = function (builder, workinghourslistOffset) {
		builder.addFieldOffset(26, workinghourslistOffset, 0);
	};
	Account.createWorkinghourslistVector = function (builder, data) {
		builder.startVector(4, data.length, 4);
		for (var i = data.length - 1; i >= 0; i--) {
			builder.addOffset(data[i]);
		}
		return builder.endVector();
	};
	Account.startWorkinghourslistVector = function (builder, numElems) {
		builder.startVector(4, numElems, 4);
	};
	Account.addDescription = function (builder, descriptionOffset) {
		builder.addFieldOffset(27, descriptionOffset, 0);
	};
	Account.addCode = function (builder, codeOffset) {
		builder.addFieldOffset(28, codeOffset, 0);
	};
	Account.addBaid = function (builder, baidOffset) {
		builder.addFieldOffset(29, baidOffset, 0);
	};
	Account.addCreatedat = function (builder, createdat) {
		builder.addFieldInt64(30, createdat, BigInt("0"));
	};
	Account.addRole = function (builder, role) {
		builder.addFieldInt8(31, role, 0);
	};
	Account.addCompanyname = function (builder, companynameOffset) {
		builder.addFieldOffset(32, companynameOffset, 0);
	};
	Account.addWorkspacename = function (builder, workspacenameOffset) {
		builder.addFieldOffset(33, workspacenameOffset, 0);
	};
	Account.addWorkspaceid = function (builder, workspaceidOffset) {
		builder.addFieldOffset(34, workspaceidOffset, 0);
	};
	Account.addEmployeetype = function (builder, employeetype) {
		builder.addFieldInt8(35, employeetype, 0);
	};
	Account.endAccount = function (builder) {
		var offset = builder.endObject();
		return offset;
	};
	Account.createAccount = function (
		builder,
		accountidOffset,
		emailOffset,
		phoneOffset,
		accounttype,
		countrycodeOffset,
		profilepicOffset,
		status,
		isemailverified,
		isphoneverified,
		iskycverified,
		fullnameOffset,
		addressOffset,
		timezoneOffset,
		currencycodeOffset,
		locationslistOffset,
		languageOffset,
		termsandconditionsversionOffset,
		acceptcalls,
		refidOffset,
		passwordchanged,
		addresseslistOffset,
		onlinestatus,
		lastactivetimestamp,
		inboxidOffset,
		sectionidOffset,
		titleOffset,
		workinghourslistOffset,
		descriptionOffset,
		codeOffset,
		baidOffset,
		createdat,
		role,
		companynameOffset,
		workspacenameOffset,
		workspaceidOffset,
		employeetype
	) {
		Account.startAccount(builder);
		Account.addAccountid(builder, accountidOffset);
		Account.addEmail(builder, emailOffset);
		Account.addPhone(builder, phoneOffset);
		Account.addAccounttype(builder, accounttype);
		Account.addCountrycode(builder, countrycodeOffset);
		Account.addProfilepic(builder, profilepicOffset);
		Account.addStatus(builder, status);
		Account.addIsemailverified(builder, isemailverified);
		Account.addIsphoneverified(builder, isphoneverified);
		Account.addIskycverified(builder, iskycverified);
		Account.addFullname(builder, fullnameOffset);
		Account.addAddress(builder, addressOffset);
		Account.addTimezone(builder, timezoneOffset);
		Account.addCurrencycode(builder, currencycodeOffset);
		Account.addLocationslist(builder, locationslistOffset);
		Account.addLanguage(builder, languageOffset);
		Account.addTermsandconditionsversion(
			builder,
			termsandconditionsversionOffset
		);
		Account.addAcceptcalls(builder, acceptcalls);
		Account.addRefid(builder, refidOffset);
		Account.addPasswordchanged(builder, passwordchanged);
		Account.addAddresseslist(builder, addresseslistOffset);
		Account.addOnlinestatus(builder, onlinestatus);
		Account.addLastactivetimestamp(builder, lastactivetimestamp);
		Account.addInboxid(builder, inboxidOffset);
		Account.addSectionid(builder, sectionidOffset);
		Account.addTitle(builder, titleOffset);
		Account.addWorkinghourslist(builder, workinghourslistOffset);
		Account.addDescription(builder, descriptionOffset);
		Account.addCode(builder, codeOffset);
		Account.addBaid(builder, baidOffset);
		Account.addCreatedat(builder, createdat);
		Account.addRole(builder, role);
		Account.addCompanyname(builder, companynameOffset);
		Account.addWorkspacename(builder, workspacenameOffset);
		Account.addWorkspaceid(builder, workspaceidOffset);
		Account.addEmployeetype(builder, employeetype);
		return Account.endAccount(builder);
	};
	return Account;
})();
exports.Account = Account;
