import * as flatbuffers from "flatbuffers";
import { Whiteboard_Data } from "../whiteboard_fb_generated";

export const decodeByteArray = (byteArray) => {
	let byteArr = new Uint8Array(byteArray);
	const buffer = new flatbuffers.ByteBuffer(byteArr);

	const whiteboardData = Whiteboard_Data.getRootAsWhiteboard_Data(buffer);

	const deserialisedData = {
		messageId: whiteboardData.messageId(),
		senderAccountId: whiteboardData.senderAccountId(),
		fullName: whiteboardData.fullName(),
		messageType: whiteboardData.messageType(),
		canvasId: whiteboardData.canvasId(),
		boardName: whiteboardData.boardName(),
		context: whiteboardData.context(),
		target: JSON.parse(whiteboardData.target()),
		background: whiteboardData.background(),
		objectId: whiteboardData.objectId(),
		action: whiteboardData.action(),
		...(whiteboardData.canvas()
			? {
					canvas: {
						id: whiteboardData.canvas().id(),
						canvas: whiteboardData.canvas().canvas(),
					},
			  }
			: {}),
		...(whiteboardData.target()
			? {
					target: JSON.parse(whiteboardData.target()),
			  }
			: {}),
		...(whiteboardData.account()
			? {
					account: {
						accountid: whiteboardData.account().accountid(),
						email: whiteboardData.account().email(),
						phone: whiteboardData.account().phone(),
						accounttype: whiteboardData.account().accounttype(),
						profilepic: whiteboardData.account().profilepic(),
						fullname: whiteboardData.account().fullname(),
						title: whiteboardData.account().title(),
						companyname: whiteboardData.account().companyname(),
						workspaceid: whiteboardData.account().workspaceid(),
						workspacename: whiteboardData.account().workspacename(),
						employeetype: whiteboardData.account().employeetype(),
					},
			  }
			: {}),
		...(whiteboardData.newObject()
			? {
					newObject: {
						creator: whiteboardData.newObject().creator(),
						objectId: whiteboardData.newObject().objectId(),
						svg: whiteboardData.newObject().svg(),
					},
			  }
			: {}),
		// ...(whiteboardData.target()
		// 	? {
		// 			target: {
		// 				type: whiteboardData.target().type(),
		// 				version: whiteboardData.target().version(),
		// 				origin_x: whiteboardData.target().originX(),
		// 				origin_y: whiteboardData.target().originY(),
		// 				left: whiteboardData.target().left(),
		// 				top: whiteboardData.target().top(),
		// 				width: whiteboardData.target().width(),
		// 				height: whiteboardData.target().height(),
		// 				fill: whiteboardData.target().fill(),
		// 				stroke: whiteboardData.target().stroke(),
		// 				stroke_width: whiteboardData.target().strokeWidth(),
		// 				stroke_dash_array: whiteboardData.target().strokeDashArray(),
		// 				stroke_line_cap: whiteboardData.target().strokeLineCap(),
		// 				stroke_dash_offset: whiteboardData.target().strokeDashOffset(),
		// 				stroke_line_join: whiteboardData.target().strokeLineJoin(),
		// 				stroke_uniform: whiteboardData.target().strokeUniform(),
		// 				stroke_miter_limit: whiteboardData.target().strokeMiterLimit(),
		// 				scale_x: whiteboardData.target().scaleX(),
		// 				scale_y: whiteboardData.target().scaleY(),
		// 				angle: whiteboardData.target().angle(),
		// 				flip_x: whiteboardData.target().flipX(),
		// 				flip_y: whiteboardData.target().flipY(),
		// 				opacit_y: whiteboardData.target().opacitY(),
		// 				shadow: whiteboardData.target().shadow(),
		// 				visible: whiteboardData.target().visible(),
		// 				background_color: whiteboardData.target().backgroundColor(),
		// 				fill_rule: whiteboardData.target().fillRule(),
		// 				paint_first: whiteboardData.target().paintFirst(),
		// 			},
		// 	  }
		// 	: {}),
		...(whiteboardData.newCanvas()
			? {
					newCanvas: {
						id: whiteboardData.newCanvas().id(),
						canvas: {
							svg: whiteboardData.newCanvas().canvas(),
						},
					},
			  }
			: {}),
	};
	return deserialisedData;
};
