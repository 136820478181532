import React from 'react';
import styled from 'styled-components';
import CanvasBoard from '../../../CanvasArtist/canvasBoard';
import Loader from '../../elements/Loader/Loader';
import './style.css';

const DrawingBoard = ({ artist, canvasId, loadingPreviousData, ...props }) => {
	return (
		<div className="drawing-area-container" {...props}>
			<div id="drawing-canvas-wrapper" className="drawing-canvas-wrapper">
				{loadingPreviousData && (
					<StyledLoader>
						<Loader />
					</StyledLoader>
				)}
				<CanvasBoard artist={artist} canvasId={canvasId} />
			</div>
		</div>
	);
};
const StyledLoader = styled.div`
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
	background: white;
`;

export default DrawingBoard;
