import React, { useState } from 'react';
import header_icons from '../../../assets/header_icons';
import BoardNameInput from '../../views/BoardNameInput';
import StyledBoardName from './style';

const BoardName = ({ boardName, setBoardName, updateBoardName }) => {
	const [enableInput, setEnableInput] = useState(false);
	return (
		<StyledBoardName
			onBlur={() => {
				setEnableInput(false);
				if (boardName === '') {
					setBoardName('Untitled CoVas');
				}
				updateBoardName(boardName);
			}}
		>
			{header_icons.board_icon()}
			<BoardNameInput
				updateBoardName={updateBoardName}
				enableInput={enableInput}
				setEnableInput={setEnableInput}
				boardName={boardName}
				setBoardName={setBoardName}
			/>
		</StyledBoardName>
	);
};

export default BoardName;
