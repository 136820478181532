/* eslint-disable no-restricted-globals */
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import header_icons from '../../../assets/header_icons';
import BasicModal from '../PopupModal';
import ShareText from '../ShareText/ShareText';
import StyledShareButton from './style';

const ShareButton = () => {
	let [open, setOpen] = React.useState(false);
	let toggleShareModal = () => setOpen(!open);
	let title = 'Share CoVas Link';

	return (
		<StyledShareButton>
			<BasicModal
				modalOpen={open}
				setOpen={setOpen}
				title={title}
				children={
					<ShareText textToCopy={`${location.href}`} textTitle={'CoVas Link'} />
				}
			/>
			<Tooltip title="Share CoVas" placement="bottom">
				{/* {header_icons.shareIcon()} */}
				<button
					type="button"
					className="styled-shr"
					style={{ background: '#376af5' }}
					onClick={toggleShareModal}
				>
					{header_icons.shareIcon({})}
					Share
				</button>
			</Tooltip>
		</StyledShareButton>
	);
};

export default ShareButton;
