import config from "../utils/config";

const BASE_API = config.baseApi;

export const MQTT_MESSAGE_TYPE = {
	JOIN_CANVAS_REQUEST: 'JOIN_CANVAS_REQUEST',
	NEW_PARTICIPANT: 'NEW_PARTICIPANT',
	LOAD_CANVAS_DATA: 'LOAD_CANVAS_DATA',
	NEW_OBJECT_ADDED: 'NEW_OBJECT_ADDED',
	OBJECT_MODIFIED: 'OBJECT_MODIFIED',
	CLEAR_CANVAS: 'CLEAR_CANVAS',
	REMOVE_OBJECT: 'REMOVE_OBJECT',
	CHANGE_BACKGROUND: 'CHANGE_BACKGROUND',
	NEW_CANVAS_CREATED: 'NEW_CANVAS_CREATED',
	UPDATE_BOARD_NAME: 'UPDATE_BOARD_NAME',
	LEAVE_CANVAS: 'LEAVE_CANVAS',
};

export const DRAW_ACTORS = {
	SELF_ARTIST: 'SELF_ARTIST',
	REMOTE_ARTIST: 'REMOTE_ARTIST',
};

export const API_ENDPOINTS = {
	// whiteboard
	GET_WHITEBOARDS: `${BASE_API}v1/whiteboards/`,
	GET_WHITEBOARD: `${BASE_API}v1/whiteboard`,
	CREATE_WHITEBOARD: `${BASE_API}v1/whiteboard`,
	DELETE_WHITEBOARD: `${BASE_API}v1/whiteboard/`,

	// get session
	GET_SESSION: `${BASE_API}co-account/v1/sessions`,
};
