// import { fabric } from 'fabric';
import { URLS } from '../../utils/constants';
import { getImageUrlAfterUpload, isValidUrl } from '../../utils/helper';
import LocalDb from '../../utils/localStorage';
import GridBackground from '../assets/background1.svg';
import dots_pattern from '../assets/dots_pattern.svg';
import {
	BACKGROUNDS,
	DRAW_ACTORS,
	SHAPES,
	TOOL_TYPE,
} from '../utils/constants';
import { CreateUUIDWithoutDash } from '../utils/helper';
import { createArrow } from './ArtistUtils/createArrow';
// import { createArrow } from './ArtistUtils/createArrow';
import { createStickyNotes } from './StickyNote';
const fabric = window.fabric;

class Artist {
	constructor(accountId, messagePublisher) {
		this.canvas = null;
		this.canvasId = null;
		this.accountId = accountId;
		this.canvasList = [];
		this.strokeWidth = 0.3;
		this.eraserColor = 'rgb(0,0,0,0)';
		this.strokeColor = '#000000';
		this.shapeColor = '#000000';
		this.shapeColor = this.currentTool = TOOL_TYPE.TEXTBOX;
		this.currentShape = SHAPES.SQUARE;
		this.count = 0;
		this.backgroundImage = BACKGROUNDS.WHITE;
		this.boardName = 'Untitled CoVas';
		this.messagePublisher = messagePublisher;

		this.setBackground = null;
		this.setCurrentCanvas = null;
		this.setCanvasList = null;
		this.setBoardName = null;
		this.stickyNoteColor = null;
		this.minimap = null;
		this.strokePropViewChangeCb = null;
	}

	loadStateActions(actions) {
		this.setBackground = actions.setBackground;
		this.setCurrentCanvas = actions.setCurrentCanvas;
		this.setCanvasList = actions.setCanvasList;
		this.setBoardName = actions.setBoardName;
	}

	changeStrokePropView(tool) {
		this.strokePropViewChangeCb &&
			this.strokePropViewChangeCb(tool || this.currentTool);
	}

	/**
	 *    INITIALIZE A NEW CANVAS
	 */
	initializeCanvas() {
		const id = this.canvasList.length === 0 ? 'one' : CreateUUIDWithoutDash();
		this.canvasId = `drawing-canvas-${id}`;
		this.setCurrentCanvas(this.canvasId);
		this.canvasList = [
			...this.canvasList,
			{
				id: this.canvasId,
				canvas: null,
			},
		];
		this.setCanvasList(this.canvasList);
	}

	saveNewCanvas(canvasId) {
		this.canvasList = [
			...this.canvasList,
			{
				id: canvasId,
				canvas: null,
			},
		];
		this.setCanvasList(this.canvasList);
	}

	/**
	 *  LOAD ALREADY STORED CANVAS AND ADD OBJECTS TO CANVAS
	 *
	 * @param  {String} canvasId
	 */
	startCanvas(canvasId) {
		const storedCanvas = this.canvasList.find(
			(canvas) => canvas.id === canvasId && canvas.canvas !== null
		);
		if (storedCanvas) {
			const jsonCanvas = JSON.stringify(this.canvas);
			const newCanvas = new fabric.Canvas(canvasId, {
				isDrawingMode: true,
				renderOnAddRemove: true,
				backgroundColor: 'white',
			});
			this.canvas = newCanvas;
			this.resizeCanvas(newCanvas);
			newCanvas.loadFromJSON(jsonCanvas, () => {
				newCanvas.renderAll();
			});
			newCanvas.freeDrawingBrush.color = this.strokeColor;
			newCanvas.freeDrawingBrush.width = this.strokeWidth;
			newCanvas.renderAll();
			this.canvasList.find((canvas) => canvas.id === canvasId).canvas =
				this.canvas;
			this.addCanvasEventListeners(newCanvas);
		} else {
			this.startNewCanvas(canvasId);
		}
		this.minimap = new fabric.Canvas('minimap', {
			containerClass: 'minimap',
			selection: false,
		});
		this.initMinimap();
		this.updateMiniMapVP();
	}

	/**
	 *    IF CANVAS IS NOT STORED THEN CREATE A NEW CANVAS
	 *
	 * @param  {String} canvasId
	 */
	startNewCanvas(canvasId) {
		this.canvas = new fabric.Canvas(canvasId, {
			isDrawingMode: true,
			renderOnAddRemove: true,
		});
		this.canvasList.find((canvas) => canvas.id === canvasId).canvas = this.canvas;
		this.setCanvasList(this.canvasList);
		this.backgroundImage && this.renderBackground();
		this.canvas.freeDrawingBrush.color = this.strokeColor;
		this.canvas.freeDrawingBrush.width = this.strokeWidth;
		this.canvas.renderAll();
		if (this.canvasList.length > 1) {
			this.sendNewCanvasData();
		}
		this.resizeCanvas(this.canvas);
		window.addEventListener(
			'resize',
			() => {
				this.resizeCanvas(this.canvas);
			},
			false
		);
		this.addCanvasEventListeners(this.canvas);
	}

	saveNewRemoteCanvas(canvasObj) {
		console.log('new canvas', canvasObj);
		const { id, canvas } = canvasObj;
		this.saveNewCanvas(id);
		const newCanvas = new fabric.Canvas(id, {
			isDrawingMode: true,
			renderOnAddRemove: true,
		});
		this.resizeCanvas(newCanvas);
		newCanvas.loadFromJSON(canvas, () => {
			newCanvas.renderAll();
		});
		newCanvas.freeDrawingBrush.color = this.strokeColor;
		newCanvas.freeDrawingBrush.width = this.strokeWidth;
		newCanvas.renderAll();
		this.canvasList.find((canvas) => canvas.id === id).canvas = newCanvas;
		this.addCanvasEventListeners(newCanvas);
	}

	updateBoardName(name, actor) {
		this.boardName = name;
		if (actor === 'REMOTE_ARTIST') {
			this.setBoardName(name);
		} else {
			this.messagePublisher.updateBoardName(name);
		}
	}

	/**
	 *    SEND NEW CANVAS DATA TO REMOTE PARTICIPANTS
	 */
	sendNewCanvasData() {
		const canvasData = {
			id: this.canvasId,
			canvas: JSON.stringify(this.canvas),
		};
		this.messagePublisher.newCanvasCreated(canvasData);
	}

	/**
	 *    SHOW NEXT CANVAS WHEN NEXT BUTTON CLICKED, CREATE NEW CANVAS IF END REACHED
	 */
	openNextCanvas() {
		if (
			this.canvasList.length > 0 &&
			this.canvasId === this.canvasList[this.canvasList.length - 1].id
		) {
			this.createNewCanvas();
		} else {
			const currentIndex = this.canvasList.findIndex(
				(canvas) => canvas.id === this.canvasId
			);
			const nextCanvas = this.canvasList[currentIndex + 1];
			if (nextCanvas) {
				this.canvas = nextCanvas.canvas;
				this.canvasId = nextCanvas.id;
				this.updateCurrentCanvas();
				this.addCanvasEventListeners(this.canvas);
				this.canvas.renderAll();
			}
		}
	}

	/**
	 *    SHOW PREVIOUS CANVAS WHEN PREVIOUS BUTTON CLICKED
	 */
	openPreviousCanvas() {
		if (this.canvasList.length < 1 && this.canvasId === this.canvasList[0].id) {
			return;
		}
		const currentIndex = this.canvasList.findIndex(
			(canvas) => canvas.id === this.canvasId
		);
		const previousCanvas = this.canvasList[currentIndex - 1];
		if (previousCanvas) {
			this.canvas = previousCanvas.canvas;
			this.canvasId = previousCanvas.id;
			this.updateCurrentCanvas();
			console.log('event listener active on (openPreviousCanvas)', this.canvas);

			this.addCanvasEventListeners(this.canvas);
			this.canvas.renderAll();
		}
	}

	/**
	 *    CREATE A NEW CANVAS
	 */
	createNewCanvas() {
		this.initializeCanvas();
	}

	/**
	 *    UPDATE THE CURRENT CANVAS TO THE ONE DISPLAYED
	 */
	updateCurrentCanvas() {
		this.setCurrentCanvas(this.canvasId);
		this.setCanvasList(this.canvasList);
	}

	/**
	 *    RENDER BACKGROUND TO THE IMAGE SELECTED
	 */
	renderBackground() {
		if (this.backgroundImage === BACKGROUNDS.WHITE) {
			this.canvas.backgroundImage = 0;
			this.canvas.fillStyle = '#fff';
			this.canvas.setBackgroundColor('#fff', () => {
				this.canvas.renderAll();
			});
			return;
		} else if (this.backgroundImage === BACKGROUNDS.BLACK) {
			this.canvas.backgroundImage = 0;
			this.canvas.setBackgroundColor('#000', () => {
				this.canvas.renderAll();
			});
			return;
		} else {
			this.canvas.fillStyle = '#fff';
			this.canvas.setBackgroundColor('#fff', () => {
				this.canvas.renderAll();
			});

			fabric.Image.fromURL(this.backgroundImage, (img) => {
				img.scaleToWidth(600);
				img.opacity = 0.25;

				img.set({ erasable: false });
				img.on('loaded', function () {
					// disable the erasable property of the background image
					this.canvas.bringToFront(img);
				});
				this.canvas.setBackgroundImage(img);
				this.canvas.requestRenderAll();
			});
		}
	}
	/**
	 *
	 *    CHANGE SELECTED BACKGROUND
	 *
	 * @param  {String} background
	 * @param  {String} actor
	 */
	selectBackground(background, actor) {
		switch (background) {
			case BACKGROUNDS.WHITE:
				this.backgroundImage = BACKGROUNDS.WHITE;
				this.renderBackground();
				break;
			case BACKGROUNDS.BLACK:
				this.backgroundImage = BACKGROUNDS.BLACK;
				this.renderBackground();
				break;
			case BACKGROUNDS.GRID:
				this.backgroundImage = GridBackground;
				this.renderBackground();
				break;
			case BACKGROUNDS.DOTTED:
				this.backgroundImage = dots_pattern;
				this.renderBackground();
				break;
			default:
				this.backgroundImage = null;
				this.renderBackground();
				break;
		}
		if (actor !== 'REMOTE_ARTIST') {
			this.messagePublisher.changeBackground(background, this.canvasId);
		}
	}
	/**
	 *  MAKE THE CANVAS RESPONSIVE TO THE SCREEN SIZE
	 *
	 * @param  {Object} canvas
	 */
	resizeCanvas(canvas) {
		const outerCanvasContainer = document.getElementById(
			'drawing-canvas-wrapper'
		);
		if (outerCanvasContainer) {
			// eslint-disable-next-line no-unused-vars
			const ratio = canvas.getWidth() / canvas.getHeight();
			const containerWidth = outerCanvasContainer.clientWidth;
			const scale = containerWidth / canvas.getWidth();
			const zoom = canvas.getZoom() * scale;
			canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
			canvas.setDimensions({
				width: outerCanvasContainer.clientWidth,
				height: outerCanvasContainer.clientHeight,
			});
		}
	}

	/**
	 *    ADD EVENT LISTENERS TO THE CANVAS
	 */
	addCanvasEventListeners(canvas) {
		canvas.on('object:modified', (options) => {
			this.onObjectModified(options);
			this.updateMiniMap();
		});

		canvas.on('object:scaling', function (e) {
			var obj = e.target;
			obj.set({
				strokeWidth: 0.3 / obj.scaleX, // adjust the stroke width based on the new dimensions of the object
			});
			canvas.renderAll();
			// this.updateMiniMap();
		});

		canvas.on('object:added', (options) => {
			if (options?.target?.id?.includes('ERASER')) {
				// set the selectable property to false
				// options.target.selectable = false;
				// options.target.set({ selectable: false });
				return;
			}
			console.debug('OBJECT ADDED', options, options?.type);
			this.updateMiniMap();
		});

		canvas.on('object:removed', () => {
			canvas.renderAll();
			this.updateMiniMap();
		});

		canvas.on('path:created', (options) => {
			if (options?.target?.id?.includes('ERASER')) {
				// options.target.selectable = false;
				// options.target.set({ selectable: false });
				return;
			}
			if (this.currentTool !== TOOL_TYPE.ERASER) {
				console.debug('PATH CREATED', options);
				this.onNewPathCreated(options);
			}
			// this.updateMiniMap();
		});

		canvas.on('selection:updated', (event) => {
			console.debug('SELECTION_UPDATED', event);
			const selectedObject = event.target;
			if (canvas.getActiveObjects().length > 1) {
				canvas.discardActiveObject();
			}
		});

		canvas.on('selection:created', (event) => {
			const selectedObject = event.target;
			if (canvas.getActiveObjects().length > 1) {
				canvas.discardActiveObject();
			}
			if (!selectedObject) {
				console.debug('INVALID SELECTION SELECTION_CREATED', selectedObject);
				return;
			}
			console.debug('SELECTION_CREATED', event, selectedObject);
		});

		canvas.on('object:moving', function (options) {
			var grid = 1;
			options.target.set({
				left: Math.round(options.target.left / grid) * grid,
				top: Math.round(options.target.top / grid) * grid,
			});
		});

		canvas.on('erasing:end', async ({ targets, drawables }) => {
			try {
				const objectIdsToRemove = targets.map((obj) => obj.id);
				/* The code is removing objects from a canvas in JavaScript. */
				const uniqueObjectIds = [...new Set(objectIdsToRemove)];
				const promises = [];
				/* The code is iterating over an array called "targets" and for each object in the array, it
				is calling the "remove" method on a "canvas" object and pushing the resulting promise into an
				array called "promises". */
				for (const obj of targets) {
					promises.push(canvas.remove(obj));
				}
				/* The code is using the `await` keyword to wait for multiple promises to resolve. It is
				using the `Promise.all()` method to pass an array of promises and waiting for all of them to
				resolve or reject. */
				await Promise.all(promises);

				// Now, remove objects one by one
				for (const objectId of uniqueObjectIds) {
					setTimeout(() => {
						console.debug('🚀 ~ file: object removal.js ~ line 1 ~ canvas.remove');
						this.messagePublisher.removeObject(objectId);
					}, 100);
					// this.messagePublisher.removeObject(objectId);
				}
				// this.updateMiniMap();
			} catch (error) {
				console.error(error);
			}
		});
	}
	/**
	 *  SEND A JOIN REQUEST WHEN FIRST JOINS THE CANVAS
	 */
	sendJoinCanvasRequest() {
		this.messagePublisher.joinCanvasRequest();
		// const mqttMessage = messageBuilder.joinCanvasRequest();
		// if (mqttMessage) {
		//   mqttHandler.publish(MQTT_TOPICS.WHTIEBOARD, mqttMessage);
		// }
	}
	/**
	 *    SEND ALL CANVAS DATA WHEN JOIN REQUEST RECEIVED FROM REMOTE
	 */
	sendCanvasData() {
		const svgCanvas = this.canvas.toSVG();
		this.messagePublisher.loadCanvasData(svgCanvas);
		// const mqttMessage = messageBuilder.loadCanvasData(svgCanvas);
		// if (mqttMessage) {
		//   mqttHandler.publish(MQTT_TOPICS.WHTIEBOARD, mqttMessage);
		// }
	}

	/**
	 *    ACTION ON NEW PATH CREATED BY CANVAS BRUSH
	 *
	 * @param  {Object} object
	 */
	onNewPathCreated(object) {
		const path = object.path;
		const id =
			this.currentTool === TOOL_TYPE.ERASER
				? `${CreateUUIDWithoutDash()}-ERASER`
				: CreateUUIDWithoutDash();
		// if (this.currentTool === TOOL_TYPE.ERASER) {
		// path.stroke = 'white';
		// path.selectable = false;
		// path.set({ selectable: false });
		// path.set({ id: id });
		// path.set({ objectId: id });
		// path.id = id;
		// path.objectId = id;
		// } else {
		path.id = id;
		path.creator = this.accountId;

		//* Uncomment to make the path unselectable
		// path.selectable = false;
		this.canvas.add(path);
		const svg = path.toSVG();
		const newObj = {
			id,
			creator: this.accountId,
			svg,
		};
		this.onNewObjectAdded(newObj);

		if (this.currentTool === TOOL_TYPE.LASER) {
			setTimeout(() => {
				try {
					// this.canvas.fxRemove(newObj);
					this.canvas.remove(newObj);
				} catch (error) {
					console.error(error);
				}
				this.messagePublisher.removeObject(newObj.id);
			}, 400);
		}
	}

	onNewObjectAdded(object) {
		this.messagePublisher.newObjectAdded(object, this.canvasId);
	}

	onObjectModified(event) {
		this.messagePublisher.objectModified(event, this.canvasId);
	}

	updateStorkeColor(color) {
		this.strokeColor = color;
		this.canvas.freeDrawingBrush.color = this.strokeColor;
		this.canvas.freeDrawingBrush.width = this.strokeWidth;
	}

	updateStrokeWidth(width) {
		this.strokeWidth = width / 10;
		this.canvas.freeDrawingBrush.color = this.strokeColor;
		this.canvas.freeDrawingBrush.width = this.strokeWidth;
	}

	updateStickyNoteColor(color) {
		if (!color) return;
		this.stickyNoteColor = color;
		this.createStickyNote(color);
	}

	updateShapeColor(color) {
		this.shapeColor = color;
	}

	stopDrawingMode() {
		this.canvas.isDrawingMode = false;
	}

	startDrawingMode() {
		this.canvas.isDrawingMode = true;
	}

	undoObject() {
		/* Checking if the canvas has more than one object on it. If it does, it clears the canvas. */
		if (this.canvas._objects.length <= 1) {
			this.canvas.clear();
			return;
		}
		const canvasObjects = this.canvas.getObjects();
		const userObjects = canvasObjects.filter(
			(obj) => obj.creator === this.accountId
		);
		const lastObject = userObjects[userObjects.length - 1];
		console.log('lastObj', lastObject);
		if (lastObject) {
			this.canvas.remove(lastObject);
			this.messagePublisher.removeObject(lastObject.id);
		}
	}

	enableEraser() {
		this.currentTool = TOOL_TYPE.ERASER;
		const eraserBrush = new fabric.EraserBrush(this.canvas);
		eraserBrush.globalCompositeOperation = 'destination-out';
		eraserBrush.width = 10;
		this.canvas.freeDrawingBrush = eraserBrush;
		this.canvas.isDrawingMode = true;

		// var eraserBrush = new fabric.PencilBrush(this.canvas);

		// eraserBrush.color =
		// 	this.backgroundImage === BACKGROUNDS.BLACK ? '#000' : '#fff'; // set the brush color to white
		// eraserBrush.width = 10; // set the brush width to 10 pixels
		// eraserBrush.opacity = 1; // set the brush opacity to 100%
		// eraserBrush.globalCompositeOperation = 'destination-out'; // set the composite operation to erase mode
	}

	initiateLaser() {
		var pencil = new fabric.PencilBrush(this.canvas);
		this.currentTool = TOOL_TYPE.LASER;
		//  set cursor to pencil
		this.canvas.freeDrawingBrush = pencil;
		this.canvas.defaultCursor = 'cross-hair';

		this.canvas.freeDrawingBrush.width = 1;
		this.canvas.freeDrawingBrush.color = 'red';
		this.canvas.freeDrawingBrush.shadow = new fabric.Shadow({
			blur: parseInt(10, 3) || 0,
			offsetX: 0,
			offsetY: 0,
			affectStroke: true,
			color: 'rgb(244,164,164)',
		});
		this.startDrawingMode();
	}

	createStickyNote(color = '#FFE600') {
		this.currentTool = TOOL_TYPE.STICKY_NOTE;
		this.canvas.isDrawingMode = false;
		const id = `${CreateUUIDWithoutDash()}-STICKYNOTE-${color}`;
		var options = {
			width: 100,
			height: 100,
			left: 160,
			top: 60,
			originX: 'center',
			originY: 'center',
			fill: color,
			textObj: {
				text: 'Write your note',
				fontSize: 12,
			},
		};
		var notes = createStickyNotes(this.canvas, options, (stickyNote) => {
			this.onObjectModified(stickyNote);
		});
		notes.id = id;
		this.canvas.add(notes);

		const svg = notes.toSVG();
		const object = {
			id,
			creator: this.accountId,
			svg,
		};
		this.onNewObjectAdded(object);
	}

	removeObject(object) {
		console.log(object);
		this.canvas.remove(object);
		this.messagePublisher.removeObject(object.id);
		this.renderAll();
	}
	renderAll() {
		this.canvasArtist.canvas.renderAll();
	}

	clearCanvas(canvas, actor) {
		// const svgCanvas = this.canvas.toSVG();
		// console.log("SVG CANVAS", svgCanvas);
		// fabric.loadSVGFromString(svgCanvas, (objects, options) => {
		//   console.log("objects", objects);
		// });
		canvas ? canvas.clear() : this.canvas.clear();
		// this.renderBackground();
		if (actor !== DRAW_ACTORS.REMOTE_ARTIST) {
			this.messagePublisher.clearCanvas(this.canvasId);
		}
	}

	takeScreenshot() {
		console.log(this.canvas);
		//  https://stackoverflow.com/questions/34908076/canvas-todataurl-operation-is-insecure
		this?.canvas?.setBackgroundColor('white');
		const screenshotImage = this.canvas.toDataURL({
			format: 'png',
			multiplier: 2,
			quality: 2,
			background: '#FFFFFF', // Set the background color to white
			enableRetinaScaling: true,
		});
		var link = document.createElement('a');
		link.download = this.boardName || 'Untitled CoVas';
		link.href = screenshotImage;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	enableSelector() {
		this.currentTool = TOOL_TYPE.POINTER;
		this.stopDrawingMode();
	}

	/**
	 * This function deletes selected objects from a canvas in JavaScript.
	 * @returns Nothing is being returned explicitly in this function. However, if the user is typing in a
	 * textarea, the function will return early and not execute the rest of the code.
	 */
	deleteSelectedObjectsFromCanvas() {
		try {
			var activeObjects = this.canvas.getActiveObjects();
			if (activeObjects.length > 1) {
				// Delete multiple selected objects
				for (var i = 0; i < activeObjects.length; i++) {
					let selection = activeObjects[i];
					if (document.activeElement.tagName.toLowerCase() !== 'textarea') {
						if (selection.type === 'activeSelection') {
							try {
								this.canvas.remove(selection);
							} catch (error) {
								console.log(error);
							}
							selection.forEachObject(function (element) {
								this.canvas.remove(element);
								this.canvas.renderAll();
								this.canvas.discardActiveObject();
								this.canvas.requestRenderAll();
								this.messagePublisher.removeObject(element.id);
							});
						} else {
							this.canvas.remove(selection);
							this.messagePublisher.removeObject(selection.id);
						}
						this.canvas.discardActiveObject();
					}
				}
			} else if (activeObjects.length === 1) {
				let selection = this.canvas.getActiveObject();
				if (document.activeElement.tagName.toLowerCase() !== 'textarea') {
					if (selection.type === 'activeSelection') {
						selection.forEachObject(function (element) {
							this.canvas.remove(element);
							this.messagePublisher.removeObject(element.id);
						});
					} else {
						this.canvas.remove(selection);
						this.messagePublisher.removeObject(selection.id);
					}
					this.canvas.discardActiveObject();
					this.canvas.requestRenderAll();
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	checkForPosition = (arrayOfObjects) => {
		for (const obj of arrayOfObjects) {
			if (obj.left === 10 && obj.top === 10) {
				return false;
			}
		}
		return true;
	};

	/**
	 * This function duplicates the currently selected object on a canvas and adds it to the canvas with a
	 * new ID and name.
	 * @returns The function does not return anything explicitly, but it has several return statements
	 * that will exit the function early if certain conditions are met. If there is no active object
	 * selected, the function will return without doing anything. If the clone object is not created
	 * successfully, the function will also return without doing anything.
	 */
	duplicateElement() {
		let activeObject = this.canvas.getActiveObject();
		if (!activeObject) return;

		let clone;

		clone = activeObject.clone((clone) => {
			const id = CreateUUIDWithoutDash();
			console.log('cloneee', { clone, type: clone.type });
			if (clone.type === 'text' || clone.type === 'textbox') {
				clone.set({
					id,
					creator: this.accountId,
					left: activeObject.left,
					top: activeObject.top,
					name: activeObject.name + '_clone',
				});
			} else {
				clone.set({
					id,
					creator: this.accountId,
					left: activeObject.left + 10,
					top: activeObject.top + 10,
					name: activeObject.name + '_clone',
				});
			}

			this.canvas.add(clone);
			const svg = clone.toSVG();

			const object = {
				id,
				creator: this.accountId,
				svg,
			};
			this.onNewObjectAdded(object);
			this.canvas.setActiveObject(clone);
			this.canvas.renderAll();
		});

		if (!clone) return;
	}

	async imageObjectWithURL(imageData) {
		// if (InDevelopment()) {
		// 	return {
		// 		...imageData,
		// 		url: 'https://random.imagecdn.app/1000/1000',
		// 	};
		// } else {
		try {
			let imageUrlGot = await getImageUrlAfterUpload(
				imageData,
				`${URLS.BASE_URL}${URLS.ENDPOINTS.IMAGE_UPLOAD}`,
				LocalDb.getUserToken().toString()
			);
			if (imageUrlGot === null || !isValidUrl(imageUrlGot.toString().slice(4))) {
				alert('Something went wrong while uploading\n', imageUrlGot && imageUrlGot);
				return { ...imageData, url: null };
			} else {
				return { ...imageData, url: imageUrlGot.slice(4) };
			}
		} catch (error) {
			alert('Something went wrong.');
			console.log('ERROR:', error);
		}
		// }
	}

	async addImage(event) {
		let thisHandler = this;
		const images = event.target.files;
		const imagesList = [];
		for (let i = 0; i < images.length; i++) {
			const fileData = {
				type: images[i].type,
				image: images[i],
				id: CreateUUIDWithoutDash(),
				title: images[i].name,
			};
			imagesList.push(await thisHandler.imageObjectWithURL(fileData));
		}

		imagesList &&
			imagesList.forEach((image) => {
				if (image.url) {
					image.creator = thisHandler.accountId;
					const imageId = image.id;
					fabric.Image.fromURL(
						image.url,
						(img) => {
							let imagesCount = this.canvas
								.getObjects()
								.filter((obj) => obj.type === 'image').length;

							let nothingInInitialPosition = this.checkForPosition(
								this.canvas.getObjects().filter((obj) => obj.type === 'image')
							);
							img.crossOrigin = 'anonymous';
							img.id = imageId;
							img.creator = thisHandler.accountId;
							img
								.scaleToWidth(80, true)
								.scaleToHeight(50, true)
								.set(
									nothingInInitialPosition
										? { left: 10, top: 10 }
										: { left: (imagesCount + 1) * 10, top: (imagesCount + 1) * 10 }
								);
							thisHandler.canvas.add(img);

							var imageObject = {
								id: imageId,
								creator: thisHandler.accountId,
								svg: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">${img.toSVG()}</svg>`,
							};
							thisHandler.onNewObjectAdded(imageObject);
						},
						{
							crossOrigin: 'anonymous',
						}
					);
				}
			});
	}

	addShape(shape) {
		this.currentTool = TOOL_TYPE.SHAPES;
		switch (shape) {
			case SHAPES.RECTANGLE: {
				this.addRectangle();
				this.changeStrokePropView(TOOL_TYPE.POINTER);
				break;
			}
			case SHAPES.CIRCLE: {
				this.addCircle();
				this.changeStrokePropView(TOOL_TYPE.POINTER);
				break;
			}
			case SHAPES.TRIANGLE: {
				this.addTriangle();
				this.changeStrokePropView(TOOL_TYPE.POINTER);
				break;
			}
			case SHAPES.LINE: {
				this.addLine();
				this.changeStrokePropView(TOOL_TYPE.POINTER);
				break;
			}
			case SHAPES.ARROW: {
				// this.addArrow();
				this.canvas.selection = false;
				// this.canvas.getObjects().forEach(function (object) {
				// 	object.selectable = false;
				// });
				createArrow(this.canvas, (object) => {
					this.onNewObjectAdded(object);
					this.enableSelector();
					this.updateMiniMap();
					// this.canvas.setActiveObject(object);
					this.changeStrokePropView(TOOL_TYPE.POINTER);
					this.canvas.renderAll();
				});
				break;
			}
			case SHAPES.STAR: {
				this.addStar();
				this.changeStrokePropView(TOOL_TYPE.POINTER);
				break;
			}
			case SHAPES.DECISION: {
				this.addDecision();
				this.changeStrokePropView(TOOL_TYPE.POINTER);
				break;
			}
			case SHAPES.DATA: {
				this.addData();
				this.changeStrokePropView(TOOL_TYPE.POINTER);
				break;
			}
			case SHAPES.PROCESS: {
				this.addProcess();
				this.changeStrokePropView(TOOL_TYPE.POINTER);
				break;
			}
			case SHAPES.TERMINAL: {
				this.addTerminal();
				this.changeStrokePropView(TOOL_TYPE.POINTER);
				break;
			}
			default:
				break;
		}
	}

	addRectangle() {
		this.stopDrawingMode();
		this.currentTool = TOOL_TYPE.SHAPES;
		this.currentShape = SHAPES.RECTANGLE;
		const id = CreateUUIDWithoutDash();
		const rect = new fabric.Rect({
			id,
			creator: this.accountId,
			top: 10,
			left: 10,
			width: 15,
			height: 15,
			hasBorders: true,
			fill: 'rgb(255,255,255, 0.01)',
			strokeWidth: 0.3,
			stroke: this.shapeColor,
			paintFirst: 'stroke',
			borderScaleFactor: 0.2,
		});
		this.canvas.add(rect);
		const svg = rect.toSVG();
		const object = {
			id,
			creator: this.accountId,
			svg,
		};
		// debugger;
		this.onNewObjectAdded(object);
	}

	addCircle() {
		this.stopDrawingMode();
		this.currentTool = TOOL_TYPE.SHAPES;
		this.currentShape = SHAPES.CIRCLE;
		const id = CreateUUIDWithoutDash();
		const circle = new fabric.Circle({
			id,
			creator: this.accountId,
			radius: 7.5,
			fill: 'rgb(255,255,255, 0)',
			top: 10,
			left: 10,
			strokeWidth: 0.3,
			stroke: this.shapeColor,
			paintFirst: 'stroke',
			borderScaleFactor: 0.2,
		});
		this.canvas.add(circle);
		const svg = circle.toSVG();
		const object = {
			id,
			creator: this.accountId,
			svg,
		};
		this.onNewObjectAdded(object);
	}

	addTriangle() {
		this.stopDrawingMode();
		this.currentTool = TOOL_TYPE.SHAPES;
		this.currentShape = SHAPES.TRIANGLE;
		const id = CreateUUIDWithoutDash();
		const triangle = new fabric.Triangle({
			id,
			creator: this.accountId,
			top: 10,
			left: 10,
			width: 15,
			height: 15,
			hasBorders: true,
			fill: 'rgb(255,255,255, 0.01)',
			strokeWidth: 0.3,
			stroke: this.shapeColor,
			paintFirst: 'stroke',
			borderScaleFactor: 0.2,
		});
		this.canvas.add(triangle);
		const svg = triangle.toSVG();
		const object = {
			id,
			creator: this.accountId,
			svg,
		};
		this.onNewObjectAdded(object);
	}

	addStar() {
		this.stopDrawingMode();
		this.currentTool = TOOL_TYPE.SHAPES;
		this.currentShape = SHAPES.STAR;
		const id = CreateUUIDWithoutDash();

		let thisHandler = this;

		// let arrowStr = `<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		// <path d="M16.2133 1.0043L18.9667 9.47847C19.1341 9.9935 19.614 10.3422 20.1555 10.3422L29.0658 10.3422C29.7924 10.3422 30.0944 11.2719 29.5066 11.699L22.2981 16.9363C21.86 17.2546 21.6767 17.8188 21.844 18.3338L24.5974 26.808C24.8219 27.499 24.0311 28.0736 23.4433 27.6465L16.2347 22.4092C15.7966 22.0909 15.2034 22.0909 14.7653 22.4092L7.55671 27.6465C6.96892 28.0736 6.17806 27.499 6.40258 26.808L9.156 18.3338C9.32335 17.8188 9.14002 17.2546 8.70191 16.9363L1.49335 11.699C0.905569 11.2719 1.20765 10.3422 1.93419 10.3422L10.8445 10.3422C11.386 10.3422 11.8659 9.9935 12.0333 9.47847L14.7867 1.0043C15.0112 0.313321 15.9888 0.31332 16.2133 1.0043Z" stroke="${this.shapeColor}" strokeWidth="0.5" stroke-linejoin="round"/>
		// </svg>
		// `;
		let arrowStr = `
			<svg width="15" height="14.032" viewBox="0 0 15 14.032" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path 
				d="M7.845 0.486L9.177 4.586C9.258 4.836 9.491 5.004 9.753 5.004L14.064 5.004C14.416 5.004 14.562 5.454 14.277 5.661L10.789 8.195C10.577 8.349 10.489 8.622 10.57 8.871L11.902 12.972C12.011 13.306 11.628 13.584 11.344 13.377L7.856 10.843C7.644 10.689 7.356 10.689 7.144 10.843L3.656 13.377C3.372 13.584 2.989 13.306 3.098 12.972L4.43 8.871C4.511 8.622 4.423 8.349 4.211 8.195L0.723 5.661C0.438 5.454 0.584 5.004 0.936 5.004L5.247 5.004C5.509 5.004 5.742 4.836 5.823 4.586L7.155 0.486C7.263 0.152 7.737 0.152 7.845 0.486Z" 
				stroke="${this.shapeColor}" 
				strokeWidth="0.3" 
				stroke-linejoin="round"/>
			</svg>
		`;

		fabric.loadSVGFromString(arrowStr, function (objects, { selectable }) {
			let objLength = objects.length;
			for (let i = 0; i < objLength; ++i) {
				let obj = objects[i];
				obj['customProp'] = 'star';
				obj.selectable = true;
				obj.targetFindTolerance = true;
				obj.set({
					top: 11,
					left: 11,
					hasControls: true,
				});
				obj.id = id;
				obj.creator = thisHandler.accountId;
				const svgObj = obj.toSVG();
				const object = {
					id,
					creator: thisHandler.accountId,
					svg: svgObj,
				};
				thisHandler.canvas.add(obj).renderAll();
				thisHandler.onNewObjectAdded(object);
			}
		});
	}

	addLine() {
		this.stopDrawingMode();
		this.currentTool = TOOL_TYPE.SHAPES;
		this.currentShape = SHAPES.LINE;
		const id = CreateUUIDWithoutDash();

		const line = new fabric.Line([10, 10, 10, 30], {
			id,
			creator: this.accountId,
			strokeWidth: 0.3,
			left: 10,
			top: 10,
			fill: 'red',
			stroke: this.shapeColor,
			selectable: true,
			targetFindTolerance: true,
		});

		line.setControlsVisibility({
			tl: false,
			tr: false,
			br: false,
			bl: false,
			ml: false,
			mt: true,
			mr: false,
			mb: true,
			mtr: true,
		});

		this.canvas.add(line);
		const svg = line.toSVG();
		const object = {
			id,
			creator: this.accountId,
			svg,
		};
		this.onNewObjectAdded(object);
	}

	addArrow() {
		let thisHandler = this;
		let arrowSVGStr = `<svg width="21" height="5.6" viewBox="0 0 21 5.6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.748 2.948a0.21 0.21 0 0 0 0 -0.297l-1.336 -1.337a0.21 0.21 0 0 0 -0.298 0.297L19.303 2.8l-1.189 1.189a0.21 0.21 0 0 0 0.298 0.297l1.336 -1.337ZM0 3.01h19.6V2.59H0v0.42Z" fill="${thisHandler.shapeColor}"/></svg>`;
		this.addSVGShape(arrowSVGStr, SHAPES.ARROW, true, false);
	}

	addProcess() {
		let thisHandler = this;
		let arrowSVGStr = `<svg width="163" height="66" viewBox="0 0 163 66" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="1.5" y="1.5" width="160" height="63" stroke="${thisHandler.shapeColor}" strokeWidth="1.5"/>
		</svg>
		`;
		this.addSVGShape(arrowSVGStr, SHAPES.PROCESS);
	}

	addTerminal() {
		let thisHandler = this;
		let startStopShapeSVGStr = `<svg width="163" height="66" viewBox="0 0 163 66" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="1.5" y="1.5" width="160" height="63" rx="31.5"  stroke="${thisHandler.shapeColor}" strokeWidth="1.5"/>
		</svg>
		`;
		this.addSVGShape(startStopShapeSVGStr, SHAPES.TERMINAL);
	}

	addDecision() {
		let thisHandler = this;
		let decisionShapeSVG = `<svg width="108" height="71" viewBox="0 0 108 71" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M105.269 35.5L54 69.2049L2.73059 35.5L54 1.79511L105.269 35.5Z" stroke="${thisHandler.shapeColor}" strokeWidth="1.5"/>
		</svg>
		`;
		this.addSVGShape(decisionShapeSVG, SHAPES.DECISION);
	}

	addData() {
		let thisHandler = this;
		let dataShapeSvg = `<svg width="198" height="66" viewBox="0 0 198 66" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.49332 64.5L35.9024 1.5H195.507L162.098 64.5H2.49332Z" stroke="${thisHandler.shapeColor}" strokeWidth="1.5"/>
		</svg>
		`;
		this.addSVGShape(dataShapeSvg, SHAPES.DATA);
	}

	addSVGShape(svgStr, shapeType, selectable = true, scaleToWidth = true) {
		this.stopDrawingMode();
		let thisHandler = this;
		this.currentTool = TOOL_TYPE.SHAPES;
		this.currentShape = shapeType;
		const id = CreateUUIDWithoutDash();

		fabric.loadSVGFromString(svgStr, function (objects, { selectable }) {
			let objLength = objects.length;
			for (let i = 0; i < objLength; ++i) {
				let obj = objects[i];
				obj.selectable = true;
				obj.targetFindTolerance = true;
				if (scaleToWidth) obj.scaleToWidth(10, true).scaleToHeight(10, true);
				obj.set({
					top: 8.5,
					left: 10,
					hasControls: true,
				});
				obj.id = id;
				obj.creator = thisHandler.accountId;
				const svgObj = obj.toSVG();
				const object = {
					id,
					creator: thisHandler.accountId,
					svg: svgObj,
				};
				thisHandler.canvas.add(obj);
				thisHandler.onNewObjectAdded(object);
			}
		});
	}

	addTextbox() {
		this.stopDrawingMode();
		const id = CreateUUIDWithoutDash();
		const creator = this.accountId;
		let textBoxCount = this.canvas
			.getObjects()
			.filter((obj) => obj.type === 'textbox').length;

		let noTextboxInInitialPosition = this.checkForPosition(
			this.canvas.getObjects().filter((obj) => obj.type === 'textbox')
		);
		const textbox = new fabric.Textbox('Text', {
			id,
			creator,
			top: noTextboxInInitialPosition ? 10 : (textBoxCount + 1) * 10,
			left: noTextboxInInitialPosition ? 10 : (textBoxCount + 1) * 10,
			fontSize: 5,
			height: 5,
			textAlign: 'left',
			fontFamily: 'Arial',
			fontWeight: 300,
		});
		textbox.set('fontFamily', 'Arial');
		this.canvas.add(textbox);
		const object = {
			id,
			creator,
			svg: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">${textbox.toSVG()}</svg>`,
		};
		this.onNewObjectAdded(object);
	}

	initializePencil() {
		var pencil = new fabric.PencilBrush(this.canvas);
		this.currentTool = TOOL_TYPE.STROKE_PROP_SELECTOR;
		//  set cursor to pencil
		this.canvas.freeDrawingBrush = pencil;
		this.canvas.defaultCursor = 'cross-hair';
		this.canvas.freeDrawingBrush.color = this.strokeColor || '#000';
		// this.canvas.freeDrawingBrush.globalCompositeOperation = 'source-over';
		this.canvas.freeDrawingBrush.width = this.strokeWidth;
		this.startDrawingMode();
	}

	createCanvasEl() {
		var designSize = { width: 280, height: 200 };
		console.log({ canvas: this.canvas });
		var originalVPT = this.canvas?.viewportTransform;
		// zoom to fit the design in the display canvas
		var designRatio = fabric.util.findScaleToFit(designSize, this.canvas);
		// zoom to fit the display the design in the minimap.
		var minimapRatio = fabric.util.findScaleToFit(this.canvas, this.minimap);
		var scaling = this.minimap.getRetinaScaling();

		var finalWidth = designSize.width * designRatio;
		var finalHeight = designSize.height * designRatio;

		this.canvas.viewportTransform = [
			designRatio,
			0,
			0,
			designRatio,
			(this.canvas.getWidth() - finalWidth) / 2,
			(this.canvas.getHeight() - finalHeight) / 2,
		];
		var canvas = this.canvas.toCanvasElement(minimapRatio * scaling);
		this.canvas.viewportTransform = originalVPT;
		return canvas;
	}

	updateMiniMap() {
		var canvas = this.createCanvasEl();
		this.minimap.backgroundImage._element = canvas;
		this.minimap.requestRenderAll();
	}

	updateMiniMapVP() {
		// var designSize = { width: 800, height: 600 };
		// var rect = this.minimap.getObjects()[0];
		// var designRatio = fabric.util.findScaleToFit(designSize, this.canvas);
		// var totalRatio = fabric.util.findScaleToFit(designSize, this.minimap);
		// var finalRatio = designRatio / this.canvas.getZoom();
		// rect.scaleX = finalRatio;
		// rect.scaleY = finalRatio;
		// rect.top =
		// 	this.minimap.backgroundImage.top -
		// 	(this.canvas.viewportTransform[5] * totalRatio) / this.canvas.getZoom();
		// rect.left =
		// 	this.minimap.backgroundImage.left -
		// 	(this.canvas.viewportTransform[4] * totalRatio) / this.canvas.getZoom();
		// this.minimap.requestRenderAll();
	}

	initMinimap() {
		var canvas = this.createCanvasEl();
		var backgroundImage = new fabric.Image(canvas);
		backgroundImage.scaleX = 1 / this.canvas.getRetinaScaling();
		backgroundImage.scaleY = 1 / this.canvas.getRetinaScaling();
		this.minimap.centerObject(backgroundImage);
		this.minimap.backgroundColor = 'white';
		this.minimap.backgroundImage = backgroundImage;
		this.minimap.requestRenderAll();
		var minimapView = new fabric.Rect({
			top: backgroundImage.top,
			left: backgroundImage.left,
			width: backgroundImage.width / this.canvas.getRetinaScaling(),
			height: backgroundImage.height / this.canvas.getRetinaScaling(),
			fill: 'rgba(0, 0, 255, 0.3)',
			cornerSize: 6,
			transparentCorners: false,
			cornerColor: 'blue',
			strokeWidth: 0,
		});
		minimapView.controls = {
			br: fabric.Object.prototype.controls.br,
		};
		// this.minimap.add(minimapView);
	}

	// var debouncedMiniMap = _.debounce(updateMiniMap, 250);
}

export default Artist;

export class Image {
	constructor(imageData) {
		this.image = imageData.image;
		this.title = imageData.title;
		this.id = imageData.id;
		this.isUploading = true;
		this.isUploaded = false;
		this.url = URL.createObjectURL(imageData.image);
		this.imageUrl = null;
	}

	uploadSuccess(url) {
		this.isUploading = false;
		this.isUploaded = true;
		this.imageUrl = url;
	}

	uploadFailure() {
		this.isUploading = false;
		this.isUploaded = false;
	}
}
