/* eslint-disable no-restricted-globals */
import { Alert, AlertTitle, Snackbar, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import Artist from '../CanvasArtist/Artist/Artist.js';
import RemoteArtist from '../CanvasArtist/Artist/RemoteArtist.js';
import BasicModal from '../components/elements/PopupModal/index.jsx';
import DrawingLayout from '../components/Layout/DrawingLayout/DrawingLayout';
import MainLayout from '../components/Layout/MainLayout/MainLayout';
import DrawingBoard from '../components/views/DrawingBoard/DrawingBoard';
import DrawingTools from '../components/views/DrawingTools';
import Header from '../components/views/Header';
import MessagePublisher from '../Mqtt/messagePublisher';
import MqttHandler from '../Mqtt/mqttSetup';
import { relayServerConnection } from '../routes/PrivateRoute.js';
import {
	BACKGROUNDS,
	MQTT_TOPICS,
	SNACKBAR_ALERT_TYPES,
} from '../utils/constants';
import LocalDb from '../utils/localStorage';
// import SnackBar from "../components/elements/SnackBar/SnackBar.jsx";
import StyledShareButton from '../components/elements/ShareButton/style.js';
import { decodeByteArray } from '../flatbuffers/flatbuffer_helpers/fb_decoder.js';
import { MQTT_MESSAGE_TYPE } from '../Mqtt/constants.js';
import {
	fetchInitialCanvasData,
	handleNewDrawingEvents,
	handleUpdateBoardName,
	renderPreviousEvents,
} from './Board.Controller.js';

let paramArr = location.pathname.split('/');
let canvasRoomId = paramArr[paramArr.length - 1];

const accountId = LocalDb.getUserAccountId();

const token =
	localStorage.getItem('anyDoneSession') &&
	JSON.parse(localStorage.getItem('anyDoneSession')).token;
export const mqttHandler = new MqttHandler(accountId, token, canvasRoomId);
export const messagePublisher = new MessagePublisher(mqttHandler, canvasRoomId);

export const artist = new Artist(accountId, messagePublisher);
export const remoteArtist = new RemoteArtist(artist);

function Board() {
	// states
	const [selectedBackground, setBackground] = useState(BACKGROUNDS.WHITE);
	const [currentCanvas, setCurrentCanvas] = useState(false);
	const [canvasList, setCanvasList] = useState([]);
	const [boardName, setBoardName] = useState('Untitled CoVas');
	const [canvasActiveUsersList, setCanvasActiveUsersList] = useState([
		{ account: LocalDb.getUserAccount() },
	]);
	const [initialCanvas, setInitialCanvas] = useState(null);
	const [alertMessage, setAlertMessage] = useState('');
	const [loadingPreviousData, setLoadingPreviousData] = useState(false);
	const [objectsToAck, setObjectsToAck] = useState([]);
	const [eventsSaving, setEventsSaving] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertSeverity, setAlertSeverity] = useState('info');
	const [openConfirmation, setOpenConfirmation] = useState(false);

	// params
	// Get the current URL, and params
	const currentUrl = window.location.href;
	const url = new URL(currentUrl);
	const searchParams = url.searchParams;

	// Extract specific query parameters by name
	const whiteboardContext = searchParams.get('context');

	const showAlertWithSeverity = (severity, alertMessage = '') => {
		setAlertSeverity(severity);
		setAlertMessage(alertMessage);
		setShowAlert(true);
	};

	useEffect(() => {
		try {
			if (canvasRoomId) {
				setLoadingPreviousData(true);
				getPreviousData().catch((e) => {
					console.log('error', e);
					setLoadingPreviousData(false);
				});
			}
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		if (initialCanvas) {
			try {
				renderPreviousEvents(initialCanvas, () => {
					setLoadingPreviousData(false);
				});
			} catch (error) {
				setLoadingPreviousData(false);
			}
		}
	}, [initialCanvas]);

	useEffect(() => {
		artist.loadStateActions({
			setBackground,
			setCurrentCanvas,
			setCanvasList,
			setBoardName,
		});
		artist.initializeCanvas();
		setTimeout(() => {
			messagePublisher.publishOwnSessionToWB();
		}, 2 * 1000);
	}, []);

	function handleSetCanvasActiveUsersList(newUser) {
		/* This is to avoid duplicate users in the list. */
		if (canvasActiveUsersList.length > 0) {
			const newArr = canvasActiveUsersList.filter(
				(user) => user.account.accountid !== newUser.account.accountid
			);
			setCanvasActiveUsersList([...newArr, newUser]);
			return;
		}
		setCanvasActiveUsersList([...canvasActiveUsersList, newUser]);
	}

	const handleSelectBackground = (background) => {
		setBackground(background);
		artist.selectBackground(background);
	};

	useEffect(() => {
		try {
			console.log('MQTT CONNECTION:', relayServerConnection.isConnected());
			if (!relayServerConnection.isConnected()) {
				mqttHandler.startConnection();
			}
		} catch (error) {
			console.log('ERROR CONNECTING MQTT', error);
		}
	}, []);

	async function getPreviousData() {
		const data = await fetchInitialCanvasData(canvasRoomId, whiteboardContext);
		if (data.success) {
			setBoardName(data.whiteboard.name);
			if (data.whiteboard.whiteboardCanvas[0].canvas) {
				setInitialCanvas(data.whiteboard.whiteboardCanvas[0].canvas);
			} else {
				setLoadingPreviousData(false);
			}
		}
		if (data.error) {
			showAlertWithSeverity(SNACKBAR_ALERT_TYPES.ERROR, data.msg);
			setLoadingPreviousData(false);
		}
	}

	async function updateWhiteBoardName(name) {
		let data = await handleUpdateBoardName(name, canvasRoomId);
		if (data.success) {
			artist.updateBoardName(name);
			showAlertWithSeverity(
				SNACKBAR_ALERT_TYPES.SUCCESS,
				'Successfully updated the name'
			);
		}
		if (!data.success) {
			showAlertWithSeverity(
				SNACKBAR_ALERT_TYPES.ERROR,
				'There was an error while updating the name'
			);
		}
	}

	function clearWb() {
		setOpenConfirmation(true);
	}

	useEffect(() => {
		if (canvasRoomId) {
			// subscribe to drawing
			relayServerConnection.subscribeWithHandler(
				`${MQTT_TOPICS.WHITEBOARD}${canvasRoomId}/canvas/1`,
				(topic, payload) => {
					if (payload) {
						setEventsSaving(true);
						let messagePayload = decodeByteArray(payload);
						const { messageId, senderAccountId } = messagePayload;

						if (
							senderAccountId === accountId &&
							messagePayload.messageType !== MQTT_MESSAGE_TYPE.NEW_PARTICIPANT
						) {
							// if the event is published by me, add the object to 'objectsToAck' list
							setObjectsToAck([...objectsToAck, messageId]);
						} else if (objectsToAck.length === 0) {
							// if there's no objects left to acknowledge, set the 'eventsSaving' state to false.
							setEventsSaving(false);
						}
						handleNewDrawingEvents(messagePayload, (messagePayload) => {
							if (messagePayload?.account?.accountid === accountId) return;
							// messagePublisher.publishOwnSessionToWB();
							handleSetCanvasActiveUsersList(messagePayload);
						});
					}
				}
			);

			relayServerConnection.subscribeWithHandler(
				`${MQTT_TOPICS.WHITEBOARD}ack/${accountId}`,
				(topic, payload) => {
					if (payload) {
						setEventsSaving(true);
						console.debug('messagePayload ack');
						let messagePayload = decodeByteArray(payload);
						const { messageId } = messagePayload;
						// when the acknowledge event is received, remove the event from the list of events left to be acknowledged
						const remainingObjectsToAck = objectsToAck.filter((i) => i !== messageId);
						setObjectsToAck(remainingObjectsToAck);

						// if objects left to acknowledge is 0, set the 'eventsSaving' state to false
						if (remainingObjectsToAck.length === 0) {
							setEventsSaving(false);
						}
						handleNewDrawingEvents(messagePayload, (messagePayload) => {
							if (messagePayload?.account?.accountid === accountId) return;
							handleSetCanvasActiveUsersList(messagePayload);
						});
					}
				}
			);
		}
	}, [canvasRoomId, relayServerConnection.isConnected()]);

	return (
		<>
			<MainLayout>
				<Header
					clearCanvas={clearWb}
					selectBackground={handleSelectBackground}
					selectedBackground={selectedBackground}
					nextCanvas={() => artist.openNextCanvas()}
					prevCanvas={() => artist.openPreviousCanvas()}
					canvasList={canvasList}
					currentCanvas={currentCanvas}
					boardName={boardName}
					setBoardName={setBoardName}
					updateBoardName={(name) => {
						updateWhiteBoardName(name);
					}}
					canvasActiveUsersList={canvasActiveUsersList}
					loadingPreviousData={loadingPreviousData}
					saving={eventsSaving}
				/>
				<DrawingLayout>
					<DrawingTools
						artist={artist}
						addShape={(shape) => artist.addShape(shape)}
						addTextbox={() => artist.addTextbox()}
						startPencil={() => artist.initializePencil()}
						clearCanvas={clearWb}
						updateStrokeColor={(color) => artist.updateStorkeColor(color)}
						updateStrokeWidth={(width) => artist.updateStrokeWidth(width)}
						updateStickyNoteColor={(color) => artist.updateStickyNoteColor(color)}
						updateShapeColor={(color) => artist.updateShapeColor(color)}
						enableSelector={() => artist.enableSelector()}
						undo={() => artist.undoObject()}
						startLaser={() => artist.initiateLaser()}
						takeScreenshot={() => artist.takeScreenshot()}
						enableEraser={() => artist.enableEraser()}
						addImage={(e) => artist.addImage(e)}
						createStickyNote={() => artist.createStickyNote()}
						// todo: add an object remove feature
					/>

					{canvasList.map((canvas) => {
						if (canvas.id === currentCanvas) {
							return (
								<DrawingBoard
									key={canvas.id}
									canvasId={canvas.id}
									artist={artist}
									loadingPreviousData={loadingPreviousData}
								/>
							);
						}
						return (
							<div style={{ display: 'none' }} key="drawing-canvas-wrapper-hidden" />
						);
					})}

					{showAlert && (
						<Snackbar
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							open={showAlert}
							autoHideDuration={6000}
							onClose={() => setShowAlert(false)}
						>
							<Alert severity={alertSeverity} onClose={() => setShowAlert(false)}>
								<AlertTitle>{alertSeverity.toUpperCase()}</AlertTitle>
								{alertMessage || ''}
							</Alert>
						</Snackbar>
					)}
				</DrawingLayout>
				<div>
					<BasicModal
						modalOpen={openConfirmation}
						setOpen={setOpenConfirmation}
						title="Clear CoVas"
					>
						<h4 style={{ fontWeight: '500' }}>
							Are you sure you want to clear the CoVas ?
						</h4>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row-reverse',
								alignItems: 'center',
								width: '100%',
								gap: '20px',
							}}
						>
							<StyledShareButton>
								<Tooltip title="Clear CoVas" placement="bottom">
									{/* {header_icons.shareIcon()} */}
									<button
										type="button"
										className="styled-shr"
										style={{ background: '#376af5' }}
										onClick={() => {
											artist.clearCanvas();
											setOpenConfirmation(false);
										}}
									>
										Clear
									</button>
								</Tooltip>
							</StyledShareButton>
							<div
								style={{ cursor: 'pointer' }}
								onClick={() => setOpenConfirmation(false)}
							>
								Close
							</div>
						</div>
					</BasicModal>
				</div>
			</MainLayout>
		</>
	);
}

export default Board;
