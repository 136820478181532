import React from 'react';

const ToolButton = ({ className, name, type, onClick, selected, icon }) => {
	return (
		<button
			className={className}
			name={name}
			type={type}
			onClick={onClick}
			style={{
				color: selected ? '#376AF5' : undefined,
				...(selected && { background: '#D3EAFB' }),
			}}
		>
			{icon}
		</button>
	);
};

export default ToolButton;
