import React from "react";
import Tooltip from "@mui/material/Tooltip";
import header_icons from "../../../assets/header_icons";
import StyledCanvasPagination from "./style";
// import Board from "../../../pages/Board";

const CanvasPagination = ({
	canvasList,
	currentCanvas,
	prevCanvas,
	nextCanvas,
}) => {
	const currentPage =
		canvasList &&
		canvasList.findIndex((canvas) => canvas.id === currentCanvas) + 1;
	return (
		<StyledCanvasPagination>
			<div className="arrow-container left-arrow-container">
				<Tooltip title="Previous Board" placement="bottom">
					<button
						onClick={prevCanvas}
						className="no-border-button"
						type="button"
					>
						{currentPage === 1
							? header_icons.left_arrow({ color: "#C2C2C2" })
							: header_icons.left_arrow({ color: "#666666" })}
					</button>
				</Tooltip>
			</div>
			<div className="center-number-container">
				{/* <div className="top-bar" /> */}
				<div className="bottom-bar" />
				<div className="pagintaion-box">{`${currentPage}/${canvasList.length}`}</div>
			</div>

			<div className="arrow-container right-arrow-container">
				<Tooltip title="Next Board" placement="bottom">
					<button
						onClick={nextCanvas}
						className="no-border-button"
						type="button"
					>
						{header_icons.right_arrow()}
					</button>
				</Tooltip>
			</div>
		</StyledCanvasPagination>
	);
};

export default CanvasPagination;
