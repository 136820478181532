import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import StrokeSelector from '../StrokeSelector';
import './style.css';
import ShapesSelector from '../ShapesSelector';
import ToolIcons from '../../../assets/ToolIcons';
import { TOOL_TYPE } from '../../../utils/constants';
import ToolButtonWithTooltip from './ToolButtons/ToolButtonWithTooltipHOC';
import useKeyboardEvents from '../../../Hooks/useKeyboardEvents';
import StickyNoteOptions from '../StickyNote/StickyNoteOptions';

const DrawingTools = ({
	artist,
	addShape,
	addTextbox,
	startPencil,
	updateStrokeWidth,
	updateStrokeColor,
	updateShapeColor,
	enableSelector,
	undo,
	takeScreenshot,
	enableEraser,
	startLaser,
	createStickyNote,
	updateStickyNoteColor,
	addImage,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [openSelectionBox, setOpenSelectionBox] = useState(false);
	const [strokeWidth, setStrokeWidth] = useState(10);
	const [stickyNoteColor, setStickyNoteColor] = useState('#FFE600');
	const [strokeColor, setStrokeColor] = useState('#000000');
	const [shapeColor, setShapeColor] = useState('#000000');
	const [selectedTool, setSelectedTool] = useState(
		TOOL_TYPE.STROKE_PROP_SELECTOR
	);

	artist.strokePropViewChangeCb = (newProp) => {
		setSelectedTool(newProp);
	};

	useKeyboardEvents(artist, {
		setSelectedTool,
		selectedTool,
	});

	const handleUpdateStrokeWidth = (event, newValue) => {
		setStrokeWidth(newValue);
		updateStrokeWidth(newValue);
	};

	const handleUpdateStrokeColor = (color, event) => {
		setStrokeColor(color.hex);
		updateStrokeColor(color.hex);
	};

	const handleUpdateStickyNoteColor = (color, event) => {
		setStickyNoteColor(color.hex);
		updateStickyNoteColor(color.hex);
	};

	const handleClick = (event, boxType) => {
		setAnchorEl(event.currentTarget);
		setOpenSelectionBox(boxType);
	};

	const handleCloseSelectionBox = () => {
		setOpenSelectionBox(false);
		setAnchorEl(null);
	};

	const handleSelectShape = (e, shape) => {
		console.log(shape);
		handleCloseSelectionBox();
		addShape(shape.type);
	};

	const handleUpdateShapeColor = (color, event) => {
		setShapeColor(color.hex);
		updateShapeColor(color.hex);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<div className="drawing-tools-container">
				<div className="tool-box-wrapper">
					{/* TEXTBOX */}
					<ToolButtonWithTooltip
						title="Add Text (Ctrl/Cmd + Opt/Alt + T)"
						className="tool-button"
						name={TOOL_TYPE.TEXTBOX}
						type="button"
						selectedTool={selectedTool}
						onClick={() => {
							setSelectedTool(TOOL_TYPE.TEXTBOX);
							addTextbox();
						}}
						icon={
							selectedTool === TOOL_TYPE.TEXTBOX
								? ToolIcons.text_box({ color: '#376AF5' })
								: ToolIcons.text_box()
						}
					/>

					<ToolButtonWithTooltip
						title="Brush (Ctrl/Cmd + P)"
						className="tool-button"
						selectedTool={selectedTool}
						name={TOOL_TYPE.STROKE_PROP_SELECTOR}
						type="button"
						onClick={(e) => {
							selectedTool === TOOL_TYPE.STROKE_PROP_SELECTOR &&
								handleClick(e, 'STROKE_SELECTOR');
							setSelectedTool(TOOL_TYPE.STROKE_PROP_SELECTOR);
							startPencil();
						}}
						icon={
							selectedTool === TOOL_TYPE.STROKE_PROP_SELECTOR
								? ToolIcons.stroke_props({ color: '#376AF5' })
								: ToolIcons.stroke_props()
						}
					/>

					{/* ENABLE SELECTOR */}
					<ToolButtonWithTooltip
						title="Select (Ctrl/Cmd + V)"
						name={TOOL_TYPE.POINTER}
						selectedTool={selectedTool}
						onClick={() => {
							setSelectedTool(TOOL_TYPE.POINTER);
							enableSelector();
						}}
						icon={
							selectedTool === TOOL_TYPE.POINTER
								? ToolIcons.pointer({ color: '#376AF5' })
								: ToolIcons.pointer()
						}
					/>

					{/* STROKE PROPS SELECTOR */}
					{/* <Tooltip  placement="right">
						<button
							className="tool-button"
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '4px',
							}}
							name={TOOL_TYPE.STROKE_PROP_SELECTOR}
							type="button"
							onClick={(e) => {
								selectedTool === TOOL_TYPE.STROKE_PROP_SELECTOR &&
									handleClick(e, 'STROKE_SELECTOR');
								setSelectedTool(TOOL_TYPE.STROKE_PROP_SELECTOR);
								startPencil();
							}}
						>
							{selectedTool === TOOL_TYPE.STROKE_PROP_SELECTOR
								? ToolIcons.stroke_props({ color: '#376AF5' })
								: ToolIcons.stroke_props()}
						</button>
					</Tooltip> */}

					{/* OPACITY SELECTOR */}
					{/* <Tooltip title="Opacity" placement="right">
            <button
              className="tool-button"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
              name="opacity"
              type="button"
              onClick={() => {
                setSelectedTool(TOOL_TYPE.OPACITY_SELECTOR);
              }}
            >
              {selectedTool === TOOL_TYPE.OPACITY_SELECTOR
                ? ToolIcons.opacity_selector({ color: "#376AF5" })
                : ToolIcons.opacity_selector()}
            </button>
          </Tooltip> */}

					{/* ERASER */}
					<ToolButtonWithTooltip
						title="Eraser (Ctrl/Cmd + E)"
						name={TOOL_TYPE.ERASER}
						selectedTool={selectedTool}
						onClick={() => {
							setSelectedTool(TOOL_TYPE.ERASER);
							enableEraser();
						}}
						icon={
							selectedTool === TOOL_TYPE.ERASER
								? ToolIcons.eraser({ color: '#376AF5' })
								: ToolIcons.eraser()
						}
					/>

					{/* SHAPES */}
					<ToolButtonWithTooltip
						title="Add Shapes"
						name={TOOL_TYPE.SHAPES}
						selectedTool={selectedTool}
						onClick={(e) => {
							handleClick(e, 'SHAPES_SELECTOR');
							setSelectedTool(TOOL_TYPE.SHAPES);
						}}
						icon={
							selectedTool === TOOL_TYPE.SHAPES
								? ToolIcons.shape_selector({ color: '#376AF5' })
								: ToolIcons.shape_selector()
						}
					/>

					{/* Sticky Note */}
					<ToolButtonWithTooltip
						title="Sticky Note"
						name={TOOL_TYPE.STICKY_NOTE}
						selectedTool={selectedTool}
						onClick={(e) => {
							handleClick(e, 'STICKY_NOTE');
							setSelectedTool(TOOL_TYPE.STICKY_NOTE);
						}}
						icon={
							selectedTool === TOOL_TYPE.STICKY_NOTE
								? ToolIcons.sticky_note({ color: '#376AF5' })
								: ToolIcons.sticky_note()
						}
					/>

					{/* LASER */}
					<ToolButtonWithTooltip
						title="Laser"
						name={TOOL_TYPE.LASER}
						selectedTool={selectedTool}
						onClick={() => {
							setSelectedTool(TOOL_TYPE.LASER);
							startLaser();
						}}
						icon={
							selectedTool === TOOL_TYPE.LASER
								? ToolIcons.laser({ color: '#376AF5' })
								: ToolIcons.laser()
						}
					/>

					{/* UNDO */}
					<ToolButtonWithTooltip
						title="Undo (Ctrl/Cmd + Z)"
						name="undo"
						selectedTool={selectedTool}
						onClick={() => {
							// setSelectedTool(TOOL_TYPE.UNDO);
							undo();
						}}
						icon={
							selectedTool === TOOL_TYPE.UNDO
								? ToolIcons.undo({ color: '#376AF5' })
								: ToolIcons.undo()
						}
					/>

					{/* ADD IMAGE */}
					<Tooltip title="Add Image" placement="right">
						<span className="tool-button">
							<input
								style={{ display: 'none' }}
								value=""
								type="file"
								name="select-images"
								id="select-images"
								accept="image/*"
								// multiple
								onChange={(e) => {
									e.preventDefault();
									addImage(e);
								}}
							/>
							<label
								style={{ cursor: 'pointer' }}
								onClick={() => {
									// setSelectedTool(TOOL_TYPE.ADD_IMAGE);
									enableSelector();
								}}
								htmlFor="select-images"
							>
								{selectedTool === TOOL_TYPE.ADD_IMAGE
									? ToolIcons.image({ color: '#376AF5' })
									: ToolIcons.image()}
							</label>
						</span>
					</Tooltip>

					{/* DOWNLOAD_SCREENSHOT */}
					<ToolButtonWithTooltip
						title="Download Screenshot (Ctrl/Cmd + K)"
						name="download-screenshot"
						selectedTool={selectedTool}
						onClick={() => {
							setSelectedTool(TOOL_TYPE.DOWNLOAD_SCREENSHOT);
							takeScreenshot();
						}}
						icon={
							selectedTool === TOOL_TYPE.DOWNLOAD_SCREENSHOT
								? ToolIcons.download_screenshot({ color: '#376AF5' })
								: ToolIcons.download_screenshot()
						}
					/>
				</div>
			</div>
			<Popover
				id={id}
				open={openSelectionBox && openSelectionBox === 'STROKE_SELECTOR'}
				anchorEl={anchorEl}
				onClose={handleCloseSelectionBox}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				sx={{ marginLeft: '20px' }}
			>
				<StrokeSelector
					strokeColor={strokeColor}
					strokeWidth={strokeWidth}
					handleUpdateStrokeWidth={handleUpdateStrokeWidth}
					handleUpdateStrokeColor={handleUpdateStrokeColor}
				/>
			</Popover>

			<Popover
				id={id}
				open={openSelectionBox && openSelectionBox === 'STICKY_NOTE'}
				anchorEl={anchorEl}
				onClose={handleCloseSelectionBox}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
			>
				<StickyNoteOptions
					noteColor={stickyNoteColor}
					handleUpdateStickyNoteColor={handleUpdateStickyNoteColor}
				/>
			</Popover>

			<Popover
				id={id}
				open={openSelectionBox && openSelectionBox === 'SHAPES_SELECTOR'}
				anchorEl={anchorEl}
				onClose={handleCloseSelectionBox}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				sx={{ marginLeft: '20px' }}
			>
				<ShapesSelector
					handleSelectShape={handleSelectShape}
					shapeColor={shapeColor}
					handleUpdateShapeColor={handleUpdateShapeColor}
				/>
			</Popover>
		</>
	);
};

export default DrawingTools;
