import styled from "styled-components";

const StyledNameInput = styled.div`
  .board-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #071032de;
    margin: 0px 10px;
  }
`;

export default StyledNameInput;
