// automatically generated by the FlatBuffers compiler, do not modify
var __createBinding =
	(this && this.__createBinding) ||
	(Object.create
		? function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				var desc = Object.getOwnPropertyDescriptor(m, k);
				if (
					!desc ||
					("get" in desc ? !m.__esModule : desc.writable || desc.configurable)
				) {
					desc = {
						enumerable: true,
						get: function () {
							return m[k];
						},
					};
				}
				Object.defineProperty(o, k2, desc);
		  }
		: function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				o[k2] = m[k];
		  });
var __setModuleDefault =
	(this && this.__setModuleDefault) ||
	(Object.create
		? function (o, v) {
				Object.defineProperty(o, "default", { enumerable: true, value: v });
		  }
		: function (o, v) {
				o["default"] = v;
		  });
var __importStar =
	(this && this.__importStar) ||
	function (mod) {
		if (mod && mod.__esModule) return mod;
		var result = {};
		if (mod != null)
			for (var k in mod)
				if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k))
					__createBinding(result, mod, k);
		__setModuleDefault(result, mod);
		return result;
	};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanvasObject = void 0;
var flatbuffers = __importStar(require("flatbuffers"));
var CanvasObject = /** @class */ (function () {
	function CanvasObject() {
		this.bb = null;
		this.bb_pos = 0;
	}
	CanvasObject.prototype.__init = function (i, bb) {
		this.bb_pos = i;
		this.bb = bb;
		return this;
	};
	CanvasObject.getRootAsCanvasObject = function (bb, obj) {
		return (obj || new CanvasObject()).__init(
			bb.readInt32(bb.position()) + bb.position(),
			bb
		);
	};
	CanvasObject.getSizePrefixedRootAsCanvasObject = function (bb, obj) {
		bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
		return (obj || new CanvasObject()).__init(
			bb.readInt32(bb.position()) + bb.position(),
			bb
		);
	};
	CanvasObject.prototype.creator = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 4);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	CanvasObject.prototype.objectId = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 6);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	CanvasObject.prototype.svg = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 8);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	CanvasObject.startCanvasObject = function (builder) {
		builder.startObject(3);
	};
	CanvasObject.addCreator = function (builder, creatorOffset) {
		builder.addFieldOffset(0, creatorOffset, 0);
	};
	CanvasObject.addObjectId = function (builder, objectIdOffset) {
		builder.addFieldOffset(1, objectIdOffset, 0);
	};
	CanvasObject.addSvg = function (builder, svgOffset) {
		builder.addFieldOffset(2, svgOffset, 0);
	};
	CanvasObject.endCanvasObject = function (builder) {
		var offset = builder.endObject();
		return offset;
	};
	CanvasObject.createCanvasObject = function (
		builder,
		creatorOffset,
		objectIdOffset,
		svgOffset
	) {
		CanvasObject.startCanvasObject(builder);
		CanvasObject.addCreator(builder, creatorOffset);
		CanvasObject.addObjectId(builder, objectIdOffset);
		CanvasObject.addSvg(builder, svgOffset);
		return CanvasObject.endCanvasObject(builder);
	};
	return CanvasObject;
})();
exports.CanvasObject = CanvasObject;
