import React from 'react';
import StyledNameInput from './style';

const BoardNameInput = ({
	boardName,
	setBoardName,
	enableInput,
	setEnableInput,
	updateBoardName,
}) => {
	const submitOnEnter = (e) => {
		if (e.keyCode === 13) {
			setEnableInput(false);
			updateBoardName(boardName);
		}
		return true;
	};
	return (
		<StyledNameInput onBlur={() => setEnableInput(false)}>
			{!enableInput ? (
				<div onClick={() => setEnableInput(true)} className="board-name">
					{boardName}
				</div>
			) : (
				<input
					onChange={(e) => {
						setBoardName(e.target.value);
					}}
					onKeyDown={(e) => submitOnEnter(e)}
					autoFocus
					placeholder="Untitled CoVas"
					value={boardName}
				/>
			)}
		</StyledNameInput>
	);
};

export default BoardNameInput;
