import { Tooltip } from "@mui/material";
import React from "react";
import ToolIcons from "../../../assets/ToolIcons";
import { useClipboard } from "./useClipBoard";
import "./style.css";

const ShareText = ({
	textToCopy = "Hi there!",
	textTitle = "Copy the following",
}) => {
	const [tooltipTitle, setTooltipTitle] = React.useState("Copy to clipboard");
	const clipboard = useClipboard({ timeout: 500 });
	return (
		<>
			<div style={{ marginTop: "20px" }}>{textTitle}</div>
			<div className="share-component-wrapper">
				<input
					disabled={true}
					type="text"
					value={textToCopy}
					className="copy-text"
				></input>
				<Tooltip title={tooltipTitle}>
					<div
						className="copyIcon"
						onClick={(e) => {
							e.stopPropagation();
							clipboard.copy(textToCopy);
							setTooltipTitle("Copied!");
						}}
					>
						{" "}
						{ToolIcons.CopyIcon({ height: "18", width: "18", color: "black" })}
					</div>
				</Tooltip>
			</div>
		</>
	);
};

export default ShareText;
