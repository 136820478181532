import styled from "styled-components";

const StyledShapesSelector = styled.div`
  padding: 20px;
  display: flex;
  min-width: 400px;
  flex-direction: column;
  gap: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .top-shapes-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .no-border-button {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }
`;

export default StyledShapesSelector;
