import { useState } from "react";

export function useClipboard({ timeout = 2000 } = {}) {
	const [error, setError] = useState(null);
	const [copied, setCopied] = useState(false);
	const [copyTimeout, setCopyTimeout] = useState(null);

	const handleCopyResult = (value) => {
		clearTimeout(copyTimeout);
		setCopyTimeout(setTimeout(() => setCopied(false), timeout));
		setCopied(value);
	};

	const copy = (valueToCopy) => {
		console.log("valueToCopy", valueToCopy);
		if ("clipboard" in navigator) {
			navigator.clipboard
				.writeText(valueToCopy)
				.then(() => handleCopyResult(true))
				.catch((err) => setError(err));
		} else {
			setError(new Error("useClipboard: navigator.clipboard is not supported"));
		}
	};

	const reset = () => {
		setCopied(false);
		setError(null);
		clearTimeout(copyTimeout);
	};

	return { copy, reset, error, copied };
}
