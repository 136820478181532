const ToolIcons = {
	laser: (options) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={(options && options.width) || '24'}
			height={(options && options.height) || '24'}
			fill="none"
		>
			<path
				stroke={(options && options.color) || '#666666'}
				strokeWidth={1.5}
				d="M15.641 12.236c-1.573 0-2.422 2.058-2.422 3.995 0 1.388-1.199 2.517-2.672 2.517-.912 0-1.483-.356-1.91-1.191l-.54-1.165c-.774-1.732-1.443-3.227-3.265-3.23C2.717 13.162 1 14.539 1 16.231c0 .135.161.244.359.244s.359-.109.359-.244c0-1.423 1.395-2.581 3.109-2.581h.001c1.292 0 1.89 1.337 2.582 2.884l.546 1.18v.001c.193.378.777 1.523 2.59 1.523 1.87 0 3.39-1.348 3.39-3.005 0-2.007.899-3.507 1.703-3.507.198 0 .359-.109.359-.244s-.161-.244-.359-.244z"
				fill={(options && options.color) || '#666666'}
			/>
			<path
				stroke={(options && options.color) || '#666666'}
				strokeWidth={1.5}
				d="M16.027 6.717a.4.4 0 0 1 .738-.198l1.495 2.377a.4.4 0 0 0 .442.173l2.631-.705a.4.4 0 0 1 .42.631l-1.719 2.23a.4.4 0 0 0-.022.457l1.527 2.427a.4.4 0 0 1-.491.583l-2.516-1.036a.4.4 0 0 0-.469.126l-1.661 2.155a.4.4 0 0 1-.716-.259l.109-2.865a.4.4 0 0 0-.248-.385l-2.604-1.072a.4.4 0 0 1 .049-.756l2.631-.705a.4.4 0 0 0 .296-.371l.106-2.806z"
				fill={(options && options.color) || '#666666'}
			/>
		</svg>
	),
	sticky_note: (options) => (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '6px',
				marginLeft: '5px',
			}}
		>
			<svg
				width="18"
				height="19.636"
				viewBox="0 0 18 19.636"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g fill={(options && options.color) || '#666666'}>
					<path d="M12.649 18.759v.023h-.025l-.647.641a.732.732 0 0 1-.246.158.817.817 0 0 1-.299.056H2.189a2.35 2.35 0 0 1-1.548-.563C.23 18.714 0 18.226 0 17.716V1.921C0 1.411.231.923.641.563A2.353 2.353 0 0 1 2.189 0H15.81a2.35 2.35 0 0 1 1.548.563c.411.36.641.848.641 1.358v11.312a.597.597 0 0 1-.185.427l-5.167 5.099ZM16.54 1.921a.604.604 0 0 0-.214-.453.785.785 0 0 0-.515-.187H2.188a.785.785 0 0 0-.515.188.603.603 0 0 0-.214.452v15.794c0 .17.077.333.214.453a.785.785 0 0 0 .515.187h8.916l.085-.083V14.3c0-.34.154-.665.427-.906a1.569 1.569 0 0 1 1.032-.375h3.863l.033-.03-.003-11.068Z" />
					<path d="M13.135 10.459H3.892a.785.785 0 0 1-.515-.188.603.603 0 0 1-.214-.452c0-.169.077-.333.214-.452a.785.785 0 0 1 .516-.188h9.243a.785.785 0 0 1 .516.188.604.604 0 0 1 .214.452.604.604 0 0 1-.213.452.785.785 0 0 1-.517.188Zm0-2.882H3.892a.785.785 0 0 1-.515-.187.603.603 0 0 1-.214-.453c0-.169.077-.332.214-.452a.785.785 0 0 1 .516-.188h9.243c.194 0 .38.068.516.188a.604.604 0 0 1 .214.452.604.604 0 0 1-.213.453.785.785 0 0 1-.517.187Z" />
				</g>
			</svg>
			<svg
				width="4"
				height="6"
				viewBox="0 0 4 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0 6L4 3L-2.62268e-07 0L0 6Z"
					fill={(options && options.color) || '#666666'}
				/>
			</svg>
		</div>
	),
	text_box: (options) => (
		<svg
			width={(options && options.width) || '24'}
			height={(options && options.height) || '18'}
			viewBox="0 0 21 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.146597 9.97404L4.12308 0.994191C4.40032 0.374967 4.90535 0 5.59004 0H5.73671C6.42097 0 6.91005 0.374896 7.187 0.994191L11.1636 9.97404C11.2451 10.1533 11.2941 10.3162 11.2941 10.4793C11.2941 11.1475 10.7726 11.6853 10.1043 11.6853C9.51766 11.6853 9.12645 11.3431 8.89836 10.8214L8.13247 9.02886H3.11286L2.31427 10.9031C2.10236 11.3919 1.67874 11.6853 1.15717 11.6853C0.505039 11.6853 0 11.1638 0 10.5118C0 10.3326 0.0651068 10.1533 0.146597 9.97404ZM7.20346 6.81239L5.62252 3.04768L4.04173 6.81239H7.20346Z"
				fill={(options && options.color) || '#666666'}
			/>
			<path
				d="M12.4805 9.07729V9.04466C12.4805 7.13791 13.931 6.25783 16.0007 6.25783C16.8809 6.25783 17.5165 6.4045 18.1358 6.61642V6.46975C18.1358 5.44293 17.5001 4.87265 16.2616 4.87265C15.5771 4.87265 15.023 4.97038 14.5503 5.11712C14.4037 5.16598 14.3059 5.18222 14.1916 5.18222C13.6214 5.18222 13.1651 4.74222 13.1651 4.17186C13.1651 3.73179 13.442 3.35696 13.8331 3.21022C14.6154 2.91689 15.463 2.75391 16.6201 2.75391C17.9726 2.75391 18.9506 3.11242 19.5699 3.73171C20.2218 4.38356 20.5151 5.3452 20.5151 6.51861V10.4951C20.5151 11.1634 19.9773 11.6848 19.3091 11.6848C18.592 11.6848 18.1194 11.1796 18.1194 10.6581V10.6418C17.5165 11.3099 16.6851 11.7499 15.4793 11.7499C13.8331 11.7501 12.4805 10.805 12.4805 9.07729ZM18.1682 8.50686V8.06685C17.7445 7.87132 17.1904 7.74089 16.5874 7.74089C15.528 7.74089 14.8762 8.16466 14.8762 8.94686V8.97949C14.8762 9.64772 15.4303 10.0388 16.2289 10.0388C17.386 10.0389 18.1682 9.40332 18.1682 8.50686Z"
				fill={(options && options.color) || '#666666'}
			/>
		</svg>
	),
	CopyIcon: (options) => (
		<svg
			width={(options && options.width) || '18'}
			height={(options && options.height) || '18'}
			viewBox="0 0 13 16"
			fill={(options && options.color) || '#000'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.66732 0.666992H1.66732C0.933984 0.666992 0.333984 1.26699 0.333984 2.00033V11.3337H1.66732V2.00033H9.66732V0.666992ZM9.00065 3.33366L13.0007 7.33366V14.0003C13.0007 14.7337 12.4007 15.3337 11.6673 15.3337H4.32732C3.59398 15.3337 3.00065 14.7337 3.00065 14.0003L3.00732 4.66699C3.00732 3.93366 3.60065 3.33366 4.33398 3.33366H9.00065ZM8.33398 8.00033H12.0007L8.33398 4.33366V8.00033Z"
				fill="#376AF5"
			/>
		</svg>
	),
	CrossIcon: (option) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={option.width || '12'}
			height={option.height || '12'}
			viewBox="0 0 14 14"
			fill="none"
		>
			<path
				d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
				fill={option.color || '#817F7F'}
			/>
		</svg>
	),

	stroke_props: (options) => (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '5px',
				marginLeft: '5px',
			}}
		>
			<svg
				width={(options && options.width) || '23'}
				height={(options && options.height) || '23'}
				viewBox="0 0 23 23"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx="11.0072"
					cy="11.7565"
					r="10.0941"
					transform="rotate(90 11.0072 11.7565)"
					stroke={(options && options.color) || '#666666'}
					strokeWidth="1.82482"
				/>
				<circle
					r="5.00294"
					transform="matrix(-4.37114e-08 1 1 4.37114e-08 11.0088 11.7568)"
					fill={(options && options.color) || '#666666'}
				/>
			</svg>
			<svg
				width="4"
				height="6"
				viewBox="0 0 4 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0 6L4 3L-2.62268e-07 0L0 6Z"
					fill={(options && options.color) || '#666666'}
				/>
			</svg>
		</div>
	),

	opacity_selector: (options) => (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '5px',
				marginLeft: '5px',
			}}
		>
			<svg
				width={(options && options.width) || '21'}
				height={(options && options.height) || '25'}
				viewBox="0 0 17 21"
				fill={(options && options.color) || '#666666'}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M13.668 6.42676L8.00471 0.773437L2.34138 6.42676C0.780459 7.98768 0 10.0689 0 12.0701C0 14.0713 0.780459 16.1825 2.34138 17.7434C3.90229 19.3043 5.9535 20.0948 8.00471 20.0948C10.0559 20.0948 12.1071 19.3043 13.668 17.7434C15.229 16.1825 16.0094 14.0713 16.0094 12.0701C16.0094 10.0689 15.229 7.98768 13.668 6.42676ZM2.00118 12.4303C2.01118 10.4291 2.62154 9.15837 3.76221 8.0277L8.00471 3.69516L12.2472 8.07773C13.3879 9.19839 13.9982 10.4291 14.0082 12.4303L2.00118 12.4303Z"
					fill={(options && options.color) || '#666666'}
				/>
			</svg>

			<svg
				width="4"
				height="6"
				viewBox="0 0 4 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0 6L4 3L-2.62268e-07 0L0 6Z"
					fill={(options && options.color) || '#666666'}
				/>
			</svg>
		</div>
	),

	eraser: (options) => (
		<svg
			width={(options && options.width) || '21'}
			height={(options && options.height) || '28'}
			viewBox="0 0 19 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.367 0.753746L18.335 5.72175C18.544 5.93068 18.7097 6.17872 18.8228 6.4517C18.9358 6.72469 18.994 7.01727 18.994 7.31275C18.994 7.60822 18.9358 7.90081 18.8228 8.17379C18.7097 8.44677 18.544 8.69481 18.335 8.90375L9.65402 17.5837L15.751 17.5847C15.9324 17.5845 16.1078 17.65 16.2446 17.7692C16.3814 17.8883 16.4703 18.053 16.495 18.2327L16.501 18.3347C16.501 18.516 16.4354 18.6911 16.3163 18.8277C16.1971 18.9643 16.0326 19.0531 15.853 19.0777L15.751 19.0847L7.34102 19.0857C7.0253 19.1035 6.7094 19.0543 6.41401 18.9414C6.11863 18.8286 5.85044 18.6545 5.62702 18.4307L0.659019 13.4617C0.450085 13.2528 0.284349 13.0048 0.171274 12.7318C0.0581989 12.4588 0 12.1662 0 11.8707C0 11.5753 0.0581989 11.2827 0.171274 11.0097C0.284349 10.7367 0.450085 10.4887 0.659019 10.2797L10.185 0.753746C10.394 0.544811 10.642 0.379075 10.915 0.266C11.188 0.152925 11.4805 0.0947266 11.776 0.0947266C12.0715 0.0947266 12.3641 0.152925 12.6371 0.266C12.91 0.379075 13.1581 0.544811 13.367 0.753746ZM9.19502 15.8437L3.24002 9.88775L1.73402 11.3547C1.59357 11.4954 1.51468 11.686 1.51468 11.8847C1.51468 12.0835 1.59357 12.2741 1.73402 12.4147L6.67902 17.3607C6.82175 17.4991 7.01357 17.5751 7.21233 17.5721C7.41109 17.5691 7.60053 17.4873 7.73902 17.3447L9.19502 15.8427V15.8437Z"
				fill={(options && options.color) || '#666666'}
			/>
		</svg>
	),

	shape_selector: (options) => (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '6px',
				marginLeft: '5px',
			}}
		>
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g
					fill="#fff"
					stroke={(options && options.color) || '#666666'}
					strokeWidth="1.82"
				>
					<path d="M12.346 6.857c0 3.409 -2.63 6.112 -5.801 6.112S0.745 10.267 0.745 6.858C0.745 3.448 3.374 0.745 6.545 0.745s5.801 2.703 5.801 6.113Z" />
					<path
						x="7.07"
						y="7.583"
						width="14.02"
						height="13.507"
						rx="3.09"
						d="M8.313 6.204H14.727A2.528 2.528 0 0 1 17.255 8.732V14.727A2.528 2.528 0 0 1 14.727 17.255H8.313A2.528 2.528 0 0 1 5.785 14.727V8.732A2.528 2.528 0 0 1 8.313 6.204z"
					/>
				</g>
			</svg>
			<svg
				width="4"
				height="6"
				viewBox="0 0 4 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0 6L4 3L-2.62268e-07 0L0 6Z"
					fill={(options && options.color) || '#666666'}
				/>
			</svg>
		</div>
	),

	undo: (options) => (
		<svg
			width={(options && options.width) || '25'}
			height={(options && options.height) || '22'}
			viewBox="0 0 23 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.0071 0.997626C8.01419 0.997626 3.95994 5.02162 3.91097 10.0029H1H0.779725L0.935483 10.1587L4.93784 14.161L5.00235 14.2255L5.06687 14.161L9.06922 10.1587L9.22498 10.0029H9.00471H6.09477C6.1435 6.2232 9.21563 3.18128 13.0071 3.18128C16.8289 3.18128 19.9199 6.27227 19.9199 10.0942C19.9199 13.916 16.8289 17.007 13.0071 17.007C11.5162 17.007 10.1336 16.5233 8.99721 15.7229L8.934 15.6784L8.8797 15.7334L7.45887 17.1743L7.38614 17.248L7.46848 17.3109C9.00445 18.4831 10.9248 19.1907 13.0071 19.1907C18.0304 19.1907 22.1036 15.1175 22.1036 10.0942C22.1036 5.07085 18.0304 0.997626 13.0071 0.997626Z"
				fill={(options && options.color) || '#666666'}
				stroke={(options && options.color) || '#666666'}
				strokeWidth="0.182482"
			/>
		</svg>
	),

	pointer: (options) => (
		<svg
			width="16"
			height="20.211"
			viewBox="0 0 16 20.211"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.381 16.602 13.785 0.945a0.876 0.876 0 0 1 0.611 -0.335c0.403 -0.045 0.736 0.184 0.738 0.506l0.211 17.926 -0.004 0.001a0.589 0.589 0 0 1 -0.208 0.44c-0.284 0.259 -0.747 0.305 -1.033 0.109l-5.486 -3.806 -7.542 1.677a0.84 0.84 0 0 1 -0.548 -0.056c-0.32 -0.163 -0.386 -0.522 -0.143 -0.803Z"
				fill={(options && options.color) || '#666666'}
			/>
		</svg>
	),

	image: (options) => (
		<svg
			width={(options && options.width) || '22'}
			height={(options && options.height) || '19'}
			viewBox="0 0 20 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.7301 0.982422H1.26986C0.56859 0.982422 0 1.55098 0 2.25225V15.2681C0 15.9694 0.56859 16.5379 1.26986 16.5379H18.7301C19.4314 16.5379 20 15.9694 20 15.2681V2.25225C20 1.55098 19.4314 0.982422 18.7301 0.982422ZM18.7301 2.25225V11.4874L16.2276 9.21222C15.8514 8.87032 15.273 8.88397 14.9136 9.24366L12.3809 11.776L7.38699 5.81095C7.0095 5.36015 6.31807 5.35573 5.93492 5.80111L1.26986 11.2227V2.25225H18.7301ZM13.3333 5.58557C13.3333 4.62112 14.1149 3.83953 15.0793 3.83953C16.0438 3.83953 16.8254 4.62112 16.8254 5.58557C16.8254 6.55002 16.0438 7.33157 15.0793 7.33157C14.1149 7.33161 13.3333 6.55002 13.3333 5.58557Z"
				fill={(options && options.color) || '#666666'}
			/>
		</svg>
	),

	download_screenshot: (options) => (
		<svg
			width={(options && options.width) || '20'}
			height={(options && options.height) || '20'}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.3412 7.50441V13.3412H1.66765V7.50441H0V13.3412C0 14.2584 0.750441 15.0088 1.66765 15.0088H13.3412C14.2584 15.0088 15.0088 14.2584 15.0088 13.3412V7.50441H13.3412ZM8.33823 8.06307L10.4978 5.91181L11.6735 7.0875L7.50441 11.2566L3.33529 7.0875L4.51098 5.91181L6.67059 8.06307V0H8.33823V8.06307Z"
				fill={(options && options.color) || '#666666'}
			/>
		</svg>
	),
};

export default ToolIcons;
