import { decodeByteArray } from '../flatbuffers/flatbuffer_helpers/fb_decoder';
import { API_ENDPOINTS, MQTT_MESSAGE_TYPE } from '../Mqtt/constants';
import LocalDb from '../utils/localStorage';
import TokenHandler from '../utils/token';
import { artist, remoteArtist } from './Board';

export const renderPreviousEvents = (initialCanvas, successCb) => {
	let decodedEvents = [];

	// decode all the byte code to event objects
	for (const drawingEvent of initialCanvas) {
		try {
			/* This is to convert the base64 string to a byte array. */
			const byteStream = Uint8Array.from(window.atob(drawingEvent), (c) =>
				c.charCodeAt(0)
			);
			const messagePayload = decodeByteArray(byteStream);
			if (
				messagePayload.messageType.toString() !==
				MQTT_MESSAGE_TYPE.NEW_PARTICIPANT.toString()
			) {
				decodedEvents.push(messagePayload);
			}
		} catch (error) {
			console.log('error while decoding', error);
		}
	}

	// handle all the Canvas added events first
	decodedEvents.forEach((event) => {
		if (event.messageType === MQTT_MESSAGE_TYPE.NEW_CANVAS_CREATED) {
			remoteArtist.newRemoteAction(event);
		}
	});

	// filter out the Canvas added events
	decodedEvents = decodedEvents.filter(
		(event) => event.messageType !== MQTT_MESSAGE_TYPE.NEW_CANVAS_CREATED
	);

	// handle all the object added events
	decodedEvents.forEach((event) => {
		if (event.messageType === MQTT_MESSAGE_TYPE.NEW_OBJECT_ADDED) {
			remoteArtist.newRemoteAction(event);
		}
	});

	// filter out the object added events
	decodedEvents = decodedEvents.filter(
		(event) => event.messageType !== MQTT_MESSAGE_TYPE.NEW_OBJECT_ADDED
	);

	// handle all the object modified events
	setTimeout(() => {
		decodedEvents.forEach((event) => {
			if (event.messageType === MQTT_MESSAGE_TYPE.JOIN_CANVAS_REQUEST) {
				artist.sendCanvasData();
			} else {
				remoteArtist.newRemoteAction(event);
			}
		});

		successCb();
	}, 1000);
};

export const handleNewDrawingEvents = (message, onNewParticipantArrival) => {
	try {
		const messagePayload = message;
		if (
			messagePayload.messageType.toString() ===
			MQTT_MESSAGE_TYPE.NEW_PARTICIPANT.toString()
		) {
			onNewParticipantArrival(messagePayload);
		} else {
			if (messagePayload.messageType === 'REMOVE_OBJECT') {
				// artist.removeObject(messagePayload);
				remoteArtist.newRemoteAction(messagePayload);
			}
			if (messagePayload.senderAccountId !== LocalDb.getUserAccountId()) {
				if (messagePayload.messageType === MQTT_MESSAGE_TYPE.JOIN_CANVAS_REQUEST) {
					artist.sendCanvasData();
				} else {
					remoteArtist.newRemoteAction(messagePayload);
				}
			}
		}
	} catch (error) {
		console.log('Error while decoding', error);
	}
};

export const fetchInitialCanvasData = async (canvasRoomId, context = null) => {
	let data = await fetch(
		`${API_ENDPOINTS.GET_WHITEBOARD}/${canvasRoomId}${
			context ? `?context=${context}` : ''
		}`,
		{
			method: 'GET',
			headers: TokenHandler.authHeadersTypeJSON(),
		}
	);
	const json = data.json();
	return json;
};

export const handleUpdateBoardName = async (boardName, canvasId) => {
	const raw = JSON.stringify({
		whiteboardId: canvasId,
		name: boardName,
	});
	let data = await fetch(`${API_ENDPOINTS.GET_WHITEBOARD}/${canvasId}`, {
		method: 'PUT',
		headers: TokenHandler.authHeadersTypeJSON(),
		body: raw,
		redirect: 'follow',
	});
	const json = data.json();
	return json;
};
