import React from "react";
import styled from "styled-components";
import LoaderGif from "../../../assets/loader.gif";

const StyledLoader = styled.div`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
`;

const Loader = ({ width = "30px", height = "30px" }) => {
	return (
		<StyledLoader className="loader" width={width} height={height}>
			<img
				src={LoaderGif}
				style={{
					height: height || "30px",
					width: width || "30px",
				}}
				alt="canvas loader"
			/>
		</StyledLoader>
	);
};

export default Loader;
