import React from "react";
import StyledAvatarGroup from "./style";
import Avatar from "@mui/material/Avatar";
import { CallAvatarIcon } from "../../CallAvatarIcon/CallAvatarIcon";

const ParticipantsAvatarGroup = ({ canvasActiveUsersList }) => {
	if (canvasActiveUsersList !== undefined) {
		return (
			<StyledAvatarGroup>
				{canvasActiveUsersList.length > 0 &&
					canvasActiveUsersList.map((user) => (
						<div key={user.account.accountid}>
							{user.account.profilepic ? (
								<Avatar
									key={user.account.accountid}
									alt={user.account.fullname}
									src={user.account.profilepic}
									style={{
										width: "30px",
										height: "30px",
										border: "1px solid lightgray",
									}}
								/>
							) : (
								<CallAvatarIcon
									uniquekey={user.account.accountid}
									subject={
										(user.account.fullname && user.account.fullname) || "U"
									}
									width={"30px"}
								/>
							)}
						</div>
					))}
			</StyledAvatarGroup>
		);
	} else {
		return null;
	}
};

export default ParticipantsAvatarGroup;
