/* eslint-disable no-unused-vars */
import LocalDb from '../../../utils/localStorage';
import { CreateUUIDWithoutDash } from '../../utils/helper';
const fabric = window.fabric;
const localAccountId = LocalDb.getUserAccountId();

export const createArrow = (canvas, successCb, shape = 'arrow') => {
	let id = `${CreateUUIDWithoutDash()}-ARROW`;
	canvas.isDrawingMode = false;
	canvas.hoverCursor = 'crosshair';
	let selectedShape;
	let pointer, startX, startY, origX, origY;
	let rect, ellipse, line, triangle, arrow;
	let stroke = 'black';
	let fill = 'transparent';
	/* previous shape will not be drawn (fix) */
	canvas.off('mouse:down');
	canvas.off('mouse:up');

	canvas.on('mouse:down', function (option) {
		if (option.target != null) {
			return;
		} else {
			switch (shape) {
				case 'arrow':
					pointer = canvas.getPointer(option.e);
					let arrowLinePoints = [pointer.x, pointer.y, pointer.x, pointer.y];

					startX = pointer.x;
					startY = pointer.y;

					line = new fabric.Line(arrowLinePoints, {
						fill: fill,
						stroke: stroke,
						strokeWidth: 0.3,
					});

					// reference points for arrowhead
					origX = line.x2;
					origY = line.y2;

					let dx = line.x2 - line.x1,
						dy = line.y2 - line.y1;

					/* calculate angle of arrow */
					let angle = Math.atan2(dy, dx);
					angle *= 180 / Math.PI;
					angle += 90;

					arrow = new fabric.Triangle({
						angle: angle,
						fill: 'black',
						top: line.y2,
						left: line.x2,
						width: 3,
						height: 3,
						originX: 'center',
						originY: 'center',
						stroke: stroke,
					});

					break;
				default:
					break;
			}

			canvas.add(line);
			canvas.add(arrow);
			canvas.renderAll();

			canvas.on('mouse:move', function (option) {
				switch (shape) {
					case 'arrow':
						pointer = canvas.getPointer(option.e);

						line.set({
							x2: pointer.x,
							y2: pointer.y,
						});

						let dx = line.x2 - line.x1,
							dy = line.y2 - line.y1;

						let angle = Math.atan2(dy, dx);
						angle *= 180 / Math.PI;
						angle += 90;

						arrow.set({
							top: line.y2,
							left: line.x2,
							angle: angle,
							width: 3,
							height: 3,
						});

						canvas.renderAll();
						break;
					default:
						break;
				}
			});
		}
	});

	canvas.on('mouse:up', function (option) {
		canvas.off('mouse:move');
		canvas.off('mouse:down');
		canvas.off('mouse:up');
		canvas?.discardActiveObject();
		canvas.remove(line);
		canvas.remove(arrow);
		selectedShape = new fabric.Group([line, arrow], {
			hasBorders: true,
			hasControls: true,
		});
		selectedShape.id = id;
		canvas.add(selectedShape);
		const svg = selectedShape.toSVG();

		const object = {
			id,
			creator: localAccountId,
			svg,
		};
		canvas.hoverCursor = 'move';
		successCb && successCb(object);
	});
};
