import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import BoardName from '../../elements/BoardName';
import CanvasPagination from '../../elements/CanvasPagination';
import ShareButton from '../../elements/ShareButton';
import BackgroundSelector from '../BackgroundSelector';
import './style.css';
// import header_icons from "../../../assets/header_icons";
import Loader from '../../elements/Loader/Loader';
import ParticipantsAvatarGroup from '../ParticipantsAvatarGroup';

const Header = ({
	clearCanvas,
	selectBackground,
	selectedBackground,
	nextCanvas,
	prevCanvas,
	currentCanvas,
	canvasList,
	boardName,
	setBoardName,
	updateBoardName,
	canvasActiveUsersList,
	loadingPreviousData,
	saving,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [openPopover, setOpenPopover] = useState(false);
	// const handleClick = (event, popoverType) => {
	// 	setAnchorEl(event.currentTarget);
	// 	setOpenPopover(popoverType);
	// };

	const handleClosePopover = () => {
		setOpenPopover(false);
		setAnchorEl(null);
	};

	const handleSelectBackground = (background) => {
		handleClosePopover();
		selectBackground(background);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	return (
		<>
			<header className="header prime-header">
				{/* LEFT CONTAINER */}
				<div className="left-header-container">
					<div className="center whiteboard-name-container">
						<BoardName
							updateBoardName={updateBoardName}
							boardName={boardName}
							setBoardName={setBoardName}
						/>
					</div>
				</div>

				{/* CENTER CONTAINER */}
				<div className="center-header-container">
					<CanvasPagination
						prevCanvas={prevCanvas}
						nextCanvas={nextCanvas}
						canvasList={canvasList}
						currentCanvas={currentCanvas}
					/>
				</div>

				{/* RIGHT CONTAINER */}
				<div className="right-header-container">
					<div className="center avatar-group-wrapper">
						<ParticipantsAvatarGroup canvasActiveUsersList={canvasActiveUsersList} />
					</div>

					<div className="center save-button-wrapper">
						<ShareButton />
					</div>
				</div>
			</header>
			<div className="header secondary-header">
				<div className="left-header-container">
					<div className="center clear-button-wrapper">
						<Tooltip title="Clear Board" placement="bottom">
							<button
								type="button"
								className="clear-canvas-button"
								onClick={() => {
									clearCanvas();
								}}
							>
								Clear
							</button>
						</Tooltip>
					</div>
					{/* BACKGROUND SELECTION */}
					{/* <div className="center set-background-container">
						<Tooltip title="Select Background" placement="bottom">
							<button
								onClick={(e) => {
									handleClick(e, 'BACKGROUND_SELECTOR');
								}}
								type="button"
								className="clear-canvas-button"
							>
								Set Background
							</button>
						</Tooltip>
					</div> */}
					{loadingPreviousData && (
						<div
							className="center set-background-container"
							style={{ fontWeight: 400, fontSize: '14px' }}
						>
							<div style={{ marginRight: ' 10px ' }}>Loading</div>{' '}
							<Loader width="15px" height="15px" />
						</div>
					)}
					{!loadingPreviousData && saving && (
						<div
							className="center set-background-container"
							style={{ fontWeight: 400, fontSize: '14px' }}
						>
							<div style={{ marginRight: ' 10px ' }}>Saving</div>{' '}
							<Loader width="15px" height="15px" />
						</div>
					)}

					{!loadingPreviousData && !saving && (
						<div
							className="center set-background-container"
							style={{ fontWeight: 400, fontSize: '14px' }}
						>
							<span style={{ opacity: '0.7' }}>Saved</span>{' '}
						</div>
					)}
				</div>
			</div>

			<Popover
				id={id}
				open={openPopover && openPopover === 'BACKGROUND_SELECTOR'}
				anchorEl={anchorEl}
				onClose={handleClosePopover}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				sx={{
					marginTop: '25px',
					marginRight: '40px',
					boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
					borderRadius: '2px',
				}}
			>
				<BackgroundSelector
					selectedBackground={selectedBackground}
					selectBackground={handleSelectBackground}
				/>
			</Popover>
		</>
	);
};

export default Header;
