// automatically generated by the FlatBuffers compiler, do not modify
Object.defineProperty(exports, "__esModule", { value: true });
exports.Whiteboard_Data =
	exports.CanvasObject =
	exports.Canvas =
	exports.Account =
		void 0;
var account_js_1 = require("./whiteboard-data/account.js");
Object.defineProperty(exports, "Account", {
	enumerable: true,
	get: function () {
		return account_js_1.Account;
	},
});
var canvas_js_1 = require("./whiteboard-data/canvas.js");
Object.defineProperty(exports, "Canvas", {
	enumerable: true,
	get: function () {
		return canvas_js_1.Canvas;
	},
});
var canvas_object_js_1 = require("./whiteboard-data/canvas-object.js");
Object.defineProperty(exports, "CanvasObject", {
	enumerable: true,
	get: function () {
		return canvas_object_js_1.CanvasObject;
	},
});
var whiteboard_data_js_1 = require("./whiteboard-data/whiteboard-data.js");
Object.defineProperty(exports, "Whiteboard_Data", {
	enumerable: true,
	get: function () {
		return whiteboard_data_js_1.Whiteboard_Data;
	},
});
