const getEnv = () => {
	let location = window.location;
	if (
		location.origin.includes('localhost') ||
		location.origin.includes('127.0.0.1')
	) {
		return 'test';
	} else if (location.origin.includes('anydone.com')) {
		return 'production';
	} else {
		return 'development';
	}
};
const env = getEnv();

const ENV_ENDPOINTS = {
	baseApi: env === 'production'	? 'https://api.anydone.com/'	: 'https://apigateway.anydone.net/',
	subDomain: env === 'production' ? '.anydone.com' : '.anydone.net',
	appUrl : env === 'production' ? 'https://app.anydone.com' : env === 'development' ? 'https://inbox.anydone.net' : 'http://localhost:3000',
	whiteboardUrl : env === 'production' ? 'https://covas.anydone.com' : env === 'development' ? 'https://covas.anydone.net' : 'http://localhost:5050',
	mqttUrl : env === 'production' ? 'wss://rt-edge-a.anydone.com/mqtt' : env === 'development' ? 'wss://ws.anydone.net/mqtt' : 'wss://ws.anydone.net/mqtt',
};

const main = {
	subDomain: ENV_ENDPOINTS.subDomain,
	projectEnv: process.env.PROJECT_ENV || 'production',
	endpoints: {},
	clientURL:ENV_ENDPOINTS.appUrl,
	whiteboardURL:ENV_ENDPOINTS.whiteboardUrl,
	mqttURL: ENV_ENDPOINTS.mqttUrl,
	baseApi: ENV_ENDPOINTS.baseApi
};

const dev = {
	subDomain: '.anydone.net',
	projectEnv: 'development',
	endpoints: {},
	clientURL:'http://localhost:3000',
	whiteboardURL:'http://localhost:5050',
	mqttURL: 'wss://ws.anydone.net/mqtt',
	baseApi: 'https://apigateway.anydone.net/'
};

const prod = {
	subDomain: '.anydone.com',
	projectEnv: 'production',
	endpoints: {},
	clientURL:'https://app.anydone.com',
	whiteboardURL:'https://covas.anydone.com',
	mqttURL: 'wss://rt-edge-a.anydone.com/mqtt',
	baseApi: 'https://api.anydone.com/'
};

const localProd = {
	subDomain: '.anydone.com',
	projectEnv: 'production',
	endpoints: {},
	clientURL:'http://localhost:3000',
	whiteboardURL:'http://localhost:5050',
	mqttURL: 'wss://rt-edge-a.anydone.com/mqtt',
	baseApi: 'https://api.anydone.com/'
};

const config = main;
console.log("🚀 ~ config:", {config})
export default config ;
