import React from "react";
import { CirclePicker } from "react-color";
import Slider from "@mui/material/Slider";
import StyledStrokeSelector from "./style";

const StrokeSelector = ({
	strokeColor,
	strokeWidth,
	handleUpdateStrokeColor,
	handleUpdateStrokeWidth,
}) => {
	return (
		<StyledStrokeSelector strokeWidth={strokeWidth} selectedColor={strokeColor}>
			<div className="top-slider-container">
				<Slider
					aria-label="stroke-length"
					value={strokeWidth}
					onChange={handleUpdateStrokeWidth}
					min={0.1}
					max={30}
				/>
				<div className="selected-color-circle" />
			</div>
			<div className="bottom-color-picker-container">
				<CirclePicker
					width="100%"
					color={strokeColor}
					colors={[
						"#000000",
						"#0F9D58",
						"#1EA4E9",
						"#DE4132",
						"#FFBB24",
						"#3A5090",
						"#00FC81",
						" #6BA8C8",
						"#FFE600",
					]}
					onChange={(color, event) => handleUpdateStrokeColor(color, event)}
				/>
			</div>
		</StyledStrokeSelector>
	);
};

export default StrokeSelector;
