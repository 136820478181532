import styled from "styled-components";

const StyledBackgroundSelector = styled.div`
  padding: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 400px;

  .image-box {
    min-width: 50px;
    min-height: 50px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
  }

  .black-image-box {
    background: #000;
  }

  .image-box svg {
    width: 100%;
    height: 100%;
  }
`;

export default StyledBackgroundSelector;
