import styled from "styled-components";

const StyledShareButton = styled.div`
	.styled-shr {
		min-height: 24px;
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 7px 15px;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		color: white;
		font-weight: 400;
		font-size: 14px;
		background: "#376af5";
	}
`;

export default StyledShareButton;
