import * as flatbuffers from "flatbuffers";
import { MQTT_MESSAGE_TYPE } from "../../Mqtt/constants";
import { CreateUUIDWithoutDash } from "../../utils/helper";
import LocalDb from "../../utils/localStorage";
import {
	Account,
	Canvas,
	CanvasObject,
	Whiteboard_Data,
} from "../whiteboard_fb_generated";

/**  	
	It takes in a senderAccountId and a fullName, 
	and then it can build a whiteboard message 
	*/
class FlatBufferBuilder {
	constructor({ senderAccountId, fullName }) {
		this.builder = new flatbuffers.Builder(1024);
		this.senderAccountId = this.getString(senderAccountId);
		this.fullname = this.getString(fullName);
	}

	/**
	 * It simply returns a flatbuffer string
	 * @param string - The string to create a node for.
	 * @returns A string
	 */
	getString(string) {
		return this.builder.createString(string);
	}

	/**
	 * It takes in a function that adds data to the whiteboard, and then builds the whiteboard data into a
	 * byte array
	 * @param adder - a function that takes in the builder and adds the data to the builder
	 * @returns the bytedata of the whiteboard data.
	 */
	buildWhiteBoard(adder) {
		let messageUuid = CreateUUIDWithoutDash();
		let messageIdWb = this.getString(messageUuid);

		// takes in adder functions and creates a whiteboard offset
		Whiteboard_Data.startWhiteboard_Data(this.builder);
		Whiteboard_Data.addSenderAccountId(this.builder, this.senderAccountId);
		Whiteboard_Data.addFullName(this.builder, this.fullname);
		Whiteboard_Data.addMessageId(this.builder, messageIdWb);
		adder();
		var wbdata_offset = Whiteboard_Data.endWhiteboard_Data(this.builder);
		this.builder.finish(wbdata_offset);

		// build the data into bytedata
		var wbdata_buf = this.builder.asUint8Array();
		return wbdata_buf;
	}

	/**
	 * It creates a string for the message type and context, gets the account object offset, and then
	 * builds the whiteboard data for publishing own data
	 * @returns The return value is a buffer.
	 */
	getPublishOwnSessionRequestBuffer() {
		// creating strings
		const messagetype_str = this.getString(MQTT_MESSAGE_TYPE.NEW_PARTICIPANT);
		const context_str = this.getString("MEET");

		// getting the account object offset
		let acccountObjectOffset = this.getAccountDetailsOffset();

		/* Calling the buildWhiteBoard function and passing in a function that adds the message type,
		context, and account to the builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
			Whiteboard_Data.addContext(this.builder, context_str);
			Whiteboard_Data.addAccount(this.builder, acccountObjectOffset);
		});
	}

	getLoadCanvasDataBuffer(svgCanvas) {
		//creating strings
		const messagetype_str = this.getString(MQTT_MESSAGE_TYPE.LOAD_CANVAS_DATA);

		Canvas.startCanvas(this.builder);
		Canvas.addCanvas(this.builder, svgCanvas);
		let canvasOffset = Canvas.endCanvas(this.builder);

		/* Calling the buildWhiteBoard function and passing in a function that adds the message type,
				context, and account to the builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
			Whiteboard_Data.addNewObject(this.builder, canvasOffset);
		});
	}

	getJoinCanvasRequestBuffer() {
		//creating strings
		const messagetype_str = this.getString(
			MQTT_MESSAGE_TYPE.JOIN_CANVAS_REQUEST
		);
		/* Calling the buildWhiteBoard function and passing in a function that adds the message type to the
		builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
		});
	}

	/**
	 * It creates a buffer that contains the message type, action, object id, target, and canvas id
	 * @param action - The action that was performed on the object.
	 * @param objectId - The id of the object that was modified.
	 * @param target - The target of the action. This is the user that the action is being sent to.
	 * @param canvasid - The id of the canvas that the object is on.
	 * @returns the result of the buildWhiteBoard function.
	 */
	getObjectModifiedBuffer(action, objectId, target, canvasid) {
		//creating strings
		const messagetype_str = this.getString(MQTT_MESSAGE_TYPE.OBJECT_MODIFIED);
		const action_str = this.getString(action);
		const object_id_str = this.getString(objectId);
		const target_str = this.getString(target);
		const canvasid_str = this.getString(canvasid);
		/* Calling the buildWhiteBoard function and passing in a function that adds the message type to the
		builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
			Whiteboard_Data.addAction(this.builder, action_str);
			Whiteboard_Data.addObjectId(this.builder, object_id_str);
			Whiteboard_Data.addCanvasId(this.builder, canvasid_str);
			Whiteboard_Data.addTarget(this.builder, target_str);
		});
	}

	getNewCanvasCreatedBuffer(canvasData) {
		//creating strings
		const messagetype_str = this.getString(
			MQTT_MESSAGE_TYPE.NEW_CANVAS_CREATED
		);
		const canvassvg_str = this.getString(canvasData.canvas);
		const canvasid_str = this.getString(canvasData.id);

		Canvas.startCanvas(this.builder);
		Canvas.addId(this.builder, canvasid_str);
		Canvas.addCanvas(this.builder, canvassvg_str);
		let canvasOffset = Canvas.endCanvas(this.builder);

		/* Calling the buildWhiteBoard function and passing in a function that adds the message type,
			context, and account to the builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
			Whiteboard_Data.addNewCanvas(this.builder, canvasOffset);
		});
	}

	getUpdateBoardName(boardName) {
		//create strings
		const messagetype_str = this.getString(MQTT_MESSAGE_TYPE.UPDATE_BOARD_NAME);
		const boardname_str = this.getString(boardName);

		/* Calling the buildWhiteBoard function and passing in a function that adds the message type,
					context, and account to the builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
			Whiteboard_Data.addBoardName(this.builder, boardname_str);
		});
	}

	getChangeBackgroundBuffer(background, canvasId) {
		//create strings
		const messagetype_str = this.getString(MQTT_MESSAGE_TYPE.CHANGE_BACKGROUND);
		const background_str = this.getString(background);
		const canvasid_str = this.getString(canvasId);

		/* Calling the buildWhiteBoard function and passing in a function that adds the message type,
					context, and account to the builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
			Whiteboard_Data.addBackground(this.builder, background_str);
			Whiteboard_Data.addCanvasId(this.builder, canvasid_str);
		});
	}

	/**
	 * This function creates a buffer that contains the message type, the object id, and the svg of the
	 * object that was added
	 * @returns the buffer that is created by the buildWhiteBoard function.
	 */
	getNewObjectAddedBuffer({ objectId, svg }, canvasid) {
		//creating strings
		const messagetype_str = this.getString(MQTT_MESSAGE_TYPE.NEW_OBJECT_ADDED);
		const objectId_str = this.getString(objectId);
		const svg_str = this.getString(svg);
		const canvasid_str = this.getString(canvasid);

		CanvasObject.startCanvasObject(this.builder);
		CanvasObject.addCreator(this.builder, this.senderAccountId);
		CanvasObject.addObjectId(this.builder, objectId_str);
		CanvasObject.addSvg(this.builder, svg_str);
		let canvasObjOffset = CanvasObject.endCanvasObject(this.builder);

		/* Calling the buildWhiteBoard function and passing in a function that adds the message type,
									context, and account to the builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
			Whiteboard_Data.addNewObject(this.builder, canvasObjOffset);
			Whiteboard_Data.addCanvasId(this.builder, canvasid_str);
		});
	}

	/**
	 * It creates a string for the message type, object id, and canvas id, then calls the buildWhiteBoard
	 * function and passes in a function that adds the message type, object id, and canvas id to the
	 * builder
	 * @param objectid - The id of the object to be removed.
	 * @param canvasid - The id of the canvas that the object is on.
	 * @returns The return value is a buffer that contains the message type, object id, and canvas id.
	 */
	getRemoveObjectBuffer(objectid, canvasid) {
		//create strings
		const messagetype_str = this.getString(MQTT_MESSAGE_TYPE.REMOVE_OBJECT);
		const objectid_str = this.getString(objectid);
		const canvasid_str = this.getString(canvasid);

		/* Calling the buildWhiteBoard function and passing in a function that adds the message type,
							context, and account to the builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
			Whiteboard_Data.addObjectId(this.builder, objectid_str);
			Whiteboard_Data.addCanvasId(this.builder, canvasid_str);
		});
	}

	/**
	 * This function creates a string for the message type and canvas id, then calls the buildWhiteBoard
	 * function and passes in a function that adds the message type and canvas id to the builder
	 * @param canvasid - The id of the canvas to clear.
	 * @returns the buildWhiteBoard function.
	 */
	getClearCanvasBuffer(canvasid) {
		//create strings
		const messagetype_str = this.getString(MQTT_MESSAGE_TYPE.CLEAR_CANVAS);
		const canvasid_str = this.getString(canvasid);

		/* Calling the buildWhiteBoard function and passing in a function that adds the message type,
									context, and account to the builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addMessageType(this.builder, messagetype_str);
			Whiteboard_Data.addCanvasId(this.builder, canvasid_str);
		});
	}

	/**
	 * It takes in a canvasData object, creates a string from the canvasData.canvas and canvasData.id, and
	 * then adds the canvas to the builder
	 * @param canvasData - This is the data that is being sent to the server.
	 * @returns The function buildWhiteBoard is being returned.
	 */
	getSendCanvasInstanceBuffer(canvasData) {
		//creating strings
		const canvassvg_str = this.getString(canvasData.canvas);
		const canvasid_str = this.getString(canvasData.id);

		Canvas.startCanvas(this.builder);
		Canvas.addId(this.builder, canvasid_str);
		Canvas.addCanvas(this.builder, canvassvg_str);
		let canvasOffset = Canvas.endCanvas(this.builder);

		/* Calling the buildWhiteBoard function and passing in a function that adds the canvas to the
		builder. */
		return this.buildWhiteBoard(() => {
			Whiteboard_Data.addCanvas(this.builder, canvasOffset);
		});
	}

	getAccountDetailsOffset() {
		let accountDetail = LocalDb.getAccountDetail();
		if (accountDetail) {
			let accountDetail = LocalDb.getAccountDetail();

			const accountId_str = this.getString(accountDetail.accountid);
			const email = this.getString(accountDetail.email);
			const phone = this.getString(accountDetail.phone);
			const profilePic = this.getString(accountDetail.profilepic);
			const fullName = this.getString(accountDetail.fullname);
			const title = this.getString(accountDetail.title);
			const companyname = this.getString(accountDetail.companyname);
			const workspacename_str = this.getString(accountDetail.workspacename);
			const workspaceid_str = this.getString(accountDetail.workspaceid);
			Account.startAccount(this.builder);
			Account.addAccountid(this.builder, accountId_str);
			Account.addEmail(this.builder, email);
			Account.addPhone(this.builder, phone);
			Account.addAccounttype(this.builder, accountDetail.accounttype);
			Account.addProfilepic(this.builder, profilePic);
			Account.addFullname(this.builder, fullName);
			Account.addTitle(this.builder, title);
			Account.addCompanyname(this.builder, companyname);
			Account.addWorkspacename(this.builder, workspacename_str);
			Account.addWorkspaceid(this.builder, workspaceid_str);
			Account.addEmployeetype(this.builder, accountDetail.employeetype);
			let accountObjectOffset = Account.endAccount(this.builder);
			return accountObjectOffset;
		} else {
			return null;
		}
	}
}

export default FlatBufferBuilder;
