import React from "react";

const NotFound = () => {
	return (
		<div
			style={{
				display: "grid",
				height: "100vh",
				width: "100vw",
				placeItems: "center",
			}}
		>
			Anydone session not found.
		</div>
	);
};

export default NotFound;
