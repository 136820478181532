import React, { useEffect } from 'react';
import './style.css';

const CanvasBoard = ({ artist, canvasId }) => {
	useEffect(() => {
		artist && artist.startCanvas(canvasId);
	}, [artist, canvasId]);

	return (
		<div className="canvas-board">
			<canvas id={canvasId} className="drawing-canvas" />
			<canvas
				id="minimap"
				width="280"
				height="145"
				style={{
					position: 'fixed',
					bottom: '10px',
					right: '10px',
					// border: '1px solid rebeccapurple',
					borderRadius: '10px',
				}}
			/>
		</div>
	);
};

export default CanvasBoard;
