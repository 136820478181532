import styled from "styled-components";

const StyledCanvasPagination = styled.div`
	display: flex;
	gap: 10px;

	.arrow-container {
		align-self: flex-end;
		padding-bottom: 5px;
	}

	.top-bar {
		border-top: 2px solid #666666;
		width: 0px;
		border-radius: 4px;
		margin-bottom: 0px;
	}

	.bottom-bar {
		border-top: 2px solid #666666;
		width: 35px;
		border-radius: 4px;
		margin-bottom: 1px;
	}

	.center-number-container {
		font-size: 12px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1px;
	}

	.pagintaion-box {
		width: fit-content;
		height: 18px;
		padding: 4px 12px;
		border: 2px solid #666666;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.no-border-button {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
	}
`;

export default StyledCanvasPagination;
