import { Tooltip } from '@mui/material';
import React from 'react';
import ToolButton from './ToolButton';

const ToolButtonWithTooltip = ({
	title,
	name,
	selectedTool,
	onClick,
	icon,
}) => (
	<Tooltip title={title} placement="right">
		<div>
			<ToolButton
				className="tool-button"
				name={name}
				type="button"
				onClick={onClick}
				selected={selectedTool === name}
				icon={icon}
			/>
		</div>
	</Tooltip>
);

export default ToolButtonWithTooltip;
