import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ToolIcons from "../../../assets/ToolIcons.js";
import "./style.css";
export const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	borderRadius: "8px",
	boxShadow: 24,
	p: 4,
};

export default function BasicModal({ modalOpen, setOpen, title, children }) {
	const open = modalOpen;
	const handleClose = () => setOpen(false);

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={modalStyle}>
					<div className="modal-header">
						<Typography
							id="modal-modal-title"
							variant="h6"
							component="h2"
							sx={{ fontSize: "1rem" }}
						>
							{title || "Modal title"}
						</Typography>
						<div
							onClick={handleClose}
							onKeyDown={handleClose}
							className="close-icon"
						>
							{ToolIcons.CrossIcon({})}
						</div>
					</div>
					{children}
				</Box>
			</Modal>
		</div>
	);
}
