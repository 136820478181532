import React from 'react';
import { CirclePicker } from 'react-color';
import styled from 'styled-components';

const StickyNoteOptions = ({
	noteColor = '#FFE600',
	handleUpdateStickyNoteColor = () => {},
}) => {
	return (
		<StyledStickyNoteOptions selectedColor={noteColor}>
			<div className="bottom-color-picker-container">
				<CirclePicker
					width="100%"
					styles={{ display: 'flex', justifyContent: 'center' }}
					color={noteColor}
					colors={[
						'#000000',
						'#0F9D58',
						'#1EA4E9',
						'#DE4132',
						'#FFBB24',
						'#00FC81',
						'#6BA8C8',
						'#FFE600',
					]}
					onChange={(color, event) => handleUpdateStickyNoteColor(color, event)}
				/>
			</div>
		</StyledStickyNoteOptions>
	);
};

const StyledStickyNoteOptions = styled.div`
	min-width: 340px;
	padding: 16px;

	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.top-slider-container {
		width: 100%;
		height: fit-content;
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.circle-picker {
		justify-content: center;
	}
`;

export default StickyNoteOptions;
