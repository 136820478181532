// automatically generated by the FlatBuffers compiler, do not modify
var __createBinding =
	(this && this.__createBinding) ||
	(Object.create
		? function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				var desc = Object.getOwnPropertyDescriptor(m, k);
				if (
					!desc ||
					("get" in desc ? !m.__esModule : desc.writable || desc.configurable)
				) {
					desc = {
						enumerable: true,
						get: function () {
							return m[k];
						},
					};
				}
				Object.defineProperty(o, k2, desc);
		  }
		: function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				o[k2] = m[k];
		  });
var __setModuleDefault =
	(this && this.__setModuleDefault) ||
	(Object.create
		? function (o, v) {
				Object.defineProperty(o, "default", { enumerable: true, value: v });
		  }
		: function (o, v) {
				o["default"] = v;
		  });
var __importStar =
	(this && this.__importStar) ||
	function (mod) {
		if (mod && mod.__esModule) return mod;
		var result = {};
		if (mod != null)
			for (var k in mod)
				if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k))
					__createBinding(result, mod, k);
		__setModuleDefault(result, mod);
		return result;
	};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Whiteboard_Data = void 0;
var flatbuffers = __importStar(require("flatbuffers"));
var account_js_1 = require("./account.js");
var canvas_js_1 = require("./canvas.js");
var canvas_object_js_1 = require("./canvas-object.js");
var Whiteboard_Data = /** @class */ (function () {
	function Whiteboard_Data() {
		this.bb = null;
		this.bb_pos = 0;
	}
	Whiteboard_Data.prototype.__init = function (i, bb) {
		this.bb_pos = i;
		this.bb = bb;
		return this;
	};
	Whiteboard_Data.getRootAsWhiteboard_Data = function (bb, obj) {
		return (obj || new Whiteboard_Data()).__init(
			bb.readInt32(bb.position()) + bb.position(),
			bb
		);
	};
	Whiteboard_Data.getSizePrefixedRootAsWhiteboard_Data = function (bb, obj) {
		bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
		return (obj || new Whiteboard_Data()).__init(
			bb.readInt32(bb.position()) + bb.position(),
			bb
		);
	};
	Whiteboard_Data.prototype.messageId = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 4);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.senderAccountId = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 6);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.fullName = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 8);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.messageType = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 10);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.canvasId = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 12);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.canvas = function (obj) {
		var offset = this.bb.__offset(this.bb_pos, 14);
		return offset
			? (obj || new canvas_js_1.Canvas()).__init(
					this.bb.__indirect(this.bb_pos + offset),
					this.bb
			  )
			: null;
	};
	Whiteboard_Data.prototype.newObject = function (obj) {
		var offset = this.bb.__offset(this.bb_pos, 16);
		return offset
			? (obj || new canvas_object_js_1.CanvasObject()).__init(
					this.bb.__indirect(this.bb_pos + offset),
					this.bb
			  )
			: null;
	};
	Whiteboard_Data.prototype.objectId = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 18);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.action = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 20);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.target = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 22);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.background = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 24);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.newCanvas = function (obj) {
		var offset = this.bb.__offset(this.bb_pos, 26);
		return offset
			? (obj || new canvas_js_1.Canvas()).__init(
					this.bb.__indirect(this.bb_pos + offset),
					this.bb
			  )
			: null;
	};
	Whiteboard_Data.prototype.boardName = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 28);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.context = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 30);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Whiteboard_Data.prototype.account = function (obj) {
		var offset = this.bb.__offset(this.bb_pos, 32);
		return offset
			? (obj || new account_js_1.Account()).__init(
					this.bb.__indirect(this.bb_pos + offset),
					this.bb
			  )
			: null;
	};
	Whiteboard_Data.startWhiteboard_Data = function (builder) {
		builder.startObject(15);
	};
	Whiteboard_Data.addMessageId = function (builder, messageIdOffset) {
		builder.addFieldOffset(0, messageIdOffset, 0);
	};
	Whiteboard_Data.addSenderAccountId = function (
		builder,
		senderAccountIdOffset
	) {
		builder.addFieldOffset(1, senderAccountIdOffset, 0);
	};
	Whiteboard_Data.addFullName = function (builder, fullNameOffset) {
		builder.addFieldOffset(2, fullNameOffset, 0);
	};
	Whiteboard_Data.addMessageType = function (builder, messageTypeOffset) {
		builder.addFieldOffset(3, messageTypeOffset, 0);
	};
	Whiteboard_Data.addCanvasId = function (builder, canvasIdOffset) {
		builder.addFieldOffset(4, canvasIdOffset, 0);
	};
	Whiteboard_Data.addCanvas = function (builder, canvasOffset) {
		builder.addFieldOffset(5, canvasOffset, 0);
	};
	Whiteboard_Data.addNewObject = function (builder, newObjectOffset) {
		builder.addFieldOffset(6, newObjectOffset, 0);
	};
	Whiteboard_Data.addObjectId = function (builder, objectIdOffset) {
		builder.addFieldOffset(7, objectIdOffset, 0);
	};
	Whiteboard_Data.addAction = function (builder, actionOffset) {
		builder.addFieldOffset(8, actionOffset, 0);
	};
	Whiteboard_Data.addTarget = function (builder, targetOffset) {
		builder.addFieldOffset(9, targetOffset, 0);
	};
	Whiteboard_Data.addBackground = function (builder, backgroundOffset) {
		builder.addFieldOffset(10, backgroundOffset, 0);
	};
	Whiteboard_Data.addNewCanvas = function (builder, newCanvasOffset) {
		builder.addFieldOffset(11, newCanvasOffset, 0);
	};
	Whiteboard_Data.addBoardName = function (builder, boardNameOffset) {
		builder.addFieldOffset(12, boardNameOffset, 0);
	};
	Whiteboard_Data.addContext = function (builder, contextOffset) {
		builder.addFieldOffset(13, contextOffset, 0);
	};
	Whiteboard_Data.addAccount = function (builder, accountOffset) {
		builder.addFieldOffset(14, accountOffset, 0);
	};
	Whiteboard_Data.endWhiteboard_Data = function (builder) {
		var offset = builder.endObject();
		return offset;
	};
	Whiteboard_Data.finishWhiteboard_DataBuffer = function (builder, offset) {
		builder.finish(offset);
	};
	Whiteboard_Data.finishSizePrefixedWhiteboard_DataBuffer = function (
		builder,
		offset
	) {
		builder.finish(offset, undefined, true);
	};
	return Whiteboard_Data;
})();
exports.Whiteboard_Data = Whiteboard_Data;
