import { Tooltip } from '@mui/material';
import React from 'react';
import Background_images from '../../../assets/background';
import { BACKGROUNDS } from '../../../utils/constants';
import StyledBackgroundSelector from './style';

const BackgroundSelector = ({ selectBackground, selectedBackground }) => {
	const backgroundList = [
		{
			type: BACKGROUNDS.WHITE,
			image: null,
		},
		// {
		// 	type: BACKGROUNDS.BLACK,
		// 	image: null,
		// },
		{
			type: BACKGROUNDS.GRID,
			image: Background_images.grid_pattern(),
		},
		{
			type: BACKGROUNDS.DOTTED,
			image: Background_images.dots_pattern(),
		},
	];
	return (
		<StyledBackgroundSelector>
			{backgroundList.map((image) => {
				if (image.type === BACKGROUNDS.BLACK) {
					return (
						<div
							key={image.type}
							onClick={() => selectBackground(image.type)}
							className="black-image-box image-box"
							style={
								selectedBackground === image.type
									? { border: '0.2px solid #376AF5' }
									: { border: '0.2px solid #666666' }
							}
						></div>
					);
				}
				return (
					<Tooltip title={image.type} placement="top" key={image.type}>
						<div
							key={image.type}
							onClick={() => selectBackground(image.type)}
							style={
								selectedBackground === image.type
									? { border: '0.2px solid #376AF5' }
									: { border: '0.2px solid #666666' }
							}
							className="image-box"
						>
							{image.image}
						</div>
					</Tooltip>
				);
			})}
		</StyledBackgroundSelector>
	);
};

export default BackgroundSelector;
