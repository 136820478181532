import { CreateUUIDWithoutDash } from "../utils/helper";
import LocalDb from "../utils/localStorage";
import { MQTT_MESSAGE_TYPE } from "./constants";
import FlatBufferBuilder from "../flatbuffers/flatbuffer_helpers/flatbuffBuilder";

const flatbuffer_builder = new FlatBufferBuilder({
	senderAccountId:
		LocalDb.getUserAccountId() && LocalDb.getAccountDetail().accountid,
	fullName: LocalDb.getAccountDetail() && LocalDb.getAccountDetail().fullname,
});

class MessageBuilder {
	constructor() {
		this.accountId = LocalDb.getUserAccountId() && LocalDb.getUserAccountId();
		this.fullName =
			LocalDb.getAccountDetail() && LocalDb.getAccountDetail().fullname;
	}

	newMessage(messageType) {
		const message = {
			messageId: CreateUUIDWithoutDash(),
			senderAccountId: this.accountId,
			fullName: this.fullName,
			messageType,
			canvasId: undefined,
			canvas: undefined,
			newObject: undefined,
			objectId: undefined,
			action: undefined,
			target: undefined,
			background: undefined,
			newCanvas: undefined,
			boardName: undefined,
		};
		return message;
	}

	publishOwnSessionRequest() {
		// const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.NEW_PARTICIPANT);
		// const messageToSend = {
		// 	context: "MEET",
		// 	account: LocalDb.getAccountDetail(),
		// 	...newMessage,
		// };
		return flatbuffer_builder.getPublishOwnSessionRequestBuffer();
	}

	joinCanvasRequest() {
		// const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.JOIN_CANVAS_REQUEST);
		const buffer = flatbuffer_builder.getJoinCanvasRequestBuffer();
		return buffer;
	}

	loadCanvasData(svgCanvas) {
		const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.LOAD_CANVAS_DATA);
		const canvas = {
			svg: svgCanvas,
		};
		newMessage.canvas = canvas;
		return flatbuffer_builder.getLoadCanvasDataBuffer();
	}

	newCanvasCreated(canvasData) {
		// const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.NEW_CANVAS_CREATED);
		// newMessage.newCanvas = canvasData;
		// console.log(flatbuffer_builder.getNewCanvasCreatedBuffer(canvasData));
		return flatbuffer_builder.getNewCanvasCreatedBuffer(canvasData);
	}

	updateBoardName(name) {
		// const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.UPDATE_BOARD_NAME);
		// newMessage.boardName = name;
		// console.log(flatbuffer_builder.getUpdateBoardName(name));
		return flatbuffer_builder.getUpdateBoardName(name);
	}

	changeBackground(background, canvasId) {
		// const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.CHANGE_BACKGROUND);
		// newMessage.background = background;
		// newMessage.canvasId = canvasId;
		return flatbuffer_builder.getChangeBackgroundBuffer(background, canvasId);
	}

	newObjectAdded(object, canvasId) {
		// const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.NEW_OBJECT_ADDED);
		const newObject = {
			creator: object.creator,
			objectId: object.id,
			svg: object.svg,
		};

		// newMessage.newObject = newObject;
		// newMessage.canvasId = canvasId;
		const flatbuffer_newObject = flatbuffer_builder.getNewObjectAddedBuffer(
			newObject,
			canvasId
		);
		return flatbuffer_newObject;
	}

	objectModified(event, canvasId) {
		// const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.OBJECT_MODIFIED);
		// newMessage.action = event.action;
		// newMessage.objectId = event.target.id;
		// newMessage.target = event.target;
		// newMessage.canvasId = canvasId;
		const objectmodifiedBuffer = flatbuffer_builder.getObjectModifiedBuffer(
			event.action,
			event.target.id,
			JSON.stringify(event.target),
			canvasId
		);
		return objectmodifiedBuffer;
	}

	removeObject(objectId, canvasId) {
		// const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.REMOVE_OBJECT);
		// newMessage.objectId = objectId;
		// newMessage.canvasId = canvasId;
		const removeObjectBuffer = flatbuffer_builder.getRemoveObjectBuffer(
			objectId,
			canvasId
		);
		return removeObjectBuffer;
	}

	clearCanvas(canvasId) {
		// const newMessage = this.newMessage(MQTT_MESSAGE_TYPE.CLEAR_CANVAS);
		// newMessage.canvasId = canvasId;
		const clearCanvas = flatbuffer_builder.getClearCanvasBuffer(canvasId);
		return clearCanvas;
	}

	sendCanvasInstance(canvas) {
		// const newMessage = this.newMessage();
		// newMessage.canvas = canvas;
		// console.log(newMessage);
		const clearCanvas = flatbuffer_builder.getSendCanvasInstanceBuffer(canvas);
		return clearCanvas;
	}
}

export default MessageBuilder;
