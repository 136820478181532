/* eslint-disable no-unused-vars */
const fabric = window.fabric;
export const createStickyNotes = (
	canvas,
	options,
	onTextChangedCb = () => {}
) => {
	fabric.StickyNote = fabric.util.createClass(fabric.Group, {
		type: 'StickyNote',
		initialize: function (options) {
			this.set(options);
			var height = this.height;
			var width = this.width;
			var fillColor = this.fill;

			var shadowSN = new fabric.Shadow({
				color: '#a3a3a3',
				blur: 4,
				offsetX: 1,
				offsetY: 1,
			});
			this.rectObj = new fabric.Rect({
				width: width,
				height: height,
				fill: this.rectObj?.fill ?? fillColor ?? 'rgba(251,201,112,1)',
				originX: 'center',
				originY: 'center',
				shadow: shadowSN,
				objectCaching: false,
				stateProperties: ['fill'],
			});
			this.textObj = new fabric.Textbox(this.textObj?.text ?? 'Notes', {
				originX: 'center',
				originY: 'center',
				textAlign: 'center',
				width: width - 10,
				selectionColor: fillColor === '#000000' ? '#ffffff' : '#000000',
				textBackgroundColor: fillColor === '#000000' ? '#ffffff' : fillColor,
				hasControls: false,
				fontSize: this.textObj?.fontSize ?? 30,
				lineHeight: 1,
				stateProperties: ['text', 'fontSize'],
				scaleX: this.textObj?.scaleX ?? 1,
				scaleY: this.textObj?.scaleY ?? 1,
				objectCaching: false,
				breakWords: true,
				fontFamily: 'Arial',
			});

			this._objects = [this.rectObj, this.textObj];
			//   this custom _set function will set custom properties value to object when it will load from json.
			// at that time loadFromJson function will call this initialize function.
			// this._setCustomProperties(this.options);
			canvas.renderAll();

			//evenet will fire if the object is double clicked by mouse
			this.on('mousedblclick', (e) => {
				var pasteFlag = false;
				var scaling = e.target.getScaledWidth() / 100;
				var textForEditing;
				canvas.bringToFront(e.target);
				e.target.selectable = false;
				const [rectObj, textObj] = this.getObjects();
				textObj.clone(function (clonedObj) {
					clonedObj.set({
						left: e.target.left,
						top: e.target.top,
						lockMovementY: true,
						lockMovementX: true,
						hasBorders: false,
						scaleX: scaling,
						scaleY: scaling,
						breakWords: true,
						width: textObj.width,
						stateProperties: [],
					});
					textForEditing = clonedObj;
				});

				this.remove(textObj);
				canvas.add(textForEditing);
				canvas.setActiveObject(textForEditing);

				textForEditing.enterEditing();
				textForEditing.selectAll();

				textForEditing.paste = (function (paste) {
					return function (e) {
						disableScrolling();
						pasteFlag = true;
					};
				})(textForEditing.paste);

				textForEditing.on('changed', function (e) {
					var fontSize = textForEditing.fontSize;
					var charCount = Math.max(textForEditing._text.length, 1);
					var charWR =
						(textForEditing.textLines.length * width) / (charCount * fontSize);

					if (textForEditing.height < height - 15) {
						fontSize = Math.min(
							Math.sqrt(
								((height - 10 - fontSize) / 1.16) * (width / (charCount * charWR))
							),
							30
						);
					}
					if (textForEditing.height > height - 15) {
						fontSize = Math.sqrt(
							((height - 10) / 1.16) * (width / (charCount * charWR))
						);
					}
					if (pasteFlag) {
						pasteFlag = false;
						while (
							textForEditing.height > height - 15 &&
							textForEditing.fontSize > 0
						) {
							fontSize = textForEditing.fontSize -= 0.2;
							canvas.renderAll();
						}
					}
					textForEditing.fontSize = fontSize;
				});

				textForEditing.on('editing:exited', () => {
					enableScrolling();
					canvas.setActiveObject(textObj);
					textObj.set({
						text: textForEditing.text,
						fontSize: textForEditing.fontSize,
						visible: true,
					});
					this.add(textObj);
					this.selectable = true;
					canvas.remove(textForEditing);
					console.log({ thisObj: this, textObj });
					let objToSend = {
						objectId: this.id,
						target: this,
					};
					objToSend.target.textObj = textObj;
					onTextChangedCb && onTextChangedCb(objToSend);
					canvas.discardActiveObject();
				});
			});

			function disableScrolling() {
				var x = window.scrollX;
				var y = window.scrollY;
				window.onscroll = function () {
					window.scrollTo(x, y);
				};
			}

			var _wrapLine = function (_line, lineIndex, desiredWidth, reservedSpace) {
				var lineWidth = 0,
					splitByGrapheme = this.splitByGrapheme,
					graphemeLines = [],
					line = [],
					// spaces in different languges?
					words = splitByGrapheme
						? fabric.util.string.graphemeSplit(_line)
						: _line.split(this._wordJoiners),
					word = '',
					offset = 0,
					infix = splitByGrapheme ? '' : ' ',
					wordWidth = 0,
					infixWidth = 0,
					largestWordWidth = 0,
					lineJustStarted = true,
					additionalSpace = splitByGrapheme ? 0 : this._getWidthOfCharSpacing();

				reservedSpace = reservedSpace || 0;
				desiredWidth -= reservedSpace;
				for (var i = 0; i < words.length; i++) {
					// i would avoid resplitting the graphemes
					word = fabric.util.string.graphemeSplit(words[i]);
					wordWidth = this._measureWord(word, lineIndex, offset);
					offset += word.length;

					// Break the line if a word is wider than the set width
					if (this.breakWords && wordWidth >= desiredWidth) {
						if (!lineJustStarted) {
							graphemeLines.push(line);
							line = [];
							lineWidth = 0;
							lineJustStarted = true;
						}
						this.fontSize *= desiredWidth / (wordWidth + 1);
						// Loop through each character in word
						for (var w = 0; w < word.length; w++) {
							var letter = word[w];
							var letterWidth =
								(this.getMeasuringContext().measureText(letter).width * this.fontSize) /
								this.CACHE_FONT_SIZE;
							line.push(letter);
							lineWidth += letterWidth;
						}
						word = [];
					} else {
						lineWidth += infixWidth + wordWidth - additionalSpace;
					}

					if (lineWidth >= desiredWidth && !lineJustStarted) {
						graphemeLines.push(line);
						line = [];
						lineWidth = wordWidth;
						lineJustStarted = true;
					} else {
						lineWidth += additionalSpace;
					}

					if (!lineJustStarted) {
						line.push(infix);
					}
					line = line.concat(word);

					infixWidth = this._measureWord([infix], lineIndex, offset);
					offset++;
					lineJustStarted = false;
					// keep track of largest word
					if (wordWidth > largestWordWidth && !this.breakWords) {
						largestWordWidth = wordWidth;
					}
				}

				i && graphemeLines.push(line);

				if (largestWordWidth + reservedSpace > this.dynamicMinWidth) {
					this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
				}

				return graphemeLines;
			};

			fabric.util.object.extend(fabric.Textbox.prototype, {
				_wrapLine: _wrapLine,
			});

			function enableScrolling() {
				window.onscroll = function () {};
			}
		},

		toObject: function (propertiesToInclude) {
			// This function is used for serialize this object. (used for create json)
			// not inlclude this.textObj and this.rectObj into json because when object will load from json, init fucntion of this class is called and it will assign this two object textObj and rectObj again.
			var obj = this.callSuper(
				'toObject',
				[
					'objectCaching',
					'textObj',
					'rectObj',
					// ... property list that you want to add into json when this object is convert into json using toJSON() function. (serialize)
				].concat(propertiesToInclude)
			);
			// delete objects array from json because then object load from json, Init function will call. which will automatically re-assign object and assign _object array.
			delete obj.objects;
			return obj;
		},

		changeText: function (text, options) {
			this.textObj.text = text;
			const fontSize = options?.fontSize;
			if (fontSize) this.textObj.fontSize = fontSize;
			this.dirty = true;
			this.canvas?.renderAll();
		},
	});

	fabric.StickyNote.async = true;
	fabric.StickyNote.fromObject = function (object, callback) {
		// This function is used for deserialize json and convert object json into button object again. (called when we call loadFromJson() fucntion on canvas)
		return fabric.Object._fromObject('StickyNote', object, callback);
	};

	return new fabric.StickyNote(options);
};
