/* eslint-disable no-unused-expressions */
import { MqttRelayServer } from '@anydone/js_relay_client';
import config from './config';
import { CreateUUID } from './helper';
import LocalDb from './localStorage';
import Subscription from './Subscription';

const host = config.mqttURL;

let instance;

class RelayConnection {
	#relayServer = undefined;

	#subscription = undefined;

	#handlers = {};

	#disconnected = false;

	constructor() {
		if (instance) {
			throw new Error('Duplicate instance.');
		}
		this.#subscription = new Subscription();
		if (!this.isConnected()) {
			this.connect();
		}
		instance = this;
	}

	connect = () => {
		const token = () => LocalDb.getUserToken();
		const userId = () => LocalDb.getUserAccountId();
		console.debug('token,', { token: token(), user: userId(), host });
		if (!token() || !userId() || token().length === 0) {
			return;
		}
		this.#relayServer = new MqttRelayServer(
			`WB-${CreateUUID()}`,
			host,
			userId(),
			token()
		);

		// create a function to handle the message

		this.#relayServer.connect(
			{
				onConnect: this.#onConnect,
				onError: this.#onError,
				onClose: this.#onClose,
				onReconnect: this.#onReconnect,
				onReconnectInProgress: this.#onReconnect,
				onMessage: this.#onMessage,
			}
			// this.#getWillMsg(this.getOnlineStatusPayload(false)),
		);
	};

	#onConnect = () => {
		if (!this.isConnected()) {
			console.debug(
				'%cConnected through Relay.',
				'font-size:20px; color:green',
				this.#subscription.getTopics()
			);
			this.#subscription.getTopics().forEach((topic) => {
				this.subscribe(topic.topic, { qos: 0 });
				this.#handlers[topic.topic] = topic.handler;
			});
		}
	};

	#onError = (err) => {
		console.error('Error ', err);
	};

	#onClose = () => {
		const that = this;
		this.checkIfOnline().then((isOnline) => {
			if (!isOnline) {
				that.#disconnected = true;
			} else {
				that.#disconnected = false;
			}
		});
	};

	#onReconnect = () => {
		// if (this.#disconnected) {
		//   store &&
		//     store.dispatch(
		//       handleChangeMqttStatus({
		//         open: true,
		//         variant: 'error',
		//         msg: 'Reconnecting...',
		//       }),
		//     );
		// }
	};

	#onMessage = async (topic, payload, packet) => {
		console.debug('Message topic.: ', topic);
		const handler = this.#handlers[topic];
		handler(topic, payload, packet);
	};

	getRelayServer = () => {
		return this.#relayServer;
	};

	publish = async (topic, payload, cb = undefined) => {
		console.debug({ topic }, { payload });
		this.#relayServer && this.#relayServer.publishAsync(topic, payload, null, cb);
	};

	subscribe = (topic, options, cb = undefined) => {
		this.#relayServer && this.#relayServer.subscribe(topic, options, cb);
	};

	unsubscribe = (topic, options, cb = undefined) => {
		this.#relayServer && this.#relayServer.unsubscribe(topic, options, cb);
	};

	subscribeWithHandler = (topic, handler) => {
		this.subscribe(topic, { qos: 0 });
		this.#handlers[topic] = handler;
	};

	getClientId = () => {
		return this.#relayServer && this.#relayServer.getClientId();
	};

	isConnected = () => {
		return this.#relayServer && this.#relayServer.isConnected();
	};

	checkIfOnline = async () => {
		return this.#relayServer && this.#relayServer.checkIfOnline();
	};
}

export default RelayConnection;
