const Background_images = {
	grid_pattern: (options) => (
		<svg
			width="500"
			height="500"
			viewBox="0 0 500 500"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.05"
				y="0.05"
				width="499.9"
				height="499.9"
				rx="1.95"
				fill="white"
				stroke="#666666"
				strokeWidth="0.1"
			/>
			<line y1="49.9" x2="500" y2="49.9" stroke="#C4C4C4" strokeWidth="0.2" />
			<line y1="99.9" x2="500" y2="99.9" stroke="#C4C4C4" strokeWidth="0.2" />
			<line y1="149.9" x2="500" y2="149.9" stroke="#C4C4C4" strokeWidth="0.2" />
			<line y1="199.9" x2="500" y2="199.9" stroke="#C4C4C4" strokeWidth="0.2" />
			<line y1="249.9" x2="500" y2="249.9" stroke="#C4C4C4" strokeWidth="0.2" />
			<line y1="299.9" x2="500" y2="299.9" stroke="#C4C4C4" strokeWidth="0.2" />
			<line y1="349.9" x2="500" y2="349.9" stroke="#C4C4C4" strokeWidth="0.2" />
			<line y1="399.9" x2="500" y2="399.9" stroke="#C4C4C4" strokeWidth="0.2" />
			<line y1="449.9" x2="500" y2="449.9" stroke="#C4C4C4" strokeWidth="0.2" />
			<line
				x1="450.1"
				y1="4.37114e-09"
				x2="450.1"
				y2="500"
				stroke="#C4C4C4"
				strokeWidth="0.2"
			/>
			<line
				x1="400.1"
				y1="4.37114e-09"
				x2="400.1"
				y2="500"
				stroke="#C4C4C4"
				strokeWidth="0.2"
			/>
			<line
				x1="350.1"
				y1="4.37114e-09"
				x2="350.1"
				y2="500"
				stroke="#C4C4C4"
				strokeWidth="0.2"
			/>
			<line
				x1="300.1"
				y1="4.37114e-09"
				x2="300.1"
				y2="500"
				stroke="#C4C4C4"
				strokeWidth="0.2"
			/>
			<line
				x1="250.1"
				y1="4.37114e-09"
				x2="250.1"
				y2="500"
				stroke="#C4C4C4"
				strokeWidth="0.2"
			/>
			<line
				x1="200.1"
				y1="4.37114e-09"
				x2="200.1"
				y2="500"
				stroke="#C4C4C4"
				strokeWidth="0.2"
			/>
			<line
				x1="150.1"
				y1="4.37114e-09"
				x2="150.1"
				y2="500"
				stroke="#C4C4C4"
				strokeWidth="0.2"
			/>
			<line
				x1="100.1"
				y1="4.37114e-09"
				x2="100.1"
				y2="500"
				stroke="#C4C4C4"
				strokeWidth="0.2"
			/>
			<line
				x1="50.1"
				y1="4.37114e-09"
				x2="50.1"
				y2="500"
				stroke="#C4C4C4"
				strokeWidth="0.2"
			/>
		</svg>
	),

	dots_pattern: (options) => (
		<svg
			width="500"
			height="500"
			viewBox="0 0 500 500"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.05"
				y="0.05"
				width="499.9"
				height="499.9"
				rx="1.95"
				fill="white"
				stroke="#666666"
				strokeWidth="0.1"
			/>
			<circle cx="30" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="30" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="70" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="110" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="150" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="190" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="230" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="270" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="310" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="350" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="390" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="430" r="5" fill="#C4C4C4" />
			<circle cx="30" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="70" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="110" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="150" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="190" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="230" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="270" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="310" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="350" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="390" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="430" cy="470" r="5" fill="#C4C4C4" />
			<circle cx="470" cy="470" r="5" fill="#C4C4C4" />
		</svg>
	),
};

export default Background_images;
