import React from 'react';

const Welcome = () => {
	return (
		<div
			style={{
				display: 'grid',
				height: '100vh',
				width: '100vw',
				placeItems: 'center',
			}}
		>
			Welcome to anydone CoVas
		</div>
	);
};

export default Welcome;
