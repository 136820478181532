export const TOOL_TYPE = {
	TEXTBOX: 'TEXT_BOX',
	STROKE_PROP_SELECTOR: 'STROKE_PROP_SELECTOR',
	OPACITY_SELECTOR: 'OPACITY_SELECTOR',
	ERASER: 'ERASER',
	SHAPES: 'SHAPES',
	UNDO: 'UNDO',
	POINTER: 'POINTER',
	ADD_IMAGE: 'ADD_IMAGE',
	DOWNLOAD_SCREENSHOT: 'DOWNLOAD_SCREENSHOT',
	LASER: 'LASER',
};

export const SHAPES = {
	CIRCLE: 'CIRCLE',
	SQUARE: 'SQUARE',
	RECTANGLE: 'RECTANGLE',
	TRIANGLE: 'TRIANGLE',
	DIAMOND: 'DIAMOND',
	LINE: 'LINE',
	ARROW: 'ARROW',
	STAR: 'STAR',
	DECISION: 'DECISION',
	PROCESS: 'PROCESS',
	DATA: 'DATA',
	TERMINAL: 'TERMINAL',
};

export const MQTT_TOPICS = {
	WHTIEBOARD: 'anydone/whiteboard/123',
};

export const BACKGROUNDS = {
	WHITE: 'WHITE',
	BLACK: 'BLACK',
	DOTTED: 'DOTTED',
	GRID: 'GRID',
};

export const MQTT_MESSAGE_TYPE = {
	JOIN_CANVAS_REQUEST: 'JOIN_CANVAS_REQUEST',
	LOAD_CANVAS_DATA: 'LOAD_CANVAS_DATA',
	NEW_OBJECT_ADDED: 'NEW_OBJECT_ADDED',
	OBJECT_MODIFIED: 'OBJECT_MODIFIED',
	CLEAR_CANVAS: 'CLEAR_CANVAS',
	REMOVE_OBJECT: 'REMOVE_OBJECT',
	CHANGE_BACKGROUND: 'CHANGE_BACKGROUND',
	NEW_CANVAS_CREATED: 'NEW_CANVAS_CREATED',
	UPDATE_BOARD_NAME: 'UPDATE_BOARD_NAME',
};

export const DRAW_ACTORS = {
	SELF_ARTIST: 'SELF_ARTIST',
	REMOTE_ARTIST: 'REMOTE_ARTIST',
};
