import styled from "styled-components";

export const InboxIconWrapper = styled.div`
	border: 1px solid ${(props) => props.color};
	background: ${(props) => props.background};
	color: ${(props) => props.color};
	border-radius: 50%;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	font-size: ${(props) =>
		`${
			props.width.includes("px")
				? props.width.slice(0, -2) * 0.5 - 2 + "px"
				: props.width
		}`};
	display: flex;
	justify-content: center;
	align-items: center;
`;
