import { CreateUUID } from "../utils/helper";
import { relayServerConnection } from "../routes/PrivateRoute";

class MqttHandler {
	constructor(accountId, token, canvasRoomId) {
		this.clientId = CreateUUID();
		this.userName = accountId;
		this.token = token;
		this.canvasRoomId = canvasRoomId;
	}

	onFailure() {
		console.log("ON MQTT CONNECTION FAILURE");
		this.startConnection();
	}

	startConnection() {
		relayServerConnection.connect();
	}

	publish(topic, msg) {
		if (relayServerConnection.isConnected()) {
			try {
				relayServerConnection.publish(topic, msg, null, (error) => {
					console.log(
						"Error while publishing message. ",
						{ error },
						{ topic },
						{ msg }
					);
				});
			} catch (error) {
				console.log(error);
			}
		} else {
			console.log("COULD NOT PUBLISH: MQTT NOT CONNECTED");
			relayServerConnection.connect();
		}
	}

	serializeMessage(message) {
		const serialized = JSON.stringify(message);
		return serialized;
	}

	desirializeMessage(message) {
		const deserialized = JSON.parse(message);
		return deserialized;
	}
}

export default MqttHandler;
