import React from "react";
import { Switch } from "react-router-dom";
import NotFound from "./components/Layout/NotFound";
import Board from "./pages/Board";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
// import MainPage from "./pages/MainPage";
// import InvalidRoom from "./components/Layout/InvalidRoom";
import Welcome from "./components/Layout/Welcome";

const App = () => {
	return (
		<Switch>
			<PublicRoute exact path="/" from component={NotFound} />
			{/* <PublicRoute exact path="/invalid-room" from component={InvalidRoom} /> */}
			<PrivateRoute path="/board/:canvasRoomId" from component={Board} />
			<PrivateRoute path="/board/" component={Welcome} />
		</Switch>
	);
};

export default App;
