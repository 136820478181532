import { useEffect } from 'react';
import { TOOL_TYPE } from '../utils/constants';

const useKeyboardEvents = (artist, states) => {
	const { setSelectedTool } = states;
	useEffect(() => {
		const handleKeyDown = (event) => {
			handleKeyboardEvents(event);
		};

		const handleKeyboardEvents = (event) => {
			switch (event.code) {
				case 'Delete':
				case 'Backspace':
					artist.deleteSelectedObjectsFromCanvas();
					break;
				case 'KeyD':
					if (event.ctrlKey || event.metaKey) {
						event.preventDefault();
						artist.duplicateElement();
					}
					break;
				case 'KeyT':
					if ((event.ctrlKey || event.metaKey) && event.altKey) {
						event.preventDefault();
						artist.addTextbox();
					}
					break;
				case 'KeyE':
					if (event.ctrlKey || event.metaKey) {
						event.preventDefault();
						artist.enableEraser();
						setSelectedTool && setSelectedTool(TOOL_TYPE.ERASER);
					}
					break;
				case 'KeyP':
					if (event.ctrlKey || event.metaKey) {
						event.preventDefault();
						artist.initializePencil();
						setSelectedTool && setSelectedTool(TOOL_TYPE.STROKE_PROP_SELECTOR);
					}
					break;
				case 'KeyZ':
					if (event.ctrlKey || event.metaKey) {
						event.preventDefault();
						artist.undoObject();
					}
					break;
				case 'KeyV':
					if (event.ctrlKey || event.metaKey) {
						event.preventDefault();
						artist.enableSelector();
						setSelectedTool && setSelectedTool(TOOL_TYPE.POINTER);
					}
					break;
				case 'KeyK':
					if (event.ctrlKey || event.metaKey) {
						event.preventDefault();
						artist.takeScreenshot();
					}
					break;
				default:
					handleArrowEvents(event);
					break;
			}
		};

		const handleArrowEvents = (event) => {
			const activeObject = artist.canvas.getActiveObject();

			if (!activeObject) {
				return;
			}

			const moveAmount = 1;

			switch (event.code) {
				case 'ArrowUp':
					activeObject.top -= moveAmount;
					break;
				case 'ArrowDown':
					activeObject.top += moveAmount;
					break;
				case 'ArrowLeft':
					activeObject.left -= moveAmount;
					break;
				case 'ArrowRight':
					activeObject.left += moveAmount;
					break;
				default:
					return;
			}
			// need to add debounce

			artist.onObjectModified({ target: activeObject });
			artist.canvas.renderAll();
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [artist, setSelectedTool]);
};

export default useKeyboardEvents;
