import { DRAW_ACTORS, MQTT_MESSAGE_TYPE } from '../utils/constants';
// import { fabric } from 'fabric';
import GridBackground from '../assets/background1.svg';
import dots_pattern from '../assets/dots_pattern.svg';
import { BACKGROUNDS } from '../utils/constants';
import { createStickyNotes } from './StickyNote';
const fabric = window.fabric;

class RemoteArtist {
	constructor(canvasArtist) {
		this.canvasArtist = canvasArtist;
		this.artistList = [];
		this.currentRemoteArtist = null;
	}

	newRemoteAction(message) {
		const canvasToUpdate =
			message.canvasId &&
			this.canvasArtist?.canvasList?.find(
				(canvas) => canvas.id === message.canvasId
			)?.canvas;
		switch (message.messageType) {
			case MQTT_MESSAGE_TYPE.LOAD_CANVAS_DATA: {
				this.loadCanvasData(message.canvas);
				break;
			}
			case MQTT_MESSAGE_TYPE.NEW_OBJECT_ADDED: {
				// console.log('message ==>', message);
				if (message?.newObject?.objectId?.includes('-ERASER')) return;
				this.onNewObjectAdded(message.newObject, canvasToUpdate);
				break;
			}
			case MQTT_MESSAGE_TYPE.OBJECT_MODIFIED: {
				this.onObjectModified(message, canvasToUpdate);
				break;
			}
			case MQTT_MESSAGE_TYPE.CLEAR_CANVAS: {
				this.clearCanvas(canvasToUpdate);
				break;
			}
			case MQTT_MESSAGE_TYPE.REMOVE_OBJECT: {
				this.removeObject(message.objectId, canvasToUpdate);
				break;
			}
			case MQTT_MESSAGE_TYPE.CHANGE_BACKGROUND: {
				this.changeCanvasBackground(message);
				break;
			}
			case MQTT_MESSAGE_TYPE.NEW_CANVAS_CREATED: {
				this.addNewCanvasToList(message.newCanvas);
				break;
			}
			case MQTT_MESSAGE_TYPE.UPDATE_BOARD_NAME: {
				this.updateBoardName(message.boardName);
				break;
			}
			default:
				break;
		}
	}

	loadCanvasData(canvas) {
		console.log(canvas);
		fabric.loadSVGFromString(canvas.svg, (objects, options) => {
			console.log(options);
			objects.forEach((obj) => {
				// obj.id = objectData.objectId;
				// obj.creator = objectData.creator;
				// obj.type === "path" && (obj.selectable = false);
				this.canvasArtist.canvas.add(obj);
				this.canvasArtist.canvas.renderAll();
			});
		});
	}

	addNewCanvasToList(canvasObj) {
		this.canvasArtist.saveNewRemoteCanvas(canvasObj);
	}

	changeCanvasBackground(message) {
		const { background, canvasId } = message;
		const canvasToUpdate = this.canvasArtist.canvasList.find(
			(canvas) => canvas.id === canvasId
		);
		this.renderBackground(canvasToUpdate.canvas, background);
	}

	updateBoardName(name) {
		this.canvasArtist.updateBoardName(name, DRAW_ACTORS.REMOTE_ARTIST);
	}

	renderBackground(canvas, background) {
		if (background === BACKGROUNDS.WHITE) {
			canvas.backgroundImage = 0;
			canvas.fillStyle = '#fff';
			canvas.setBackgroundColor('#fff', () => {
				canvas.renderAll();
			});
			return;
		} else if (background === BACKGROUNDS.BLACK) {
			canvas.backgroundImage = 0;
			canvas.setBackgroundColor('#000', () => {
				canvas.renderAll();
			});
			return;
		} else {
			const backgroundImage =
				background === BACKGROUNDS.GRID
					? GridBackground
					: background === BACKGROUNDS.DOTTED
						? dots_pattern
						: null;
			fabric.Image.fromURL(backgroundImage, (img) => {
				img.scaleToWidth(600);
				img.opacity = 0.25;
				canvas.setBackgroundImage(img);
				canvas.requestRenderAll();
			});
		}
	}

	checkForPosition = (arrayOfObjects) => {
		for (const obj of arrayOfObjects) {
			if (obj.left === 10 && obj.top === 10) {
				return false;
			}
		}
		return true;
	};

	onNewObjectAdded(objectData, canvas) {
		var ObjId = objectData.objectId;
		fabric.loadSVGFromString(objectData.svg, (objects, options) => {
			if (objects.length === 0) return;
			if (!canvas) {
				return;
			}
			if (ObjId.includes('-STICKYNOTE')) {
				var options = {
					width: 100,
					height: 100,
					left: 160,
					top: 60,
					originX: 'center',
					originY: 'center',
					fill: ObjId?.split('-')[2],
					textObj: {
						text: 'Write your note',
						fontSize: 12,
					},
				};
				var notes = createStickyNotes(canvas, options, (stickyNote) => {
					this.canvasArtist.onObjectModified(stickyNote);
				});
				notes.id = `${ObjId}`;
				canvas.add(notes);
				canvas.renderAll();
				return;
			}

			if (ObjId.includes('-ARROW')) {
				const group = new fabric.Group(objects, {
					...options,
					id: ObjId,
					objectId: ObjId,
					creator: objectData.creator,
				});
				canvas.add(group);
				canvas.renderAll();
				return;
			}

			objects.forEach((obj) => {
				obj.id = objectData.objectId;
				obj.creator = objectData.creator;

				//* uncomment this to make the pen tool not selectable
				// obj.type === "path" &&
				// 	obj.strokeLineCap === "round" &&
				// 	(obj.selectable = false);
				let textBoxCount = canvas
					.getObjects()
					.filter((obj) => obj.type === 'textbox').length;

				let noTextboxInInitialPosition = this.checkForPosition(
					canvas.getObjects().filter((obj) => obj.type === 'textbox')
				);

				if (obj.type === 'text') {
					let optionsopt = obj.toObject();
					let newObj = {
						...obj,
						top: noTextboxInInitialPosition ? 10 : (textBoxCount + 1) * 10,
						left: noTextboxInInitialPosition ? 10 : (textBoxCount + 1) * 10,
						hasControls: true,
						breakWords: true,
					};
					var newEditableText = new fabric.Textbox(optionsopt.text, newObj);
					canvas.add(newEditableText);
					canvas.renderAll();
					return;
				}
				canvas.add(obj);
				canvas.renderAll();
			});
		});
	}

	onObjectModified(object, canvas) {
		const canvasObjects = canvas.getObjects();

		/* Finding the object in the canvas that has the same id as the object that was modified. */
		let modifiedObject = canvasObjects.find((obj) => obj.id === object.objectId);

		function replaceValues(obj1, obj2) {
			if (!obj1 || !obj2) return;
			Object.keys(obj1).forEach((key) => {
				if (obj2.hasOwnProperty(key)) {
					obj1[key] = obj2[key];
				}
			});
		}

		// console.log({ modifiedObject, object });
		// if (!modifiedObject) return;
		if (modifiedObject?.type === 'StickyNote') {
			const stringText = object?.target?.textObj?.text;
			modifiedObject?.changeText(stringText.toString(), {
				fontSize: object?.target?.textObj?.fontSize,
				height: object?.target?.textObj?.height,
			});
			modifiedObject?._objects?.forEach((obj) => {
				if (obj.text) {
					obj.text = modifiedObject?.textObj?.text;
					obj.textLines = [modifiedObject?.textObj?.text];
					obj._text = [modifiedObject?.textObj?.text?.split('')];
				}
			});
		}
		if (modifiedObject) {
			/* Iterating over the object.target and setting the modifiedObject[key] to value. */
			for (const [key, value] of Object.entries(object.target)) {
				modifiedObject[key] = value;
			}
			modifiedObject.setCoords();
			this.canvasArtist.updateMiniMap();
			canvas.renderAll();
		}
	}

	removeObject(objectId) {
		if (this.canvasArtist.canvas._objects.length <= 1) {
			this.canvasArtist.canvas.clear();
			this.renderAll();
			return;
		}
		if (this.canvasArtist.canvas._objects.length === 1) debugger;
		const canvasObjects = this.canvasArtist.canvas.getObjects();
		const removedObject = canvasObjects.find((obj) => obj.id === objectId);
		removedObject && this.canvasArtist.canvas.remove(removedObject);
		this.renderAll();
	}

	renderAll() {
		this.canvasArtist.canvas.renderAll();
	}

	clearCanvas(canvas) {
		this.canvasArtist.clearCanvas(canvas, DRAW_ACTORS.REMOTE_ARTIST);
	}
}

export default RemoteArtist;
