import { MQTT_TOPICS } from "../utils/constants";
import MessageBuilder from "./messageBuilder";

class MessagePublisher {
	constructor(mqttHandler, canvasRoomId) {
		this.mqttHandler = mqttHandler;
		this.messageBuilder = new MessageBuilder();
		this.canvasRoomId = canvasRoomId;
	}

	publish(message) {
		if (!message || !this.mqttHandler) return;
		this.mqttHandler.publish(
			`${MQTT_TOPICS.WHITEBOARD}${this.canvasRoomId}/canvas/1`,
			message
		);
	}

	updateBoardName(name) {
		const message = this.messageBuilder.updateBoardName(name);
		this.publish(message);
	}

	publishOwnSessionToWB() {
		const message = this.messageBuilder.publishOwnSessionRequest();
		this.publish(message);
	}

	newCanvasCreated(canvasData) {
		const message = this.messageBuilder.newCanvasCreated(canvasData);

		this.publish(message);
	}

	changeBackground(background, canvasId) {
		const message = this.messageBuilder.changeBackground(background, canvasId);
		this.publish(message);
	}

	joinCanvasRequest() {
		const message = this.messageBuilder.joinCanvasRequest();
		this.publish(message);
	}

	loadCanvasData(svgCanvas) {
		const message = this.messageBuilder.loadCanvasData(svgCanvas);
		this.publish(message);
	}

	newObjectAdded(object, canvasId) {
		const message = this.messageBuilder.newObjectAdded(object, canvasId);
		this.publish(message);
	}

	objectModified(event, canvasId) {
		console.log("targettt to change", event);
		const message = this.messageBuilder.objectModified(event, canvasId);
		this.publish(message);
	}

	removeObject(objectId) {
		const message = this.messageBuilder.removeObject(objectId);
		this.publish(message);
	}

	clearCanvas(canvasId) {
		const message = this.messageBuilder.clearCanvas(canvasId);
		this.publish(message);
	}
}

export default MessagePublisher;
