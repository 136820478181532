import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import Loader from "../components/elements/Loader/Loader";
import RelayConnection from "../utils/RelayServerConnection";
import useSessionFromCookie from "../Hooks/useSessionFromCookie";
export const relayServerConnection = new RelayConnection();

export const PrivateRoute = ({
	component: Component,
	redirect: RedirectComponent,
	key,
	...rest
}) => {
	const { sessionSet, fetchingSession } = useSessionFromCookie();
	return (
		<Route
			key={key}
			{...rest}
			render={(props) => {
				if (fetchingSession) {
					return <LoaderPage />;
				}
				if (!fetchingSession && sessionSet) {
					return <Component {...props} />;
				}
				return <h1>Request Failed</h1>;
			}}
		/>
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.any,
};

const LoaderPage = () => (
	<div
		style={{
			height: "100vh",
			width: "100vw",
			display: "grid",
			placeItems: "center",
		}}
	>
		<Loader />
	</div>
);
export default PrivateRoute;
