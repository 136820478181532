// automatically generated by the FlatBuffers compiler, do not modify
var __createBinding =
	(this && this.__createBinding) ||
	(Object.create
		? function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				var desc = Object.getOwnPropertyDescriptor(m, k);
				if (
					!desc ||
					("get" in desc ? !m.__esModule : desc.writable || desc.configurable)
				) {
					desc = {
						enumerable: true,
						get: function () {
							return m[k];
						},
					};
				}
				Object.defineProperty(o, k2, desc);
		  }
		: function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				o[k2] = m[k];
		  });
var __setModuleDefault =
	(this && this.__setModuleDefault) ||
	(Object.create
		? function (o, v) {
				Object.defineProperty(o, "default", { enumerable: true, value: v });
		  }
		: function (o, v) {
				o["default"] = v;
		  });
var __importStar =
	(this && this.__importStar) ||
	function (mod) {
		if (mod && mod.__esModule) return mod;
		var result = {};
		if (mod != null)
			for (var k in mod)
				if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k))
					__createBinding(result, mod, k);
		__setModuleDefault(result, mod);
		return result;
	};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Canvas = void 0;
var flatbuffers = __importStar(require("flatbuffers"));
var Canvas = /** @class */ (function () {
	function Canvas() {
		this.bb = null;
		this.bb_pos = 0;
	}
	Canvas.prototype.__init = function (i, bb) {
		this.bb_pos = i;
		this.bb = bb;
		return this;
	};
	Canvas.getRootAsCanvas = function (bb, obj) {
		return (obj || new Canvas()).__init(
			bb.readInt32(bb.position()) + bb.position(),
			bb
		);
	};
	Canvas.getSizePrefixedRootAsCanvas = function (bb, obj) {
		bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
		return (obj || new Canvas()).__init(
			bb.readInt32(bb.position()) + bb.position(),
			bb
		);
	};
	Canvas.prototype.id = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 4);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Canvas.prototype.canvas = function (optionalEncoding) {
		var offset = this.bb.__offset(this.bb_pos, 6);
		return offset
			? this.bb.__string(this.bb_pos + offset, optionalEncoding)
			: null;
	};
	Canvas.startCanvas = function (builder) {
		builder.startObject(2);
	};
	Canvas.addId = function (builder, idOffset) {
		builder.addFieldOffset(0, idOffset, 0);
	};
	Canvas.addCanvas = function (builder, canvasOffset) {
		builder.addFieldOffset(1, canvasOffset, 0);
	};
	Canvas.endCanvas = function (builder) {
		var offset = builder.endObject();
		return offset;
	};
	Canvas.createCanvas = function (builder, idOffset, canvasOffset) {
		Canvas.startCanvas(builder);
		Canvas.addId(builder, idOffset);
		Canvas.addCanvas(builder, canvasOffset);
		return Canvas.endCanvas(builder);
	};
	return Canvas;
})();
exports.Canvas = Canvas;
