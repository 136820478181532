import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

const PublicRoute = ({
	component: Component,
	redirect: RedirectComponent,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				// if (LocalDb.getSessions()) {
				// 	return (
				// 		<Redirect
				// 			to={{
				// 				pathname: "/board/",
				// 			}}
				// 		/>
				// 	);
				// }
				return <Component {...props} />;
			}}
		/>
	);
};

PublicRoute.propTypes = {
	component: PropTypes.any,
};

export default PublicRoute;
