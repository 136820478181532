import styled from "styled-components";

const StyledStrokeSelector = styled.div`
  min-width: 400px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .top-slider-container {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .selected-color-circle {
    width: ${(props) => props.strokeWidth || "10"}px;
    height: ${(props) => props.strokeWidth || "10"}px;
    min-height: ${(props) => props.strokeWidth || "10"}px;
    min-width: ${(props) => props.strokeWidth || "10"}px;
    background: ${(props) => props.selectedColor || "black"};
    border-radius: 50%;
  }
`;

export default StyledStrokeSelector;
